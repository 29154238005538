import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  messageSent: null
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    _sendMessage: (state, action: PayloadAction<string>) => {
      state.messageSent = action.payload;
    }
  }
});

export const { _sendMessage } = messageSlice.actions;
export default messageSlice.reducer;
