import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Grid,
  CircularProgress
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetLinkedOrganizationsQuery,
  useLazyGetYourShiftPatternsQuery,
  useLazyGetOtherShiftPatternsQuery,
  useUpdateShiftMutation
} from 'src/services/api';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Shift } from './types';
import { LoadingButton } from '@mui/lab';

interface EditShiftDialogProps {
  open: boolean;
  onClose: () => void;
  shift: any | null;
  onEditShift: (updatedShift: Shift) => void;
}

interface ShiftFormData {
  selectedAgency: string;
  shiftPatternId: string;
  count: number;
  date: string;
}

const EditShiftDialog: React.FC<EditShiftDialogProps> = ({
  open,
  onClose,
  shift,
  onEditShift
}) => {
  const [shiftPatterns, setShiftPatterns] = useState([]);
  const [isLoadingShiftPatterns, setIsLoadingShiftPatterns] = useState(false);

  const [
    updateShift,
    {
      data: updateShiftData,
      isLoading: updateShiftLoading,
      error: updateShiftError
    }
  ] = useUpdateShiftMutation();

  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  //   const [updateShift] = useUpdateShiftMutation();

  const { control, handleSubmit, reset, watch } = useForm<ShiftFormData>({
    defaultValues: {
      selectedAgency: 'internal',
      shiftPatternId: '',
      count: 1,
      date: moment().format('YYYY-MM-DD')
    }
  });

  const { data: linkedOrgs } = useGetLinkedOrganizationsQuery('agency', {
    skip: !open
  });

  const selectedAgency = watch('selectedAgency');

  const [getYourShiftPatterns] = useLazyGetYourShiftPatternsQuery();
  const [getOtherShiftPatterns] = useLazyGetOtherShiftPatternsQuery();

  useEffect(() => {
    if (open && shift) {
      reset({
        selectedAgency: shift.agentId?._id || 'internal',
        shiftPatternId: shift.shiftPattern._id,
        count: shift.count,
        date: shift.date
      });
    }
  }, [open, shift, reset]);

  useEffect(() => {
    const fetchShiftPatterns = async () => {
      setIsLoadingShiftPatterns(true);
      try {
        let patterns;
        if (selectedAgency === 'internal') {
          const response = await getYourShiftPatterns(
            userState.currentOrganization?._id
          ).unwrap();
          patterns = response;
        } else {
          const response = await getOtherShiftPatterns(selectedAgency).unwrap();
          patterns = response;
        }
        setShiftPatterns(patterns);
      } catch (error) {
        console.log('Error fetching shift patterns:', error);
        dispatch(
          showSnack({
            message: 'Error fetching shift patterns',
            color: 'error'
          })
        );
      } finally {
        setIsLoadingShiftPatterns(false);
      }
    };

    if (open && selectedAgency) {
      fetchShiftPatterns();
    }
  }, [selectedAgency, open, userState.currentOrganization?._id]);

  const onSubmit = async (data: ShiftFormData) => {
    if (shift) {
      try {
        const updatedShift = await updateShift({
          _id: shift._id,
          shiftPattern: data.shiftPatternId,
          count: data.count,
          agentId:
            data.selectedAgency === 'internal' ? null : data.selectedAgency,
          date: data.date
        }).unwrap();
        onEditShift(updatedShift);
        dispatch(
          showSnack({ message: 'Shift updated successfully', color: 'success' })
        );
        onClose();
      } catch (error) {
        dispatch(
          showSnack({ message: 'Error updating shift', color: 'error' })
        );
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Shift</DialogTitle>
        <DialogContent>
          <Controller
            name="selectedAgency"
            control={control}
            rules={{ required: 'Agency is required' }}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Agency</InputLabel>
                <Select {...field}>
                  <MenuItem value="internal">Internal</MenuItem>
                  {linkedOrgs?.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          {isLoadingShiftPatterns ? (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="shiftPatternId"
                  control={control}
                  rules={{ required: 'Shift pattern is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Shift Pattern</InputLabel>
                      <Select {...field}>
                        {shiftPatterns.map((pattern) => (
                          <MenuItem key={pattern._id} value={pattern._id}>
                            {pattern.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="count"
                  control={control}
                  rules={{ required: 'Count is required', min: 1 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Count"
                      fullWidth
                      margin="normal"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Controller
            name="date"
            control={control}
            rules={{ required: 'Date is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                label="Date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            loading={updateShiftLoading}
            type="submit"
            color="primary"
          >
            Update Shift
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditShiftDialog;
