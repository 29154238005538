import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Box,
  Chip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import {
  Delete,
  DeleteOutlineOutlined,
  Edit,
  Email,
  Phone
} from '@mui/icons-material';
import { Staff, getRoleColor } from './util';
import { useGetCareStaffsQuery } from 'src/services/api';
import type { IStaff } from 'src/interfaces/staffs';
import { ChevronsDownIcon } from 'lucide-react';
interface CareStaff extends Staff {
  specialization?: string;
}

const CareStaffAccordion: React.FC<{ staff: IStaff }> = ({ staff }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronsDownIcon />}
      aria-controls={`care-staff-content-${staff._id}`}
      id={`care-staff-header-${staff._id}`}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
          {staff.user?.firstName[0]}
        </Avatar>
        <Typography variant="body2">
          {staff.user?.firstName} {staff.user?.lastName}
        </Typography>
        <Chip
          label={staff.role.replace('_', ' ')}
          color={getRoleColor(staff.role)}
          size="small"
          sx={{ ml: 2 }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          marginY: 'auto'
        }}
      >
        <IconButton
          size="small"
          sx={{ display: 'flex', margin: 'auto', height: '100%' }}
          color="primary"
          onClick={(e) => {
            e.stopPropagation(); /* Handle edit */
          }}
        >
          <Edit fontSize="medium" />
        </IconButton>
        <IconButton
          size="small"
          sx={{ display: 'flex', marginBlock: 'auto', height: '100%' }}
          color="error"
          onClick={(e) => {
            e.stopPropagation(); /* Handle delete */
          }}
        >
          <DeleteOutlineOutlined fontSize="medium" />
        </IconButton>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {/* <Typography variant="body2">
            <strong>Specialization:</strong> {staff.specialization}
          </Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Email fontSize={'medium'} sx={{ mr: 1 }} />
          <Typography variant="body2">{staff.user?.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Phone fontSize={'medium'} sx={{ mr: 1 }} style={{}} />
          <Typography variant="body2">{staff.user?.phone}</Typography>
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
);

const CareStaffList: React.FC<{ staffList: CareStaff[] }> = ({ staffList }) => {
  const [filter, setFilter] = useState<any>('all');

  const [staffs, setCareStaffs] = useState<IStaff[]>([]);

  const {
    data: careStaffs,
    isLoading,
    isError,
    error,
    refetch
  } = useGetCareStaffsQuery(undefined);

  useEffect(() => {
    if (careStaffs) {
      setCareStaffs(careStaffs);
    }
  }, [careStaffs]);

  const filteredStaff =
    filter === 'all' ? staffs : staffs.filter((staff) => staff.role === filter);

  const roles = ['all', ...new Set(staffs.map((staff) => staff.role))];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        sx={{
          width: 220 + '!important'
        }}
      >
        <FormControl size="small">
          <InputLabel>Filter by Role</InputLabel>
          <Select
            size="small"
            value={filter}
            label="Filter by Role"
            onChange={(e) => setFilter(e.target.value)}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role === 'all' ? 'All Roles' : role.replace('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        {filteredStaff.map((staff) => (
          <Grid item xs={12} md={6} key={staff._id}>
            <CareStaffAccordion staff={staff} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CareStaffList;
