import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Chip,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from 'src/redux/hook';
import { styled } from '@mui/system';

const CrossedChip = styled(Chip)(({ theme }) => ({
  textDecoration: 'line-through',
  opacity: 0.6
}));

const QualificationsTraining = ({ onSubmit }) => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      qualifications: [{ name: '', institution: '', year: '' }],
      trainings: [{ name: '', provider: '', completionDate: '' }],
      skills: []
    }
  });

  const {
    fields: qualificationFields,
    append: appendQualification,
    remove: removeQualification
  } = useFieldArray({
    control,
    name: 'qualifications'
  });

  const {
    fields: trainingFields,
    append: appendTraining,
    remove: removeTraining
  } = useFieldArray({
    control,
    name: 'trainings'
  });

  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([]);
  const userState = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (application?.qualificationsAndTraining) {
      reset({
        qualifications: application.qualificationsAndTraining
          .qualifications || [{ name: '', institution: '', year: '' }],
        trainings: (
          application.qualificationsAndTraining.trainings || [
            { name: '', provider: '', completionDate: '' }
          ]
        ).map((training) => ({
          ...training,
          completionDate: formatDateForInput(training.completionDate)
        })),
        skills: application.qualificationsAndTraining.skills || []
      });
      setSkills(
        application.qualificationsAndTraining.skills?.map((skill) => ({
          name: skill,
          crossed: false
        })) || []
      );
    }
  }, [application, reset]);

  const handleAddSkill = () => {
    if (skill.trim()) {
      if (!skills.some((s) => s.name === skill.trim())) {
        setSkills([...skills, { name: skill.trim(), crossed: false }]);
      }
      setSkill('');
    }
  };

  const handleToggleSkill = (skillToToggle) => {
    setSkills(
      skills.map((s) =>
        s.name === skillToToggle ? { ...s, crossed: !s.crossed } : s
      )
    );
  };

  // Function to format date strings for input fields
  const formatDateForInput = (dateString?: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleFormSubmit = (data) => {
    // Filter out crossed skills
    const activeSkills = skills.filter((s) => !s.crossed).map((s) => s.name);

    console.log(activeSkills, 'ss');

    onSubmit({
      qualificationsAndTraining: {
        ...data,
        skills: activeSkills
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Qualifications</Typography>
          {qualificationFields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`qualifications.${index}.name`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Qualification name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Qualification Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.qualifications?.[index]?.name}
                      helperText={errors.qualifications?.[index]?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`qualifications.${index}.institution`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Institution is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Institution"
                      fullWidth
                      margin="normal"
                      error={!!errors.qualifications?.[index]?.institution}
                      helperText={
                        errors.qualifications?.[index]?.institution?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name={`qualifications.${index}.year`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Year is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Year"
                      fullWidth
                      margin="normal"
                      error={!!errors.qualifications?.[index]?.year}
                      helperText={errors.qualifications?.[index]?.year?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={() => removeQualification(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              appendQualification({ name: '', institution: '', year: '' })
            }
          >
            Add Qualification
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Training</Typography>
          {trainingFields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`trainings.${index}.name`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Training name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Training Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.trainings?.[index]?.name}
                      helperText={errors.trainings?.[index]?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`trainings.${index}.provider`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Provider is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Provider"
                      fullWidth
                      margin="normal"
                      error={!!errors.trainings?.[index]?.provider}
                      helperText={errors.trainings?.[index]?.provider?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name={`trainings.${index}.completionDate`}
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Completion date is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Completion Date"
                      type="date"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!errors.trainings?.[index]?.completionDate}
                      helperText={
                        errors.trainings?.[index]?.completionDate?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={() => removeTraining(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              appendTraining({ name: '', provider: '', completionDate: '' })
            }
          >
            Add Training
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Skills</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <TextField
                label="Add a Skill"
                fullWidth
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" onClick={handleAddSkill} fullWidth>
                Add Skill
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {skills.map((skill, index) =>
              skill.crossed ? (
                <CrossedChip
                  key={index}
                  label={skill.name}
                  onDelete={() => handleToggleSkill(skill.name)}
                  sx={{ m: 0.5 }}
                />
              ) : (
                <Chip
                  key={index}
                  label={skill.name}
                  onDelete={() => handleToggleSkill(skill.name)}
                  sx={{ m: 0.5 }}
                />
              )
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default QualificationsTraining;
