// components/TaxInfo.js
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useSubmitApplicationSectionMutation } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';

export default function TaxInfo({ onSubmit }) {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );
  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setValue('taxCode', application?.taxInfo?.taxCode || '');
    setValue('niNumber', application?.taxInfo?.niNumber || '');
    setValue('hasP45', application?.taxInfo?.hasP45 || false);
    setValue('hasStudentLoan', application?.taxInfo?.hasStudentLoan || false);
  }, [application]);

  const [submitApplication, setSubmitApplication] =
    useSubmitApplicationSectionMutation();

  const dispatch = useDispatch();

  const submitForm = async (data) => {
    try {
      await submitApplication({
        data: {
          taxInfo: data
        }
      }).unwrap();
      dispatch(
        showSnack({ message: 'Tax info saved successfully', color: 'success' })
      );
    } catch (error) {
      dispatch(showSnack({ message: error.message, color: 'error' }));
      console.error('Error saving data:', error);
      // Handle error (e.g., show error message to user)
    }
  };
  const userState = useAppSelector((state) => state.user.user);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="taxCode"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Tax Code"
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="niNumber"
            control={control}
            defaultValue=""
            rules={{ required: 'National Insurance number is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="National Insurance Number"
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="hasP45"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    defaultChecked={application?.taxInfo?.hasP45}
                  />
                }
                label="I have a P45 from my previous employer"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="hasStudentLoan"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    defaultChecked={application?.taxInfo?.hasStudentLoan}
                  />
                }
                label="I have a student loan"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
