import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IShift } from 'src/interfaces/shift.interface';
import { api } from 'src/services/api';

interface ShiftState {
  shifts: IShift[];
}

const initialState: ShiftState = {
  shifts: []
};

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    addShift: (state, action: PayloadAction<IShift[]>) => {
      const newShifts = action.payload;
      const existingShifts = state.shifts;

      const mergedShifts = newShifts?.map((newShift) => {
        const existingShift = existingShifts.find(
          (shift) => shift._id === newShift._id
        );
        return existingShift ? { ...existingShift, ...newShift } : newShift;
      });

      const updatedShifts = [
        ...mergedShifts,
        ...existingShifts.filter(
          (shift) => !newShifts.some((newShift) => newShift._id === shift._id)
        )
      ];

      state.shifts = updatedShifts as any;
    },
    removeShift: (state, action: PayloadAction<string>) => {
      const shiftId = action.payload;
      state.shifts = state.shifts.filter((shift) => shift._id !== shiftId);
    },
    updateShift: (state, action: PayloadAction<IShift>) => {
      const updatedShift = action.payload;
      const index = state.shifts.findIndex(
        (shift) => shift._id === updatedShift._id
      );

      if (index !== -1) {
        state.shifts[index] = updatedShift as any;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getShifts.matchFulfilled,
      (state, action: PayloadAction<IShift[]>) => {
        console.log(action.payload, 'andiiii');
        state.shifts = action.payload as any;
      }
    );
    builder.addMatcher(
      api.endpoints.assignCarers.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.shifts = state.shifts?.map((shift) => {
          if (shift._id === action.payload.shift._id) {
            return action.payload.shift;
          }
          return shift;
        });
      }
    );
    builder.addMatcher(
      api.endpoints.unassignCarer.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.shifts = state.shifts?.map((shift) => {
          if (shift._id === action.payload.shift._id) {
            return action.payload.shift;
          }
          return shift;
        });
      }
    );
    builder.addMatcher(
      api.endpoints.publishShiftMultiple.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const newShifts = action.payload;
        const existingShifts = state.shifts;

        const mergedShifts = newShifts?.map((newShift) => {
          const existingShift = existingShifts.find(
            (shift) => shift._id === newShift._id
          );
          return existingShift ? { ...existingShift, ...newShift } : newShift;
        });

        const updatedShifts = [
          ...mergedShifts,
          ...existingShifts.filter(
            (shift) => !newShifts.some((newShift) => newShift._id === shift._id)
          )
        ];

        state.shifts = updatedShifts;
      }
    );
    builder.addMatcher(
      api.endpoints.deleteShift.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.shifts = state.shifts.filter(
          (shift) => shift._id !== action.payload.shift._id
        );
      }
    );
  }
});

export const { addShift, removeShift, updateShift } = shiftSlice.actions;

export default shiftSlice.reducer;
