import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  TextField,
  Box,
  styled,
  useTheme,
  Paper
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { useAppSelector } from 'src/redux/hook';
import { Eye, File, Upload, Calendar, Trash } from 'lucide-react';

const SectionPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2
}));

const DocumentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2)
}));

const IdentificationInfo = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (application?.identificationInfo) {
      setValue('documentType', application.identificationInfo.documentType);
      setValue(
        'expiryDate',
        application.identificationInfo.expiryDate
          ? new Date(application.identificationInfo.expiryDate)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setFileName(application.identificationInfo.fileName || '');
    }
  }, [application, setValue]);

  const submitIdentificationInfo = async (data) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('section', 'identificationInfo');
      formData.append('documentType', data.documentType);
      if (data.document && data.document.length > 0) {
        formData.append('file', data.document[0]);
      }
      if (data.expiryDate) {
        formData.append('expiryDate', data.expiryDate);
      }

      const response = await axios.post(
        `${apiHostname}/api/v1/application/upload-document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      dispatch(
        showSnack({
          message: 'Identification information saved successfully',
          color: 'success'
        })
      );

      onSubmit(response.data);
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error.response?.data?.message ||
            'An error occurred while saving identification info',
          color: 'error'
        })
      );
      console.error('Error saving identification data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${apiHostname}/api/v1/application/delete-document/identificationInfo`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      dispatch(
        showSnack({
          message: 'Identification document deleted successfully',
          color: 'success'
        })
      );

      setFileName('');
      setValue('document', null);
      setValue('documentType', '');
      setValue('expiryDate', '');

      // You might want to update your global state here if necessary
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error.response?.data?.message ||
            'An error occurred while deleting identification document',
          color: 'error'
        })
      );
      console.error('Error deleting document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = () => {
    const url = application?.identificationInfo?.documentUrl;
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const renderUploadedDocument = () => {
    if (!fileName) return null;

    return (
      <DocumentPaper elevation={2}>
        <Box display="flex" alignItems="center">
          <File
            size={24}
            color={theme.palette.primary.main}
            style={{ marginRight: theme.spacing(2) }}
          />
          <Typography variant="body1">{fileName}</Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            startIcon={<Eye />}
            onClick={handleView}
            sx={{ mr: 1 }}
          >
            View
          </Button>
          <Button
            variant="outlined"
            startIcon={<Trash />}
            onClick={handleDelete}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </DocumentPaper>
    );
  };

  return (
    <SectionPaper elevation={3}>
      <Typography variant="h6" gutterBottom>
        Identification Information
      </Typography>
      <form onSubmit={handleSubmit(submitIdentificationInfo)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.documentType}>
              <InputLabel id="identification-type-label">
                Identification Type
              </InputLabel>
              <Controller
                name="documentType"
                control={control}
                defaultValue=""
                rules={{ required: 'Identification type is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="identification-type-label"
                    label="Identification Type"
                  >
                    <MenuItem value="passport">Passport</MenuItem>
                    <MenuItem value="drivingLicense">Driving License</MenuItem>
                    <MenuItem value="birthCertificate">
                      Birth Certificate
                    </MenuItem>
                    <MenuItem value="biometricResidencePermit">
                      Biometric Residence Permit
                    </MenuItem>
                  </Select>
                )}
              />
              {errors.documentType && (
                <FormHelperText>{errors.documentType.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="document"
              control={control}
              defaultValue={null}
              rules={{
                required:
                  !fileName && 'Identification document upload is required'
              }}
              render={({ field: { onChange, onBlur, value, ...field } }) => (
                <Box>
                  <input
                    {...field}
                    type="file"
                    id="identification-document-upload"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files && files.length > 0) {
                        onChange(files);
                        setFileName(files[0].name);
                      }
                    }}
                    onBlur={onBlur}
                    accept=".pdf,.png,.jpg,.jpeg"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                  />
                  {renderUploadedDocument()}
                  <Button
                    variant="contained"
                    startIcon={<Upload />}
                    color="primary"
                    onClick={handleUploadClick}
                    sx={{
                      borderRadius: theme.shape.borderRadius * 3,
                      padding: theme.spacing(1.5, 3),
                      textTransform: 'none',
                      fontWeight: 600,
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none'
                      }
                    }}
                  >
                    {fileName
                      ? 'Replace Identification Document'
                      : 'Upload Identification Document'}
                  </Button>
                </Box>
              )}
            />
            {errors.document && (
              <FormHelperText error>{errors.document.message}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expiryDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Expiry Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <Calendar
                        size={20}
                        color={theme.palette.text.secondary}
                        style={{ marginRight: 8 }}
                      />
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                mt: 2,
                py: 1.5,
                px: 4,
                borderRadius: theme.shape.borderRadius * 2,
                textTransform: 'none',
                fontWeight: 600
              }}
            >
              {isLoading ? 'Saving...' : 'Save Identification Information'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SectionPaper>
  );
};

export default IdentificationInfo;
