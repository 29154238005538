import React, { useEffect, useState } from 'react';
import {
  Box,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  styled
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Sidebar from './sidebar';
import MobileStaffList from './staff-list';
import ChatBox from './chat-box';
import { useLazyGetLinkedUsersQuery } from 'src/services/api';
import type { IUser } from 'src/interfaces/user.interface';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${theme.header.height})`, // Adjust this value based on your app's header height
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    background: theme.colors.gradients.primary,
    width: '98%',
    padding: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px' + '!important',
    height: `calc(100vh - ${theme.header.height} - 20px)`,
    margin: 'auto'
  }
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 350,
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const StyledChatArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));

const StyledSearchBar = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: 20
  }
}));

const ChatPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedUser, setSelectedUser] = useState<{
    user: any;
    type: string;
  }>({
    user: null,
    type: ''
  });
  const [searchTerm, setSearchTerm] = useState('');

  const userState = useAppSelector((state) => state.user.user);
  const [staffs, setStaffs] = useState<IUser[]>([]);
  const dispatch = useDispatch();

  const handleSetSelectedUser = (user: any, type: string) => {
    setSelectedUser({
      user,
      type
    });
  };

  const fetchCarers = async (type) => {
    try {
      // const response = await getLinkedUsers(type).unwrap();
      // setStaffs(response.data);
    } catch (error) {
      console.error('Error fetching carers:', error);
      dispatch(showSnack({ message: 'Error fetching carers', color: 'error' }));
    }
  };

  const filteredStaffs = staffs.filter((staff) =>
    `${staff.fname} ${staff.lname}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <StyledContainer>
      <StyledSidebar>
        <StyledSearchBar
          variant="outlined"
          placeholder="Search users..."
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: ' flex',
              width: '90vw'
            }
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {isMobile ? (
          <MobileStaffList
            onSelectUser={setSelectedUser}
            selectedUser={selectedUser}
          />
        ) : (
          <Sidebar
            onSelectUser={handleSetSelectedUser}
            selectedUser={selectedUser}
          />
        )}
      </StyledSidebar>
      <StyledChatArea>
        {selectedUser && (
          <ChatBox user={selectedUser} isMobile={isMobile} onClose={() => {}} />
        )}
      </StyledChatArea>
    </StyledContainer>
  );
};

export default ChatPage;
