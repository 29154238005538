import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { _setProfilePicture } from 'src/redux/reducers/users';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, Controller } from 'react-hook-form';
import Preferences from './views/preference';

const StyledRoot = styled(Box)(({ theme }) => ({
  height: `calc( 100vh - ${theme.header.height} - 20px)`,
  overflowY: 'auto',
  width: '95%',
  margin: 'auto',
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 0
  }
}));

const StyledProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  marginRight: theme.spacing(3),
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 100,
    marginRight: 0,
    marginBottom: theme.spacing(2)
  }
}));

const HiddenInput = styled('input')({
  display: 'none'
});

const StyledInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    background: 'transparent'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CareHomeSettings = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [shiftPeriod, setShiftPeriod] = useState('month');
  const [isEditing, setIsEditing] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const userState = useAppSelector((state) => state.userState);
  const shiftState = useAppSelector((state) => state.shifts.shifts);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      careHomeName: userState.currentOrganization?.name || '',
      careHomeType: userState.currentOrganization?.type || '',
      street: userState.user.address?.street || '',
      city: userState.user.address?.city || '',
      state: userState.user.address?.state || '',
      zipCode: userState.user.address?.zipCode || '',
      country: userState.user.address?.country || '',
      phoneNumber: userState.user.phone || '',
      website: ''
    }
  });

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({ message: 'Profile picture updated', color: 'success' })
          );
          dispatch(_setProfilePicture(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit = (data) => {
    console.log(data);
    // Implement save changes logic here
    setIsEditing(false);
    dispatch(
      showSnack({ message: 'Profile updated successfully', color: 'success' })
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <StyledRoot>
      <StyledProfileHeader>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          {isUploading ? (
            <Box
              sx={{
                width: 120,
                height: 120,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <StyledAvatar
              src={''}
              alt={userState.user.firstName}
              onClick={handleAvatarClick}
            />
          )}
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
          />
          <Box
            sx={{
              textAlign: isMobile ? 'center' : 'left',
              ml: isMobile ? 0 : 2
            }}
          >
            <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
              {userState.user.firstName} {userState.user.lastName}
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {userState.user.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Member since {dayjs().format('MMMM YYYY')}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={handleEditToggle}
          sx={{ mt: isMobile ? 2 : 0 }}
        >
          {isEditing ? 'Cancel' : 'Edit Profile'}
        </Button>
      </StyledProfileHeader>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="care home settings tabs"
      >
        <Tab label="Profile" />
        <Tab label="Care Home Overview" />
        <Tab label="Shift Settings" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name="careHomeName"
                control={control}
                rules={{ required: 'Care Home Name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Care Home Name"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="careHomeType"
                control={control}
                rules={{ required: 'Care Home Type is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Care Home Type"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="street"
                control={control}
                rules={{ required: 'Street is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Street"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="city"
                control={control}
                rules={{ required: 'City is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="City"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="state"
                control={control}
                rules={{ required: 'State is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="State"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="zipCode"
                control={control}
                rules={{ required: 'Zip Code is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Zip Code"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="country"
                control={control}
                rules={{ required: 'Country is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Country"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: 'Phone Number is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="website"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    label="Website"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    disabled={!isEditing}
                    margin="normal"
                  />
                )}
              />
            </Grid>
          </Grid>

          {isEditing && (
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </Box>
          )}
        </form>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Care Home Overview
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StyledInfoCard>
              <Typography variant="subtitle1" gutterBottom>
                Number of Residents
              </Typography>
              <Typography variant="h4">{0}</Typography>
            </StyledInfoCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledInfoCard>
              <Typography variant="subtitle1" gutterBottom>
                Staff Members
              </Typography>
              <Typography variant="h4">{0}</Typography>
            </StyledInfoCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledInfoCard>
              <Typography variant="subtitle1" gutterBottom>
                Shifts Scheduled
              </Typography>
              <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                <InputLabel>Period</InputLabel>
                <Select
                  value={shiftPeriod}
                  onChange={(e) => setShiftPeriod(e.target.value)}
                  label="Period"
                >
                  <MenuItem value="month">This Month</MenuItem>
                  <MenuItem value="year">This Year</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="h4">
                {shiftPeriod === 'month'
                  ? shiftState.filter(
                      (shift) => dayjs(shift.date).month() === dayjs().month()
                    ).length || 0
                  : shiftState.filter(
                      (shift) => dayjs(shift.date).year() === dayjs().year()
                    ).length || 0}
              </Typography>
            </StyledInfoCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledInfoCard>
              <Typography variant="subtitle1" gutterBottom>
                Bed Capacity
              </Typography>
              <Typography variant="h4">{0}</Typography>
            </StyledInfoCard>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Preferences />
      </TabPanel>
    </StyledRoot>
  );
};

export default CareHomeSettings;
