import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';

const HealthInfo = ({ onSubmit }) => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      hasHealthConditions: '',
      healthConditionsDetails: '',
      requiresAdjustments: false,
      adjustmentsDetails: '',
      emergencyContactName: '',
      emergencyContactRelation: '',
      emergencyContactPhone: '',
      tetanusVaccination: '',
      tetanusVaccinationDate: '',
      covidVaccination: '',
      covidVaccinationDate: '',
      bloodType: '',
      allergies: '',
      medications: '',
      physicalLimitations: ''
    }
  });

  const hasHealthConditions = watch('hasHealthConditions');
  const requiresAdjustments = watch('requiresAdjustments');

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  useEffect(() => {
    if (application?.healthInfo) {
      Object.keys(application.healthInfo).forEach((key: any) => {
        setValue(key, application.healthInfo[key]);
      });
    }
  }, [application, setValue]);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ healthInfo: data }))}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Do you have any health conditions relevant to your role?
            </FormLabel>
            <Controller
              name="hasHealthConditions"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        {hasHealthConditions === 'yes' && (
          <Grid item xs={12}>
            <Controller
              name="healthConditionsDetails"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Health Conditions Details"
                  multiline
                  rows={4}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name="requiresAdjustments"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} color="primary" />}
                label="Do you require any adjustments in your workplace?"
              />
            )}
          />
        </Grid>

        {requiresAdjustments && (
          <Grid item xs={12}>
            <Controller
              name="adjustmentsDetails"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Required Adjustments Details"
                  multiline
                  rows={4}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h6">Emergency Contact</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="emergencyContactName"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Name" fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="emergencyContactRelation"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Relationship" fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="emergencyContactPhone"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Phone Number" fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Vaccinations</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Have you had a tetanus vaccination in the last 10 years?
            </FormLabel>
            <Controller
              name="tetanusVaccination"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="unknown"
                    control={<Radio />}
                    label="Unknown"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="tetanusVaccinationDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date of Last Tetanus Vaccination"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Have you been vaccinated against COVID-19?
            </FormLabel>
            <Controller
              name="covidVaccination"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="partially"
                    control={<Radio />}
                    label="Partially"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="covidVaccinationDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date of Last COVID-19 Vaccination"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="bloodType"
            control={control}
            render={({ field }) => (
              <TextField {...field} select label="Blood Type" fullWidth>
                {['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].map(
                  (option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )
                )}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="allergies"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Allergies"
                fullWidth
                multiline
                rows={2}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="medications"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Current Medications"
                fullWidth
                multiline
                rows={2}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="physicalLimitations"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Physical Limitations"
                fullWidth
                multiline
                rows={2}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default HealthInfo;
