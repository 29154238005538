import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Box
} from '@mui/material';

interface PadCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (stoolType: string, urineType: string) => void;
}

const stoolTypes = [
  { type: '1', image: require('src/assets/images/stool/type1.png') },
  { type: '2', image: require('src/assets/images/stool/type2.png') },
  { type: '3', image: require('src/assets/images/stool/type3.png') },
  { type: '4', image: require('src/assets/images/stool/type4.png') },
  { type: '5', image: require('src/assets/images/stool/type5.png') },
  { type: '6', image: require('src/assets/images/stool/type6.png') },
  { type: '7', image: require('src/assets/images/stool/type7.png') }
];

const urineTypes = ['Wet', 'Dry', 'Damp'];

const PadCheckDialog: React.FC<PadCheckDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const [selectedStoolType, setSelectedStoolType] = useState('');
  const [selectedUrineType, setSelectedUrineType] = useState('');

  const handleResolve = () => {
    if (selectedStoolType && selectedUrineType) {
      onResolve(selectedStoolType, selectedUrineType);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Pad Check</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Stool Type</FormLabel>
          <RadioGroup
            aria-label="stool-type"
            name="stool-type"
            value={selectedStoolType}
            onChange={(e) => setSelectedStoolType(e.target.value)}
          >
            <Grid container spacing={2}>
              {stoolTypes.map((stool) => (
                <Grid item xs={4} sm={3} md={2} key={stool.type}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={stool.image}
                      alt={`Type ${stool.type}`}
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <FormControlLabel
                      value={stool.type}
                      control={<Radio />}
                      label={`Type ${stool.type}`}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Urine Type</FormLabel>
          <RadioGroup
            aria-label="urine-type"
            name="urine-type"
            value={selectedUrineType}
            onChange={(e) => setSelectedUrineType(e.target.value)}
          >
            <Grid container spacing={2}>
              {urineTypes.map((urine) => (
                <Grid item key={urine}>
                  <FormControlLabel
                    value={urine}
                    control={<Radio />}
                    label={urine}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleResolve}
          color="primary"
          disabled={!selectedStoolType || !selectedUrineType}
        >
          Resolve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PadCheckDialog;
