import React, { useEffect } from 'react';
import {
  useForm,
  Controller,
  useFieldArray,
  SubmitHandler
} from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from 'src/redux/hook';

interface WorkExperienceEntry {
  jobTitle?: string;
  employer?: string;
  startDate?: string;
  endDate?: string;
  currentlyWorking?: boolean;
  responsibilities?: string;
}

interface WorkExperienceFormData {
  experiences: WorkExperienceEntry[];
}

interface WorkExperienceProps {
  onSubmit: SubmitHandler<{ workExperience: WorkExperienceEntry[] }>;
}

const WorkExperience: React.FC<WorkExperienceProps> = ({ onSubmit }) => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<WorkExperienceFormData>({
    defaultValues: {
      experiences: [
        {
          jobTitle: '',
          employer: '',
          startDate: '',
          endDate: '',
          currentlyWorking: false,
          responsibilities: ''
        }
      ]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'experiences'
  });

  useEffect(() => {
    if (application?.workExperience && application.workExperience.length > 0) {
      const formattedExperiences = application.workExperience.map((exp) => ({
        ...exp,
        startDate: formatDateForInput(exp.startDate),
        endDate: formatDateForInput(exp.endDate)
      }));
      reset({ experiences: formattedExperiences });
    }
  }, [application?.workExperience, reset]);

  // Function to format date strings for input fields
  const formatDateForInput = (dateString?: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const userState = useAppSelector((state) => state.user.user);

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({ workExperience: data.experiences })
      )}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Work Experience</Typography>
        </Grid>

        {fields.map((field, index) => {
          const currentlyWorking = watch(
            `experiences.${index}.currentlyWorking`
          );
          return (
            <Grid container item spacing={2} key={field.id}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Experience {index + 1}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`experiences.${index}.jobTitle` as const}
                  control={control}
                  rules={{ required: 'Job title is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Job Title"
                      fullWidth
                      error={!!errors.experiences?.[index]?.jobTitle}
                      helperText={
                        errors.experiences?.[index]?.jobTitle?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`experiences.${index}.employer` as const}
                  control={control}
                  rules={{ required: 'Employer is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Employer"
                      fullWidth
                      error={!!errors.experiences?.[index]?.employer}
                      helperText={
                        errors.experiences?.[index]?.employer?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`experiences.${index}.startDate` as const}
                  control={control}
                  rules={{ required: 'Start date is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Start Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.experiences?.[index]?.startDate}
                      helperText={
                        errors.experiences?.[index]?.startDate?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`experiences.${index}.currentlyWorking` as const}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="I currently work here"
                    />
                  )}
                />
              </Grid>
              {!currentlyWorking && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name={`experiences.${index}.endDate` as const}
                    control={control}
                    rules={{ required: 'End date is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="End Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.experiences?.[index]?.endDate}
                        helperText={
                          errors.experiences?.[index]?.endDate?.message
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name={`experiences.${index}.responsibilities` as const}
                  control={control}
                  rules={{ required: 'Responsibilities are required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Responsibilities"
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.experiences?.[index]?.responsibilities}
                      helperText={
                        errors.experiences?.[index]?.responsibilities?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <IconButton onClick={() => remove(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              append({
                jobTitle: '',
                employer: '',
                startDate: '',
                endDate: '',
                currentlyWorking: false,
                responsibilities: ''
              })
            }
          >
            Add Work Experience
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default WorkExperience;
