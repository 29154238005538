import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  IconButton,
  Grid,
  CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  usePublishShiftMultipleMutation,
  usePublishShiftMultipleFreeMutation,
  useGetLinkedOrganizationsQuery,
  useGetYourShiftPatternsQuery,
  useGetOtherShiftPatternsQuery,
  useLazyGetYourShiftPatternsQuery,
  useLazyGetOtherShiftPatternsQuery
} from 'src/services/api';
import moment from 'moment';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import type { Shift } from './types';

interface AddShiftDialogProps {
  open: boolean;
  onClose: () => void;
  selectedDate: moment.Moment | null;
  onAddShift: (newShifts: any) => void;
  shiftToEdit?: any;
}

interface ShiftFormData {
  selectedAgency: string;
  shifts: {
    shiftPatternId: string;
    count: number;
  }[];
  duplicationType: string;
  nextNDays: number;
  skipDays: number;
}

const AddShiftDialog: React.FC<AddShiftDialogProps> = ({
  open,
  onClose,
  selectedDate,
  onAddShift,
  shiftToEdit
}) => {
  const [remainingDaysInMonth, setRemainingDaysInMonth] = useState(0);
  const [shiftPatterns, setShiftPatterns] = useState([]);
  const [isLoadingShiftPatterns, setIsLoadingShiftPatterns] = useState(false);

  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  const [publishShiftMultiple] = usePublishShiftMultipleMutation();
  const [publishShiftMultipleFree] = usePublishShiftMultipleFreeMutation();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm<ShiftFormData>({
      defaultValues: {
        selectedAgency: 'internal',
        shifts: [{ shiftPatternId: '', count: 1 }],
        duplicationType: 'none',
        nextNDays: 1,
        skipDays: 0
      }
    });

  useEffect(() => {
    if (open && shiftToEdit) {
      reset({
        selectedAgency: shiftToEdit.agentId || 'internal',
        shifts: [
          {
            shiftPatternId: shiftToEdit.shiftPattern._id,
            count: shiftToEdit.count
          }
        ],
        duplicationType: 'none',
        nextNDays: 1,
        skipDays: 0
      });
    } else if (open) {
      reset();
    }
  }, [open, shiftToEdit, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts'
  });

  const { data: linkedOrgs } = useGetLinkedOrganizationsQuery('agency', {
    skip: !open
  });

  const selectedAgency = watch('selectedAgency');
  const duplicationType = watch('duplicationType');

  const [getYourShiftPatterns] = useLazyGetYourShiftPatternsQuery();

  const [getOtherShiftPatterns] = useLazyGetOtherShiftPatternsQuery();

  useEffect(() => {
    if (selectedDate) {
      const endOfMonth = selectedDate.clone().endOf('month');
      const daysRemaining = endOfMonth.diff(selectedDate, 'days') + 1;
      setRemainingDaysInMonth(daysRemaining);
    }
  }, [selectedDate]);

  useEffect(() => {
    const fetchShiftPatterns = async () => {
      setIsLoadingShiftPatterns(true);
      try {
        let patterns;
        if (selectedAgency === 'internal') {
          const response = await getYourShiftPatterns(
            userState.currentOrganization?._id
          ).unwrap();
          patterns = response;
        } else {
          const response = await getOtherShiftPatterns(selectedAgency).unwrap();
          patterns = response;
        }
        setShiftPatterns(patterns);
      } catch (error) {
        dispatch(
          showSnack({
            message: 'Error fetching shift patterns',
            color: 'error'
          })
        );
      } finally {
        setIsLoadingShiftPatterns(false);
      }
    };

    if (open && selectedAgency) {
      fetchShiftPatterns();
    }
  }, [selectedAgency, open, userState.currentOrganization?._id]);

  useEffect(() => {
    if (open && shiftToEdit) {
      reset({
        selectedAgency: shiftToEdit.agentId || 'internal',
        shifts: [
          {
            shiftPatternId: shiftToEdit.shiftPattern._id,
            count: shiftToEdit.count
          }
        ],
        duplicationType: 'none',
        nextNDays: 1,
        skipDays: 0
      });
    } else if (open) {
      reset();
    }
  }, [open, shiftToEdit, reset]);

  const onSubmit = async (data: ShiftFormData) => {
    if (selectedDate) {
      if (shiftToEdit) {
        // Update existing shift
        try {
          //   const updatedShift = await updateShift({
          //     shiftId: shiftToEdit._id,
          //     shiftPattern: data.shifts[0].shiftPatternId,
          //     count: data.shifts[0].count,
          //     agentId: data.selectedAgency === 'internal' ? null : data.selectedAgency
          //   }).unwrap();
          //   dispatch(showSnack({ message: 'Shift updated successfully', color: 'success' }));
          //   onAddShift(updatedShift);
          //   onClose();
        } catch (error) {
          dispatch(
            showSnack({ message: 'Error updating shift', color: 'error' })
          );
        }
      } else {
        const shiftsToAdd = [];
        let currentDate = selectedDate.clone();
        let daysToAdd = 1;

        switch (data.duplicationType) {
          case 'nextNDays':
            daysToAdd = data.nextNDays;
            break;
          case 'remainingMonth':
            daysToAdd = remainingDaysInMonth;
            break;
          case 'wholeMonthSkip':
            daysToAdd = remainingDaysInMonth - data.skipDays;
            currentDate = currentDate.add(data.skipDays, 'days');
            break;
        }

        const createShiftsForDate = (date: moment.Moment) => {
          return data.shifts.map((shift) => ({
            date: date.format('YYYY-MM-DD'),
            count: shift.count,
            shiftPattern: shift.shiftPatternId,
            agentId:
              data.selectedAgency === 'internal' ? null : data.selectedAgency
          }));
        };

        for (let i = 0; i < daysToAdd; i++) {
          shiftsToAdd.push(...createShiftsForDate(currentDate));
          currentDate = currentDate.clone().add(1, 'day');
        }

        try {
          const publishFunction =
            data.selectedAgency === 'internal'
              ? publishShiftMultiple
              : publishShiftMultipleFree;
          const response = await publishFunction(shiftsToAdd).unwrap();
          dispatch(
            showSnack({
              message: 'Shifts added successfully',
              color: 'success'
            })
          );
          onAddShift(response);
          onClose();
        } catch (error) {
          dispatch(
            showSnack({ message: 'Error adding shifts', color: 'error' })
          );
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {shiftToEdit
            ? 'Edit Shift'
            : `Add Shift for ${selectedDate?.format('MMMM D, YYYY')}`}
        </DialogTitle>
        <DialogContent>
          <Controller
            name="selectedAgency"
            control={control}
            rules={{ required: 'Agency is required' }}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Agency</InputLabel>
                <Select {...field}>
                  <MenuItem value="internal">Internal</MenuItem>
                  {linkedOrgs?.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          {isLoadingShiftPatterns ? (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          ) : (
            fields.map((field, index) => (
              <Grid container spacing={2} key={field.id}>
                <Grid item xs={6}>
                  <Controller
                    name={`shifts.${index}.shiftPatternId`}
                    control={control}
                    rules={{ required: 'Shift pattern is required' }}
                    render={({ field }) => (
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Shift Pattern</InputLabel>
                        <Select {...field}>
                          {shiftPatterns.map((pattern) => (
                            <MenuItem key={pattern._id} value={pattern._id}>
                              {pattern.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`shifts.${index}.count`}
                    control={control}
                    rules={{ required: 'Count is required', min: 1 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        label="Count"
                        fullWidth
                        margin="normal"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          )}

          <Button onClick={() => append({ shiftPatternId: '', count: 1 })}>
            Add Another Shift
          </Button>

          {!shiftToEdit && (
            <>
              <Controller
                name="duplicationType"
                control={control}
                rules={{ required: 'Duplication type is required' }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Duplication</InputLabel>
                    <Select {...field}>
                      <MenuItem value="none">No duplication</MenuItem>
                      <MenuItem value="nextNDays">Next N days</MenuItem>
                      <MenuItem value="remainingMonth">
                        Remaining days in this month
                      </MenuItem>
                      <MenuItem value="wholeMonthSkip">
                        Whole month (skip N days)
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              {duplicationType === 'nextNDays' && (
                <Controller
                  name="nextNDays"
                  control={control}
                  rules={{ required: 'Number of days is required', min: 1 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      label="Number of days"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  )}
                />
              )}

              {duplicationType === 'wholeMonthSkip' && (
                <Controller
                  name="skipDays"
                  control={control}
                  rules={{ required: 'Skip days is required', min: 0 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      label="Skip days"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  )}
                />
              )}

              {duplicationType !== 'none' && (
                <Box mt={2}>
                  <Typography variant="body2">
                    {duplicationType === 'nextNDays' &&
                      `This will create shifts for the next ${watch(
                        'nextNDays'
                      )} days.`}
                    {duplicationType === 'remainingMonth' &&
                      `This will create shifts for the remaining ${remainingDaysInMonth} days in this month.`}
                    {duplicationType === 'wholeMonthSkip' &&
                      `This will create shifts for ${
                        remainingDaysInMonth - watch('skipDays')
                      } days, starting after ${watch('skipDays')} days.`}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Add Shifts
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddShiftDialog;
