import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2]
}));

const PlanDetails = ({ plan }) => {
  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        {plan.name}
      </Typography>
      <Typography variant="body1" paragraph>
        {plan.description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2
        }}
      >
        <Typography variant="h6">Price:</Typography>
        <Typography variant="h5" color="primary">
          ${plan.price} / {plan.interval}
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        * Prices are in USD and exclude applicable taxes.
      </Typography>
    </StyledPaper>
  );
};

export default PlanDetails;
