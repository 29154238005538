// CalendarDay.tsx
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import moment from 'moment';
import { DayCell, ShiftAvatar, ShiftIndicator } from './styles';
import { Shift } from './types';
import { CheckCircle, LoaderCircle } from 'lucide-react';
import { CheckCircleSharp } from '@mui/icons-material';

interface CalendarDayProps {
  day: moment.Moment;
  isCurrentMonth: boolean;
  shifts: Shift[];
  onClick: () => void;
  setSelectedDate: (date: moment.Moment) => void;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  day,
  isCurrentMonth,
  shifts,
  onClick,
  setSelectedDate
}) => {
  const isToday = day.isSame(moment(), 'day');

  const getShiftColor = (shiftName: string): string => {
    let hash = 0;
    for (let i = 0; i < shiftName.length; i++) {
      hash = shiftName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 100%, 70%)`;
  };

  const renderShiftAvatars = () => {
    return shifts.slice(0, 2).map((shift, index) => (
      <ShiftAvatar
        key={shift._id}
        style={{
          backgroundColor: getShiftColor(shift.shiftPattern.name),
          boxShadow: `0 0 0 2px 3px gray`,
          zIndex: index,
          fontSize: '0.65rem'
        }}
      >
        {shift.shiftPattern.name.substring(0, 2).toUpperCase()}
      </ShiftAvatar>
    ));
  };

  let acceptedShifts = 0;

  let completedShifts = 0;
  let totalCount = 0;

  shifts.forEach((shift) => {
    totalCount += shift.count;
    if (shift.isAccepted) {
      acceptedShifts++;
    }
    if (shift.isCompleted) {
      completedShifts++;
    }
  });

  return (
    <Grid item xs={12 / 7}>
      <DayCell
        isCurrentMonth={isCurrentMonth}
        isToday={isToday}
        onClick={(e) => {
          e.stopPropagation();
          console.log('Clicked date:', day.format('YYYY-MM-DD'));
          setSelectedDate(day);
          onClick();
        }}
      >
        <Typography variant="subtitle2">{day.date()}</Typography>
        <Box display="flex" mt={1}>
          {renderShiftAvatars()}
        </Box>
        {shifts.length > 0 && (
          <Typography variant="caption">
            {shifts.reduce((acc, shift) => acc + shift.count, 0)} shifts
          </Typography>
        )}

        {acceptedShifts > 0 && totalCount === acceptedShifts && (
          <CheckCircle size={24} color="green" style={{ marginLeft: 'auto' }} />
        )}
        {acceptedShifts > 0 && totalCount > acceptedShifts && (
          <LoaderCircle
            size={24}
            color="brown"
            style={{ marginLeft: 'auto' }}
          />
        )}

        {completedShifts > 0 && totalCount === completedShifts && (
          <CheckCircleSharp
            style={{ marginLeft: 'auto', color: 'green', fontSize: 24 }}
          />
        )}
        {completedShifts > 0 && totalCount > completedShifts && (
          <CheckCircleSharp
            style={{ marginLeft: 'auto', color: 'brown', fontSize: 24 }}
          />
        )}
      </DayCell>
    </Grid>
  );
};

export default CalendarDay;
