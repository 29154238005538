import React, { useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'src/redux/hook';

interface NextOfKinFormData {
  name: string;
  relationship: string;
  phoneNumber: string;
  mobileNumber: string;
  email: string;
  address: string;
}

interface NextOfKinProps {
  onSubmit: SubmitHandler<{ nextOfKin: NextOfKinFormData }>;
}

const NextOfKin: React.FC<NextOfKinProps> = ({ onSubmit }) => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<NextOfKinFormData>({
    defaultValues: {
      name: '',
      relationship: '',
      phoneNumber: '',
      mobileNumber: '',
      email: '',
      address: ''
    }
  });

  useEffect(() => {
    setValue('name', application?.nextOfKin?.name || '');
    setValue('relationship', application?.nextOfKin?.relationship || '');
    setValue('phoneNumber', application?.nextOfKin?.phoneNumber || '');
    setValue('mobileNumber', application?.nextOfKin?.mobileNumber || '');
    setValue('email', application?.nextOfKin?.email || '');
    setValue('address', application?.nextOfKin?.address || '');
  }, [application, setValue]);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ nextOfKin: data }))}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Next of Kin Information</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Full Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="relationship"
            control={control}
            rules={{ required: 'Relationship is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Relationship"
                fullWidth
                error={!!errors.relationship}
                helperText={errors.relationship?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: 'Phone number is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="mobileNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Mobile Number"
                fullWidth
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="address"
            control={control}
            rules={{ required: 'Address is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address"
                fullWidth
                multiline
                rows={3}
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NextOfKin;
