import React from 'react';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem
} from '@mui/material';

const BillingAddressForm = ({
  billingAddress,
  setBillingAddress,
  useDifferentAddress,
  setUseDifferentAddress,
  userAddress
}) => {
  const handleBillingAddressChange = (event) => {
    setBillingAddress({
      ...billingAddress,
      [event.target.name]: event.target.value
    });
  };

  const handleUseDifferentAddress = (event) => {
    setUseDifferentAddress(event.target.checked);
    if (!event.target.checked) {
      setBillingAddress(userAddress);
    } else {
      setBillingAddress({
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={useDifferentAddress}
              onChange={handleUseDifferentAddress}
              name="useDifferentAddress"
            />
          }
          label="Use a different billing address"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Street Address"
          name="street"
          value={
            useDifferentAddress ? billingAddress.street : userAddress.street
          }
          onChange={handleBillingAddressChange}
          disabled={!useDifferentAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="City"
          name="city"
          value={useDifferentAddress ? billingAddress.city : userAddress.city}
          onChange={handleBillingAddressChange}
          disabled={!useDifferentAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="State/Province"
          name="state"
          value={useDifferentAddress ? billingAddress.state : userAddress.state}
          onChange={handleBillingAddressChange}
          disabled={!useDifferentAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Zip/Postal Code"
          name="zipCode"
          value={
            useDifferentAddress ? billingAddress.zipCode : userAddress.zipCode
          }
          onChange={handleBillingAddressChange}
          disabled={!useDifferentAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Country"
          name="country"
          value={
            useDifferentAddress ? billingAddress.country : userAddress.country
          }
          onChange={handleBillingAddressChange}
          disabled={!useDifferentAddress}
          select
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="CA">Canada</MenuItem>
          <MenuItem value="UK">United Kingdom</MenuItem>
          {/* Add more countries as needed */}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default BillingAddressForm;
