import React, { useState, useCallback } from 'react';
import { Box, keyframes } from '@mui/material';

interface AuthWrapperProps {
  children: React.ReactNode;
  backgroundBrightness?: number; // 0 to 1, where 1 is full brightness
}

const waveAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  backgroundBrightness = 0.7
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.backgroundContainer}>
        {/* <Box
          sx={{
            ...styles.backgroundImage,
            filter: `blur(9px) brightness(${backgroundBrightness})`,
            animation: `${waveAnimation} 15s ease infinite`,
            transform: isHovered ? 'scale(1.05)' : 'scale(1.15)',
            transition: 'transform 0.3s ease-in-out',
          }}
        /> */}
      </Box>
      <Box
        sx={{
          ...styles.content
          // transform: isHovered ? 'scale(1.05)' : 'scale(0.95)',
          // transition: 'transform 0.3s ease-in-out',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: '0px !important'
  },
  backgroundContainer: {
    position: 'absolute',
    top: '-50px',
    left: '-50px',
    right: '-50px',
    bottom: '-50px',
    zIndex: 0
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${require('src/assets/login_bg.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  content: {
    width: '100%',
    margin: '0 auto',
    padding: '20px',
    position: 'relative',
    zIndex: 1
  }
};

export default AuthWrapper;
