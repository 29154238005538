import React, { useState, useRef, useEffect, forwardRef } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Paper,
  List,
  ListItem,
  styled,
  useMediaQuery,
  Divider,
  CircularProgress,
  type ListProps
} from '@mui/material';
import {
  Send,
  AttachFile,
  Mic,
  ArrowBack,
  MoreVert
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import {
  useLazyGetChatsQuery,
  useMarkTaskAsDueMutation,
  useSendChatMutation,
  useSendGlobalChatMutation
} from 'src/services/api';
import { wsHostname } from 'src/api/api';
import { IUser } from 'src/interfaces/user.interface';

interface ChatBoxProps {
  user: {
    user: any;
    type: string;
  };
  isMobile: boolean;
  onClose: () => void;
}

interface Message {
  _id: string;
  sender: any;
  receiver: any;
  content: string;
  messageType: string;
  createdAt: string;
  readBy: string[];
}

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: 'inherit',
  [theme.breakpoints.up('sm')]: {
    padding: '10px'
  }
}));

const ChatHeader = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3),
  backgroundColor: 'inherit',
  borderRadius: '10px',
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  height: '50px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.colors.secondary.main,
    height: '64px'
  },
  filter: 'blur(0px) brightness(0.90)'
}));

const MessageListBase = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  backgroundColor: 'transparent',
  height: `calc(100vh - 128px - ${theme.header.height} - 50px)`,
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 100px - 28px)'
  }
}));

export const MessageList = forwardRef<HTMLUListElement, ListProps>(
  (props, ref) => <MessageListBase {...props} ref={ref} />
);

const MessageBubbleBase = styled(Paper)(({ theme }) => ({
  maxWidth: '70%',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  borderRadius: 16,
  position: 'relative',
  boxShadow: 'none',
  filter: 'blur(0px) brightness(0.90)'
}));

const MessageBubble = ({ isCurrentUser, children }) => {
  const theme = useTheme();
  return (
    <MessageBubbleBase
      sx={{
        alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
        background: isCurrentUser
          ? theme.colors.gradients.secondary + '!important'
          : theme.colors.gradients.primary + '!important',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 2,
          [isCurrentUser ? 'right' : 'left']: -5,
          borderTop: `8px solid ${
            isCurrentUser
              ? theme.colors.warning.main + '!important'
              : theme.colors.secondary.main + '!important'
          }`,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent'
        }
      }}
    >
      {children}
    </MessageBubbleBase>
  );
};

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'inherit',
  height: '50px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.colors.secondary.main,
    height: '64px'
  },
  position: 'sticky',
  bottom: 0,
  zIndex: 1
}));

const ChatBox: React.FC<ChatBoxProps> = ({ user, isMobile, onClose }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const messageListRef = useRef<HTMLUListElement>(null);
  const wsRef = useRef<WebSocket | null>(null);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useAppSelector((state) => state.userState.user);

  const [sendMessage] = useSendChatMutation();
  const [sendGlobal] = useSendGlobalChatMutation();
  const [getMessages, { isLoading, isFetching }] = useLazyGetChatsQuery();
  const [markAsRead] = useMarkTaskAsDueMutation();

  useEffect(() => {
    wsRef.current = new WebSocket(`${wsHostname}?userId=${currentUser._id}`);

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.sender || data.sender === currentUser._id) {
        setMessages((prevMessages) => [...prevMessages, data]);
        if (data.sender) {
          markAsRead(data._id);
        }
        scrollToBottom();
      }
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [currentUser._id, user.user?._id, markAsRead]);

  useEffect(() => {
    if (user.user?._id) {
      setPage(1);
      setHasMore(true);
      setMessages([]);
      loadMessages(1);
    }
  }, [user.user?._id]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        messageListRef.current &&
        messageListRef.current.scrollTop === 0 &&
        !isLoading &&
        !isFetching &&
        hasMore
      ) {
        loadMoreMessages();
      }
    };

    messageListRef.current?.addEventListener('scroll', handleScroll);
    return () =>
      messageListRef.current?.removeEventListener('scroll', handleScroll);
  }, [isLoading, isFetching, hasMore]);

  const loadMessages = async (newPage = 1) => {
    if (!user.user?._id) return;

    try {
      const result = await getMessages({
        otherUserId: user.user._id,
        page: newPage,
        limit: 20
      }).unwrap();

      const newMessages = result.data.messages;
      setMessages((prevMessages) =>
        newPage === 1 ? newMessages : [...newMessages, ...prevMessages]
      );

      setHasMore(result.data.currentPage < result.data.totalPages);
      setPage(result.data.currentPage);

      newMessages.forEach((msg) => {
        if (
          msg.sender._id === user.user?._id &&
          !msg.readBy.includes(currentUser._id)
        ) {
          markAsRead(msg._id);
        }
      });

      if (newPage === 1) {
        scrollToBottom();
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    }
  };

  const loadMoreMessages = () => {
    if (hasMore && !isLoading && !isFetching) {
      loadMessages(page + 1);
    }
  };

  const handleSend = async () => {
    if (message.trim() && user.user?._id) {
      try {
        const result = await sendMessage({
          senderId: currentUser._id,
          recipientId: user.user._id,
          content: message,
          messageType: 'text'
        }).unwrap();
        setMessages((prevMessages) => [...prevMessages, result.data]);
        setMessage('');
        scrollToBottom();
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }, 0);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const isCurrentUserMessage = (msg: Message) => {
    if (typeof msg.sender === 'string') {
      return msg.sender === currentUser._id;
    }
    return msg.sender._id === currentUser._id;
  };

  return (
    <ChatContainer>
      {user.user ? (
        <>
          <ChatHeader elevation={0}>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                sx={{ mr: 1 }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Avatar src={''} sx={{ width: 40, height: 40, mr: 1 }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">{`${user.user?.firstName} ${user.user?.lastName}`}</Typography>
              <Typography
                sx={{ marginTop: '-5px' }}
                variant="subtitle2"
                color="textSecondary"
              >
                {user.type}
              </Typography>
            </Box>
            <IconButton>
              <MoreVert />
            </IconButton>
          </ChatHeader>
          {!isMobile && <Divider sx={{ mt: 1 }} />}

          <Box position="relative" flexGrow={1}>
            <MessageList ref={messageListRef}>
              {isLoading && page === 1 ? (
                <Box display="flex" justifyContent="center" my={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {isFetching && page > 1 && (
                    <Box display="flex" justifyContent="center" my={2}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  {messages.map((msg) => (
                    <ListItem
                      key={msg._id}
                      sx={{
                        display: 'flex',
                        justifyContent: isCurrentUserMessage(msg)
                          ? 'flex-end'
                          : 'flex-start'
                      }}
                    >
                      <MessageBubble isCurrentUser={isCurrentUserMessage(msg)}>
                        <Typography
                          sx={{
                            color: isCurrentUserMessage(msg)
                              ? 'white'
                              : 'inherit',
                            textShadow: isCurrentUserMessage(msg)
                              ? '1px 1px 2px rgba(0,0,0,0.5)'
                              : 'none'
                          }}
                          variant="body2"
                        >
                          {msg.content}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}
                        >
                          {formatTime(msg.createdAt)}
                        </Typography>
                      </MessageBubble>
                    </ListItem>
                  ))}
                </>
              )}
            </MessageList>
          </Box>

          <InputContainer>
            <IconButton size="small">
              <AttachFile />
            </IconButton>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message"
              value={message}
              size="small"
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              sx={{
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  backgroundColor: theme.colors.alpha.black[5]
                }
              }}
            />
            <IconButton
              color="primary"
              onClick={handleSend}
              disabled={!message.trim()}
            >
              {message.trim() ? <Send /> : <Mic />}
            </IconButton>
          </InputContainer>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Typography variant="body1">Select a user to chat with</Typography>
        </Box>
      )}
    </ChatContainer>
  );
};

export default ChatBox;
