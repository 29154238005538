import React, { FC, ReactNode } from 'react';
import { alpha, Box, lighten, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';

import Sidebar from './Sidebar';
import Header from './Header';
import { SidebarProvider, useSidebar } from './sidebarContext';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainContent: FC = () => {
  const theme = useTheme();
  const { isExpanded } = useSidebar();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      component={motion.div}
      initial={{ paddingLeft: theme.sidebar.width }}
      animate={{
        paddingLeft: isMobile ? 0 : isExpanded ? theme.sidebar.width : '80px'
      }}
      transition={{ duration: 0.3 }}
    >
      <Header />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {}
        }}
      >
        <Box display="block">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <SidebarProvider>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: theme.colors.secondary.light,
          [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white'
          },
          overflow: 'hidden',
          '.MuiPageTitle-wrapper': {
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Sidebar />
        <MainContent />
      </Box>
    </SidebarProvider>
  );
};

export default SidebarLayout;
