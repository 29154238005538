import React from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { CreditCard } from '@mui/icons-material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const StyledCardElement = styled(CardElement)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    borderColor: theme.palette.primary.main
  }
}));

const PaymentForm = ({
  personalInfo,
  billingAddress,
  plan,
  organizationId,
  createSubscription,
  isCreatingSubscription,
  setSnackbar,
  handleNext
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${personalInfo.firstName} ${personalInfo.lastName}`,
          email: personalInfo.email,
          phone: personalInfo.phone,
          address: {
            line1: billingAddress.street,
            city: billingAddress.city,
            state: billingAddress.state,
            postal_code: billingAddress.zipCode,
            country: billingAddress.country
          }
        }
      });

      if (error) {
        setSnackbar({ open: true, message: error.message, severity: 'error' });
        return;
      }

      const response = await createSubscription({
        organizationId,
        priceId: plan.stripePriceId,
        paymentMethodId: paymentMethod.id,
        billingDetails: {
          name: `${personalInfo.firstName} ${personalInfo.lastName}`,
          email: personalInfo.email,
          phone: personalInfo.phone,
          address: {
            street: billingAddress.street,
            city: billingAddress.city,
            state: billingAddress.state,
            zipCode: billingAddress.zipCode,
            country: billingAddress.country
          }
        }
      });

      if ('error' in response) {
        setSnackbar({
          open: true,
          message: 'Failed to create subscription',
          severity: 'error'
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Subscription created successfully!',
          severity: 'success'
        });
        handleNext(); // Move to the next step or completion page
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'An unexpected error occurred',
        severity: 'error'
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>
      <StyledCardElement />
      <Box sx={{ mt: 3, mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          You will be charged ${plan.price} {plan.currency} for your{' '}
          {plan.interval}ly subscription to {plan.name}.
        </Typography>
      </Box>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3 }}
        disabled={!stripe || isCreatingSubscription}
        startIcon={
          isCreatingSubscription ? (
            <CircularProgress size={20} />
          ) : (
            <CreditCard />
          )
        }
      >
        {isCreatingSubscription
          ? 'Processing...'
          : `Pay ${plan.price} ${plan.currency}`}
      </Button>
    </form>
  );
};

export default PaymentForm;
