import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { pageTransition, pageVariants } from '../animation';
import { useAppSelector } from 'src/redux/hook';

const OrganizationForm = ({ onSubmit, userId, userAddress }) => {
  const [useUserAddress, setUseUserAddress] = useState(false);
  const userState = useAppSelector((state) => state.userState.user);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      name: '',
      type: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      },
      phone: '',
      email: ''
    }
  });

  const watchedAddress = watch('address');

  useEffect(() => {
    if (useUserAddress && userAddress) {
      setValue('address', userAddress);
    }
  }, [useUserAddress, userAddress, setValue]);

  const handleUseUserAddress = (event) => {
    setUseUserAddress(event.target.checked);
    if (event.target.checked && userState?.address) {
      setValue('address', userState?.address);
    } else {
      setValue('address', {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      });
    }
  };

  const onFormSubmit = async (data) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${apiHostname}/api/v1/organizations`,
        {
          ...data,
          ownerId: userState?._id
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      onSubmit(response.data);
    } catch (error) {
      console.error('Organization creation failed:', error);
      // Handle error (show message to user)
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            borderRadius: '8px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto'
          }}
        >
          <Typography variant="h4" gutterBottom>
            Create Your Organization
          </Typography>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Organization name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Organization Name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: 'Organization type is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.type}>
                      <InputLabel>Organization Type</InputLabel>
                      <Select {...field}>
                        <MenuItem value="agency">Agency</MenuItem>
                        <MenuItem value="home">Home</MenuItem>
                      </Select>
                      {errors.type && (
                        <Typography color="error">
                          {errors.type.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useUserAddress}
                      onChange={handleUseUserAddress}
                      name="useUserAddress"
                      color="primary"
                    />
                  }
                  label="Use my personal address"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address.street"
                  control={control}
                  rules={{ required: 'Street is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Street"
                      error={!!errors.address?.street}
                      helperText={errors.address?.street?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.street}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.city"
                  control={control}
                  rules={{ required: 'City is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="City"
                      error={!!errors.address?.city}
                      helperText={errors.address?.city?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.city}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.state"
                  control={control}
                  rules={{ required: 'State is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="State"
                      error={!!errors.address?.state}
                      helperText={errors.address?.state?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.state}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.zipCode"
                  control={control}
                  rules={{ required: 'Zip Code is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Zip Code"
                      error={!!errors.address?.zipCode}
                      helperText={errors.address?.zipCode?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.zipCode}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address.country"
                  control={control}
                  rules={{ required: 'Country is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Country"
                      error={!!errors.address?.country}
                      helperText={errors.address?.country?.message}
                      disabled={useUserAddress}
                      value={watchedAddress.country}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: 'Phone is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone"
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    padding: '12px',
                    fontSize: '1.1rem',
                    fontWeight: 600
                  }}
                >
                  Create Organization
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </motion.div>
  );
};

export default OrganizationForm;
