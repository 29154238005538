import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Avatar,
  styled,
  CircularProgress,
  Paper,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';
import { _setProfilePicture } from 'src/redux/reducers/users';

const StyledPaper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  margin: 'auto',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4]
  }
}));

const HiddenInput = styled('input')({
  display: 'none'
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export default function PersonalInfo({ onSubmit }) {
  const { control, handleSubmit, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [useUserAddress, setUseUserAddress] = useState(false);

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );
  const userState = useAppSelector((state) => state.userState);

  const submitForm = async (data) => {
    try {
      const response = await axios.post(
        `${apiHostname}/api/v1/application/`,
        {
          personalInfo: {
            ...data,
            address: {
              street: data.street,
              city: data.city,
              state: data.state,
              zipCode: data.zipCode,
              country: data.country
            }
          }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );
      dispatch(
        showSnack({
          message: 'Personal info saved successfully',
          color: 'success'
        })
      );
      onSubmit(data);
    } catch (error) {
      dispatch(showSnack({ message: error.message, color: 'error' }));
      console.error('Error saving data:', error);
    }
  };

  useEffect(() => {
    setValue('firstName', application?.personalInfo?.firstName);
    setValue('lastName', application?.personalInfo?.lastName);
    setValue('dateOfBirth', application?.personalInfo?.dateOfBirth);
    setValue('phone', application?.personalInfo?.phone);
    setValue('email', application?.personalInfo?.email);
    setValue('avatar', application?.personalInfo?.avatarUrl);

    // Set address fields
    setValue('street', application?.personalInfo?.address?.street || '');
    setValue('city', application?.personalInfo?.address?.city || '');
    setValue('state', application?.personalInfo?.address?.state || '');
    setValue('zipCode', application?.personalInfo?.address?.zipCode || '');
    setValue('country', application?.personalInfo?.address?.country || '');
  }, [application, setValue]);

  useEffect(() => {
    if (useUserAddress && userState.user.address) {
      setValue('street', userState.user.address.street);
      setValue('city', userState.user.address.city);
      setValue('state', userState.user.address.state);
      setValue('zipCode', userState.user.address.zipCode);
      setValue('country', userState.user.address.country);
    }
  }, [useUserAddress, userState.user.address, setValue]);

  const handleUseUserAddressChange = (event) => {
    setUseUserAddress(event.target.checked);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({ message: 'Profile picture updated', color: 'success' })
          );
          dispatch(_setProfilePicture(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <StyledPaper>
      <form onSubmit={handleSubmit(submitForm)}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          {isUploading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 120
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {userState.user.role === 'carer' ? (
                <StyledAvatar
                  src={'/profile-image.jpg'}
                  alt={`${userState.user.firstName} ${userState.user.lastName}`}
                  onClick={handleAvatarClick}
                />
              ) : (
                <StyledAvatar
                  src={
                    application.personalInfo?.avatarUrl || '/profile-image.jpg'
                  }
                  alt={`${application.personalInfo?.firstName} ${application.personalInfo?.lastName}`}
                />
              )}
            </>
          )}
          {userState.user.role === 'carer' && (
            <>
              <HiddenInput
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*"
              />
              <Typography variant="body2" color="textSecondary">
                Click on the avatar to update your profile picture
              </Typography>
            </>
          )}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              defaultValue={application?.personalInfo?.firstName || ''}
              rules={{ required: 'First name is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="First Name"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              defaultValue={application?.personalInfo?.lastName || ''}
              rules={{ required: 'Last name is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={application?.personalInfo?.dateOfBirth || ''}
              rules={{ required: 'Date of birth is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Date of Birth"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useUserAddress}
                  onChange={handleUseUserAddressChange}
                  name="useUserAddress"
                  color="primary"
                />
              }
              label="Use my account address"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="street"
              control={control}
              rules={{ required: 'Street is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Street"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              control={control}
              rules={{ required: 'City is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="City"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="state"
              control={control}
              rules={{ required: 'State is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="State"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="zipCode"
              control={control}
              rules={{ required: 'Zip Code is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Zip Code"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="country"
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Country"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              defaultValue={application?.personalInfo?.phone || ''}
              rules={{ required: 'Phone number is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Phone Number"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              defaultValue={application?.personalInfo?.email || ''}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Save & Continue
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </StyledPaper>
  );
}
