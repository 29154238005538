import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { api } from 'src/services/api';
import calendarSlice from './reducers/calendar-slice';
import users from './reducers/users';
import shiftTypes from './reducers/shift-types';
import homeUsers from './reducers/home-users';
import shiftSlice from './reducers/shift';
import invSlice from 'src/redux/reducers/invitations';
import snackSlice from './reducers/snack/snack-slice';
import homeSettings from './reducers/home-settings';
import payment from './reducers/dialogs/payment';
import carerApplicationSlice from './reducers/carer-application';
import shiftPattern from './reducers/shift-pattern';
import messages from './reducers/messages';
import organizationReducer from './reducers/organization-slice';
import organizationRoleReducer from './reducers/organizationrole-slice';
import userReducer from './reducers/user-slice';
import quickStats from './reducers/quick-stats';

const store = configureStore({
  reducer: {
    calendar: calendarSlice,
    user: users,
    shiftType: shiftTypes,
    shifts: shiftSlice,
    invDialog: invSlice,
    snack: snackSlice,
    homeSettings: homeSettings,

    homeUsers: homeUsers,

    paymentDialog: payment,

    shiftPattern: shiftPattern,
    message: messages,
    organization: organizationReducer,
    organizationRole: organizationRoleReducer,
    userState: userReducer,
    quickStats: quickStats,

    carerApplication: carerApplicationSlice,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
