import React from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Paper,
  styled
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatBox from './chat-box';
import { useAppSelector } from 'src/redux/hook';
import {
  useGetAdminStaffsQuery,
  useGetCareStaffsQuery,
  useGetLinkedOrganizationsQuery,
  useGetLinkedOrgranizationAdminsQuery
} from 'src/services/api';
import type { IUser } from 'src/interfaces/heirarchy';

interface MobileStaffListProps {
  onSelectUser: (user: any, type: string) => void;
  selectedUser: any;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  margin: '4px 8px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(2)
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 2)
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 2, 1),
  fontWeight: 'bold',
  color: theme.palette.text.secondary
}));

const MobileStaffList: React.FC<MobileStaffListProps> = ({
  onSelectUser,
  selectedUser
}) => {
  const userState = useAppSelector((state) => state.userState);

  const { data: careStaffs } = useGetCareStaffsQuery(undefined);
  const { data: adminStaffs } = useGetAdminStaffsQuery(undefined);
  const { data: linkedOrganizations } =
    useGetLinkedOrgranizationAdminsQuery(undefined);

  const renderUserList = (users: any, type: string) => (
    <>
      <SectionHeader variant="subtitle2">{type}</SectionHeader>
      <List disablePadding>
        {users?.map((user: any) => (
          <React.Fragment key={user._id}>
            <StyledListItemButton
              selected={selectedUser?._id === user.user?._id}
              onClick={() => onSelectUser(user?.user, type)}
            >
              <ListItemAvatar>
                <StyledAvatar
                  src={''}
                  alt={`${user.user.firstName} ${user.user.lastName}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.user.firstName} ${user.user.lastName}`}
                secondary={type}
                primaryTypographyProps={{
                  variant: 'body1',
                  fontWeight: selectedUser?._id === user._id ? 'bold' : 'normal'
                }}
                secondaryTypographyProps={{
                  variant: 'body2',
                  color: 'textSecondary'
                }}
              />
            </StyledListItemButton>
            <StyledDivider />
          </React.Fragment>
        ))}
      </List>
    </>
  );

  return (
    <Paper elevation={0} sx={{ height: '100%', overflow: 'auto' }}>
      {renderUserList(linkedOrganizations || [], 'Organizations')}
      {renderUserList(careStaffs || [], 'Care Staffs')}
      {renderUserList(adminStaffs || [], 'Admin Staffs')}
      <Dialog
        fullScreen
        open={selectedUser !== null}
        onClose={() => onSelectUser(null, '')}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onSelectUser(null, '')}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedUser &&
                `${selectedUser.firstName} ${selectedUser.lastName}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: 'calc(100% - 56px)' }}>
          {selectedUser && (
            <ChatBox
              user={selectedUser}
              isMobile={true}
              onClose={() => {
                onSelectUser(null, '');
              }}
            />
          )}
        </Box>
      </Dialog>
    </Paper>
  );
};

export default MobileStaffList;
