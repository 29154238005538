import React from 'react';
import { Grid, TextField } from '@mui/material';

const PersonalInfoForm = ({ personalInfo, setPersonalInfo }) => {
  const handlePersonalInfoChange = (event) => {
    setPersonalInfo({
      ...personalInfo,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="First Name"
          name="firstName"
          value={personalInfo.firstName}
          onChange={handlePersonalInfoChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Last Name"
          name="lastName"
          value={personalInfo.lastName}
          onChange={handlePersonalInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={personalInfo.email}
          onChange={handlePersonalInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Phone"
          name="phone"
          value={personalInfo.phone}
          onChange={handlePersonalInfoChange}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfoForm;
