import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from 'src/interfaces/user.interface';
import { api } from 'src/services/api';

interface HomeUsersState {
  users: IUser[];
  loading: boolean;
}

const initialState: HomeUsersState = {
  users: [],
  loading: false
};

const homeUsersSlice = createSlice({
  name: 'homeUsers',
  initialState,
  reducers: {
    fetchUsersStart(state) {
      state.loading = true;
    },
    setUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
      state.loading = false;
    },
    removeUsers(state, action: PayloadAction<string>) {
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    // Add extra reducers here
    builder.addMatcher(
      api.endpoints.getLinkedUsers.matchFulfilled,
      (state, action) => {
        state.users = action.payload.data[0]?.users;
        state.loading = false;
      }
    );
  }
});

export const { fetchUsersStart, setUsers, removeUsers } =
  homeUsersSlice.actions;

export default homeUsersSlice.reducer;
