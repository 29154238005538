import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/system';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  useGetPlanQuery,
  useCreateSubscriptionMutation,
  useGetSubscriptionDetailsQuery
} from 'src/services/api';
import { useAppSelector } from 'src/redux/hook';
import SubscriptionDetails from './views/subscription-details';
import PlanDetails from './views/plan-details';
import PersonalInfoForm from './views/personal-info';
import BillingAddressForm from './views/billing';
import PaymentForm from './views/payment-form';

const stripePromise = loadStripe(
  'pk_test_51Pid1H2M2oCccQjmdDbnK7EEm1aSwZ6Zy76aCgaDKvwUoLw5fjnd20Cq7PUqSEwbOmtoKGFB2ZxZHBaQgIlT0aDd00J4qRyAmm'
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3]
}));

const steps = [
  'Plan Details',
  'Personal Information',
  'Billing Address',
  'Payment Details'
];

const PaymentPage = () => {
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );
  const organizationId = currentOrganization?._id;
  const [activeStep, setActiveStep] = useState(0);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: user.phone || ''
  });
  const [billingAddress, setBillingAddress] = useState({
    street: user.address?.street || '',
    city: user.address?.city || '',
    state: user.address?.state || '',
    zipCode: user.address?.zipCode || '',
    country: user.address?.country || ''
  });
  const [useDifferentAddress, setUseDifferentAddress] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const { data: plan, isLoading: isPlanLoading } = useGetPlanQuery(
    currentOrganization?.type
  );
  const { data: subscriptionDetails, isLoading: isSubscriptionLoading } =
    useGetSubscriptionDetailsQuery(organizationId);
  const [createSubscription, { isLoading: isCreatingSubscription }] =
    useCreateSubscriptionMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return true; // Plan details step is always complete
      case 1:
        return Object.values(personalInfo).every((value) => value !== '');
      case 2:
        return Object.values(billingAddress).every((value) => value !== '');
      case 3:
        return true; // Will be handled by Stripe Elements
      default:
        return false;
    }
  };

  if (isPlanLoading || isSubscriptionLoading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh'
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const isSubscribed =
    subscriptionDetails && subscriptionDetails.status === 'active';

  if (isSubscribed) {
    return (
      <SubscriptionDetails
        plan={plan}
        subscriptionDetails={subscriptionDetails}
        organizationId={organizationId}
      />
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Subscribe to {plan?.name}
      </Typography>
      <StyledPaper>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 4 }}>
          {activeStep === 0 && <PlanDetails plan={plan} />}
          {activeStep === 1 && (
            <PersonalInfoForm
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
            />
          )}
          {activeStep === 2 && (
            <BillingAddressForm
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              useDifferentAddress={useDifferentAddress}
              setUseDifferentAddress={setUseDifferentAddress}
              userAddress={user.address}
            />
          )}
          {activeStep === 3 && (
            <Elements stripe={stripePromise}>
              <PaymentForm
                personalInfo={personalInfo}
                billingAddress={
                  useDifferentAddress ? billingAddress : user.address
                }
                plan={plan}
                organizationId={organizationId}
                createSubscription={createSubscription}
                isCreatingSubscription={isCreatingSubscription}
                setSnackbar={setSnackbar}
                handleNext={handleNext}
              />
            </Elements>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={
              activeStep === steps.length - 1 || !isStepComplete(activeStep)
            }
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default PaymentPage;

// import React from 'react';
// import { useAppSelector } from 'src/redux/hook';
// import SubscriptionManagement from './subsciption-management';

// type Props = {};

// export default function PaymentPage({}: Props) {
//   const { user, currentOrganization } = useAppSelector(
//     (state) => state.userState
//   );
//   const organizationId = currentOrganization?._id;
//   return <SubscriptionManagement organizationId={organizationId} />;
// }
