import React from 'react';
import { Box, useTheme } from '@mui/material';

const GlassEffectBox = ({
  children,
  onSubmit,
  height,
  maxHeight,
  overflow,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 3,
        height: height || 'auto',
        maxHeight: maxHeight || `calc(100vh - ${theme.header.height} - 3rem)`,
        overflow: overflow || 'auto',
        background: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(10px)',
        borderRadius: 2,
        border: '1px solid rgba(255, 255, 255, 0.3)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        },
        ...otherProps
      }}
    >
      {React.cloneElement(children, { onSubmit })}
    </Box>
  );
};

export default GlassEffectBox;
