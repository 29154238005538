import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Snackbar,
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LockResetIcon from '@mui/icons-material/LockReset';
import { apiHostname } from 'src/api/api';
import { useRequestPasswordResetMutation, useResetPasswordMutation } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
}));

// Request Password Reset Dialog
export const RequestResetDialog = ({ open, onClose }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [requestReset, {
        isLoading: requestResetLoading,
        isSuccess: requestResetSuccess,
    }] = useRequestPasswordResetMutation();

    const dispatch = useDispatch();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await requestReset(email).unwrap();

            console.log(response);

            dispatch(showSnack({ message: 'Reset link sent to your email!', color: 'success' }));
            onClose();
        } catch (error) {
            dispatch(showSnack({ message: error.message, color: 'error' }));
            // setSnackbar({ open: true, message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <StyledDialog open={open} onClose={onClose}>
                <DialogTitle>Reset Password</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <IconWrapper>
                            <LockResetIcon color="primary" style={{ fontSize: 48 }} />
                        </IconWrapper>
                        <DialogContentText>
                            Enter your email address and we'll send you a link to reset your password.
                        </DialogContentText>
                        <StyledTextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <LoadingButton
                            loading={requestResetLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Send Reset Link
                        </LoadingButton>
                    </DialogActions>
                </form>
            </StyledDialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
            />
        </>
    );
};

// Reset Password Dialog
export const ResetPasswordDialog = ({
    token,
    open,
    onResetSuccess,
    onClose

}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [resetPassword, { isLoading: resetPasswordLoading, isSuccess: resetPasswordSuccess }] = useResetPasswordMutation();

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setSnackbar({ open: true, message: 'Passwords do not match', severity: 'error' });
            return;
        }
        setLoading(true);
        try {
            const response = await resetPassword({ token, password: password }).unwrap();
            console.log(response)
            dispatch(showSnack({ message: 'Your password has been changed', color: 'success' }));
            onResetSuccess();
            onClose()
        } catch (error) {

            dispatch(showSnack({ message: error.message || 'Error updating the password', color: 'danger' }));


        } finally {
            setLoading(false);
        }
    };

    if (!token) {
        return <Typography color="error">Invalid or missing reset token. Please request a new password reset link.</Typography>;
    }



    return (
        <>
            <StyledDialog open={open} onClose={() => {
                navigate('/');
            }}>
                <DialogTitle>Set New Password</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <IconWrapper>
                            <LockResetIcon color="primary" style={{ fontSize: 48 }} />
                        </IconWrapper>
                        <DialogContentText>
                            Please enter your new password below.
                        </DialogContentText>
                        <StyledTextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="New Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <StyledTextField
                            margin="dense"
                            id="confirmPassword"
                            label="Confirm New Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => navigate('/login')}>Cancel</Button>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Reset Password
                        </LoadingButton>
                    </DialogActions>
                </form>
            </StyledDialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
            />
        </>
    );
};

// // Usage example
// export const PasswordResetFlow = () => {
//     const [openRequestDialog, setOpenRequestDialog] = useState(false);

//     return (
//         <div>
//             <Button onClick={() => setOpenRequestDialog(true)}>Forgot Password?</Button>
//             <RequestResetDialog open={openRequestDialog} onClose={() => setOpenRequestDialog(false)} />
//             {/* ResetPasswordDialog is rendered on the reset password page */}
//         </div>
//     );
// };