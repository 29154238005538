import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  alpha
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonalInfo from './components/personal';
import TaxInfo from './components/tax';
import IdentificationInfo from './components/identification';
import EmploymentDetails from './components/employment';
import BackgroundCheck from './components/background-check';
import QualificationsTraining from './components/qualification';
import HealthInfo from './components/health-info';
import ProfessionalMemberships from './components/professional-membership';
import PensionInfo from './components/pension';
import NextOfKin from './components/nextofkin';
import DiversityMonitoring from './components/diversity';
import CarerSpecifics from './components/carerspecifics';
import BankDetails from './components/bank';
import WorkExperience from './components/work-experience';
import AvailabilitySchedule from './components/availability';
import {
  useLazyGetApplicationQuery,
  useSubmitApplicationSectionMutation
} from 'src/services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useParams } from 'react-router';
import { saveApplication } from 'src/redux/reducers/carer-application';
import { useAppSelector } from 'src/redux/hook';
import RightToWork from './components/righttowork';
import GlassEffectBox from 'src/components/core/ui/glass';
import DBSInformation from './components/dbs';
import SkillsAssessment from './components/skills-assesments';

const categories = [
  { name: 'Personal Information', component: PersonalInfo },
  { name: 'Identification', component: IdentificationInfo },
  { name: 'DBS', component: DBSInformation },
  { name: 'Right to Work', component: RightToWork },
  { name: 'Tax Information', component: TaxInfo },
  { name: 'Bank Details', component: BankDetails },
  { name: 'Work Experience', component: WorkExperience },
  { name: 'Qualifications & Training', component: QualificationsTraining },
  { name: 'Skill Assesment', component: SkillsAssessment },
  { name: 'Availablity', component: AvailabilitySchedule },
  { name: 'Health Information', component: HealthInfo },
  { name: 'Next of Kin', component: NextOfKin },
  { name: 'Diversity Monitoring', component: DiversityMonitoring }
];

export default function CarerApplicationForm() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [submitApplication, setSubmitApplication] =
    useSubmitApplicationSectionMutation();

  const [getApplication, getApplicationState] = useLazyGetApplicationQuery();

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const userState = useAppSelector((state) => state.userState);

  async function fetchCarerProfile(id: string) {
    try {
      const response = await getApplication(id).unwrap();

      console.log(response);
      dispatch(
        saveApplication({
          ...application,
          ...response.data
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchCarerProfile(id);
    } else if (userState.user?._id) {
      fetchCarerProfile(userState.user._id);
    }
  }, [id, userState.user?._id]);

  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCategoryChange = (index) => {
    setSelectedCategory(index);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const response = await submitApplication({
        data
      }).unwrap();
      console.log(response);
      dispatch(
        showSnack({ message: 'Data saved successfully', color: 'success' })
      );
    } catch (error) {
      dispatch(showSnack({ message: error.message, color: 'error' }));
    }
  };

  const SelectedComponent = categories[selectedCategory].component;

  const SideMenu = () => (
    <List>
      {categories.map((category, index) => (
        <ListItem
          button
          key={category.name}
          selected={selectedCategory === index}
          onClick={() => handleCategoryChange(index)}
        >
          <ListItemText
            sx={{
              color: selectedCategory === index ? 'primary.main' : 'gray'
            }}
            primary={category.name}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {!isMobile && (
          <Grid item md={3}>
            <Paper
              sx={{
                overflowY: 'auto',
                height: `calc(100vh - ${theme.header.height} - 3rem)`
              }}
              elevation={3}
            >
              <SideMenu />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={isMobile ? 12 : 9}>
          {isMobile && (
            <Box sx={{ mb: 2 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}

          <GlassEffectBox
            onSubmit={handleSubmit}
            height={`calc(100vh - ${theme.header.height} - 3rem)`}
            maxHeight={`calc(100vh - ${theme.header.height} - 3rem)`}
            overflow="scroll"
          >
            <SelectedComponent onSubmit={handleSubmit} />
          </GlassEffectBox>
        </Grid>
      </Grid>
      {isMobile && (
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <SideMenu />
          </Box>
        </Drawer>
      )}
    </Box>
  );
}
