import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import {
  Sofa,
  Briefcase,
  CheckCircle,
  Clock,
  CalendarDays
} from 'lucide-react';

const QuickStatCard = ({ label, value, icon: Icon, iconColor, bgColor }) => {
  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3, height: '100%' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            backgroundColor: bgColor || 'black',
            borderRadius: 1.5,
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon color={iconColor || 'white'} size={24} />
        </Box>
        <Box>
          <Typography variant="subtitle2" color="text.secondary">
            {label}
          </Typography>
          <Typography variant="h4" component="div" fontWeight="bold">
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const QuickStatsGrid = ({ quickStats }) => {
  const iconMap = {
    'Total Shifts': Briefcase,
    'Completed Shifts': CheckCircle,
    'Pending Shifts': Clock,
    'Previous Month': CalendarDays
  };

  return (
    <Grid container spacing={2}>
      {quickStats.map((stat, index) => (
        <Grid item xs={6} sm={3} key={index}>
          <QuickStatCard
            label={stat.label}
            value={stat.value}
            icon={iconMap[stat.label] || Sofa}
            iconColor={stat.iconColor}
            bgColor={stat.bgColor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export { QuickStatCard, QuickStatsGrid };
