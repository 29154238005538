import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Paper,
  Box,
  IconButton,
  Tooltip,
  Rating
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';

type CoreSkill =
  | 'personalCare'
  | 'mobility'
  | 'medication'
  | 'mealPreparation'
  | 'housekeeping'
  | 'communication'
  | 'timeManagement'
  | 'problemSolving';
type SpecializedSkill =
  | 'dementiaCare'
  | 'palliativeCare'
  | 'mentalHealthAwareness';
type SkillLevel =
  | 'no-experience'
  | 'some-experience'
  | 'experienced'
  | 'highly-experienced';

interface CustomSkill {
  name: string;
  level: number;
}

interface SkillsAssessmentFormData {
  [key: string]: any;
  personalCare: number;
  mobility: number;
  medication: number;
  mealPreparation: number;
  housekeeping: number;
  communication: number;
  timeManagement: number;
  problemSolving: number;
  dementiaCare: SkillLevel;
  palliativeCare: SkillLevel;
  mentalHealthAwareness: SkillLevel;
  additionalSkills: string;
  customSkills: CustomSkill[];
}

interface SkillsAssessmentProps {
  onSubmit: any;
}

const coreSkills: CoreSkill[] = [
  'personalCare',
  'mobility',
  'medication',
  'mealPreparation',
  'housekeeping',
  'communication',
  'timeManagement',
  'problemSolving'
];

const specializedSkills: SpecializedSkill[] = [
  'dementiaCare',
  'palliativeCare',
  'mentalHealthAwareness'
];

const skillLabels: string[] = [
  'Novice',
  'Beginner',
  'Competent',
  'Proficient',
  'Expert'
];

const SkillsAssessment: React.FC<SkillsAssessmentProps> = ({ onSubmit }) => {
  const [newSkillName, setNewSkillName] = useState('');

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<SkillsAssessmentFormData>({
    defaultValues: {
      personalCare: application?.skillsAssessment?.personalCare || 1,
      mobility: application?.skillsAssessment?.mobility || 1,
      medication: application?.skillsAssessment?.medication || 1,
      mealPreparation: application?.skillsAssessment?.mealPreparation || 1,
      housekeeping: application?.skillsAssessment?.housekeeping || 1,
      communication: application?.skillsAssessment?.communication || 1,
      timeManagement: application?.skillsAssessment?.timeManagement || 1,
      problemSolving: application?.skillsAssessment?.problemSolving || 1,
      dementiaCare:
        application?.skillsAssessment?.dementiaCare || 'no-experience',
      palliativeCare:
        application?.skillsAssessment?.palliativeCare || 'no-experience',
      mentalHealthAwareness:
        application?.skillsAssessment?.mentalHealthAwareness || 'no-experience',
      additionalSkills: application?.skillsAssessment?.additionalSkills || '',
      customSkills: application?.skillsAssessment?.customSkills || []
    }
  });
  const [customSkills, setCustomSkills] = useState<CustomSkill[]>(
    application?.skillsAssessment?.customSkills || []
  );

  const userState = useAppSelector((state) => state.user.user);

  const addCustomSkill = () => {
    if (newSkillName.trim() !== '') {
      const updatedSkills = [...customSkills, { name: newSkillName, level: 1 }];
      setCustomSkills(updatedSkills);
      setValue('customSkills', updatedSkills);
      setNewSkillName('');
    }
  };

  const removeCustomSkill = (index: number) => {
    const updatedSkills = customSkills.filter((_, i) => i !== index);
    setCustomSkills(updatedSkills);
    setValue('customSkills', updatedSkills);
  };

  const updateCustomSkillLevel = (index: number, newLevel: number | null) => {
    if (newLevel === null) return;
    const updatedSkills = customSkills.map((skill, i) =>
      i === index ? { ...skill, level: newLevel } : skill
    );
    setCustomSkills(updatedSkills);
    setValue('customSkills', updatedSkills);
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
      <form
        onSubmit={handleSubmit((data) =>
          onSubmit({
            skillsAssessment: {
              ...data,
              customSkills: customSkills
            }
          })
        )}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Skills Assessment
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Rate your proficiency in each skill area. Your honest assessment
              helps us match you with suitable care opportunities.
            </Typography>
          </Grid>

          {coreSkills.map((skill) => (
            <Grid item xs={12} sm={6} key={skill}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography id={`${skill}-rating-label`}>
                  {skill.charAt(0).toUpperCase() +
                    skill.slice(1).replace(/([A-Z])/g, ' $1')}
                </Typography>
                <Controller
                  name={skill}
                  control={control}
                  render={({ field }) => (
                    <Rating
                      {...field}
                      max={5}
                      onChange={(_, value) => field.onChange(value)}
                    />
                  )}
                />
              </Box>
              <Typography variant="caption" color="textSecondary">
                {skillLabels[watch(skill) - 1] || 'Not Rated'}
              </Typography>
            </Grid>
          ))}

          {specializedSkills.map((skill) => (
            <Grid item xs={12} key={skill}>
              <FormControl
                component="fieldset"
                error={!!errors[skill]}
                fullWidth
              >
                <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                  {skill.charAt(0).toUpperCase() +
                    skill.slice(1).replace(/([A-Z])/g, ' $1')}
                </FormLabel>
                <Controller
                  name={skill}
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value="no-experience"
                        control={<Radio />}
                        label="No Experience"
                      />
                      <FormControlLabel
                        value="some-experience"
                        control={<Radio />}
                        label="Some Experience"
                      />
                      <FormControlLabel
                        value="experienced"
                        control={<Radio />}
                        label="Experienced"
                      />
                      <FormControlLabel
                        value="highly-experienced"
                        control={<Radio />}
                        label="Highly Experienced"
                      />
                    </RadioGroup>
                  )}
                />
                {errors[skill] && (
                  <FormHelperText>{errors[skill]?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Custom Skills
            </Typography>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <TextField
                label="Add a custom skill"
                variant="outlined"
                value={newSkillName}
                onChange={(e) => setNewSkillName(e.target.value)}
                sx={{ marginRight: 2, flexGrow: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={addCustomSkill}
                startIcon={<AddIcon />}
              >
                Add Skill
              </Button>
            </Box>
            <Grid container spacing={2}>
              {customSkills.map((skill, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper elevation={1} sx={{ padding: 2 }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle1">{skill.name}</Typography>
                      <Tooltip title="Remove Skill">
                        <IconButton
                          size="small"
                          onClick={() => removeCustomSkill(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Rating
                        value={skill.level}
                        onChange={(_, newValue) =>
                          updateCustomSkillLevel(index, newValue)
                        }
                        max={5}
                      />
                      <Typography variant="caption" color="textSecondary">
                        {skillLabels[skill.level - 1] || 'Not Rated'}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="additionalSkills"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Additional Skills or Qualifications"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  helperText="List any other skills, certifications, or qualifications you'd like to highlight."
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Save & Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SkillsAssessment;
