import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material';
import { Shift } from './types';
import { Person as PersonIcon } from '@mui/icons-material';

interface ViewAssignedStaffDialogProps {
  open: boolean;
  onClose: () => void;
  shift: Shift;
  assignedStaffs?: any[];
}

const ViewAssignedStaffDialog: React.FC<ViewAssignedStaffDialogProps> = ({
  open,
  onClose,
  shift,
  assignedStaffs
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assigned Staff for {shift.shiftPattern.name}</DialogTitle>
      <DialogContent>
        {assignedStaffs.length > 0 ? (
          <List>
            {assignedStaffs.map((user) => (
              <ListItem key={user.user?._id}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.user?.firstName} ${user.user?.lastName}`}
                  secondary={user.user?.email}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No staff assigned to this shift yet.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewAssignedStaffDialog;
