import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';

interface DiversityMonitoringFormData {
  gender: string;
  ageGroup: string;
  ethnicity: string;
  disability: string;
  disabilityDetails?: string;
  sexualOrientation: string;
  religion: string;
}

interface DiversityMonitoringProps {
  onSubmit: any;
}

const DiversityMonitoring: React.FC<DiversityMonitoringProps> = ({
  onSubmit
}) => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<DiversityMonitoringFormData>({
    defaultValues: {
      gender: application?.diversityInfo?.gender || '',
      ageGroup: application?.diversityInfo?.ageGroup || '',
      ethnicity: application?.diversityInfo?.ethnicity || '',
      disability: application?.diversityInfo?.disability || '',
      disabilityDetails: application?.diversityInfo?.disabilityDetails || '',
      sexualOrientation: application?.diversityInfo?.sexualOrientation || '',
      religion: application?.diversityInfo?.religion || ''
    }
  });

  const disability = watch('disability');
  const userState = useAppSelector((state) => state.user.user);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ diversityInfo: data }))}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Diversity Monitoring</Typography>
          <Typography variant="body2" color="textSecondary">
            This information is collected for monitoring purposes only and will
            be treated as confidential.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.gender}>
            <FormLabel component="legend">Gender</FormLabel>
            <Controller
              name="gender"
              control={control}
              rules={{ required: 'Gender is required' }}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                  <FormControlLabel
                    value="prefer-not-to-say"
                    control={<Radio />}
                    label="Prefer not to say"
                  />
                </RadioGroup>
              )}
            />
            {errors.gender && (
              <FormHelperText>{errors.gender.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.ageGroup}>
            <InputLabel id="age-group-label">Age Group</InputLabel>
            <Controller
              name="ageGroup"
              control={control}
              rules={{ required: 'Age group is required' }}
              render={({ field }) => (
                <Select {...field} labelId="age-group-label" label="Age Group">
                  <MenuItem value="18-24">18-24</MenuItem>
                  <MenuItem value="25-34">25-34</MenuItem>
                  <MenuItem value="35-44">35-44</MenuItem>
                  <MenuItem value="45-54">45-54</MenuItem>
                  <MenuItem value="55-64">55-64</MenuItem>
                  <MenuItem value="65+">65+</MenuItem>
                  <MenuItem value="prefer-not-to-say">
                    Prefer not to say
                  </MenuItem>
                </Select>
              )}
            />
            {errors.ageGroup && (
              <FormHelperText>{errors.ageGroup.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.ethnicity}>
            <InputLabel id="ethnicity-label">Ethnicity</InputLabel>
            <Controller
              name="ethnicity"
              control={control}
              rules={{ required: 'Ethnicity is required' }}
              render={({ field }) => (
                <Select {...field} labelId="ethnicity-label" label="Ethnicity">
                  <MenuItem value="white-british">White British</MenuItem>
                  <MenuItem value="white-irish">White Irish</MenuItem>
                  <MenuItem value="white-other">White Other</MenuItem>
                  <MenuItem value="mixed">Mixed</MenuItem>
                  <MenuItem value="asian-british">Asian British</MenuItem>
                  <MenuItem value="asian-other">Asian Other</MenuItem>
                  <MenuItem value="black-british">Black British</MenuItem>
                  <MenuItem value="black-other">Black Other</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="prefer-not-to-say">
                    Prefer not to say
                  </MenuItem>
                </Select>
              )}
            />
            {errors.ethnicity && (
              <FormHelperText>{errors.ethnicity.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.disability}>
            <FormLabel component="legend">
              Do you consider yourself to have a disability?
            </FormLabel>
            <Controller
              name="disability"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="prefer-not-to-say"
                    control={<Radio />}
                    label="Prefer not to say"
                  />
                </RadioGroup>
              )}
            />
            {errors.disability && (
              <FormHelperText>{errors.disability.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {disability === 'yes' && (
          <Grid item xs={12}>
            <Controller
              name="disabilityDetails"
              control={control}
              rules={{ required: 'Please provide details of your disability' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Disability Details"
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.disabilityDetails}
                  helperText={errors.disabilityDetails?.message}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.sexualOrientation}>
            <FormLabel component="legend">Sexual Orientation</FormLabel>
            <Controller
              name="sexualOrientation"
              control={control}
              rules={{ required: 'Sexual orientation is required' }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="heterosexual"
                    control={<Radio />}
                    label="Heterosexual"
                  />
                  <FormControlLabel
                    value="gay"
                    control={<Radio />}
                    label="Gay"
                  />
                  <FormControlLabel
                    value="lesbian"
                    control={<Radio />}
                    label="Lesbian"
                  />
                  <FormControlLabel
                    value="bisexual"
                    control={<Radio />}
                    label="Bisexual"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                  <FormControlLabel
                    value="prefer-not-to-say"
                    control={<Radio />}
                    label="Prefer not to say"
                  />
                </RadioGroup>
              )}
            />
            {errors.sexualOrientation && (
              <FormHelperText>
                {errors.sexualOrientation.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.religion}>
            <InputLabel id="religion-label">Religion or Belief</InputLabel>
            <Controller
              name="religion"
              control={control}
              rules={{ required: 'Religion or belief is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="religion-label"
                  label="Religion or Belief"
                >
                  <MenuItem value="no-religion">No Religion</MenuItem>
                  <MenuItem value="christian">Christian</MenuItem>
                  <MenuItem value="muslim">Muslim</MenuItem>
                  <MenuItem value="hindu">Hindu</MenuItem>
                  <MenuItem value="sikh">Sikh</MenuItem>
                  <MenuItem value="jewish">Jewish</MenuItem>
                  <MenuItem value="buddhist">Buddhist</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="prefer-not-to-say">
                    Prefer not to say
                  </MenuItem>
                </Select>
              )}
            />
            {errors.religion && (
              <FormHelperText>{errors.religion.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DiversityMonitoring;
