import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUser, IUserState } from 'src/interfaces/user.interface';
import { api } from 'src/services/api';

const initialState: IUserState = {
  user: {
    fname: '',
    lname: '',
    nameChangedAt: undefined,
    email: '',
    accountType: undefined,
    isEmailVerified: false,
    emailChangedAt: undefined,
    countryCode: undefined,
    phone: undefined,
    isPhoneVerified: false,
    phoneChangedAt: undefined,
    avatar: undefined,
    website: undefined,
    isPrivate: false,
    accountStatus: undefined,
    token: '',
    verification: {
      isVerified: false,
      category: '',
      verifiedAt: new Date(),
      lastRequestedAt: undefined
    },
    fcmToken: undefined,
    homeId: null,
    agencyId: null,
    staffs: [],
    agencies: [],
    homes: []
  } as IUser,
  loading: false,
  error: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => {
      return action.payload;
    },
    _setFcmToken: (state, action: PayloadAction<string>) => {
      state.user.fcmToken = action.payload;
    },

    _setProfilePicture: (state, action: PayloadAction<string>) => {
      state.user.avatar.url = action.payload;
    },

    clearUser: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.updateAvailability.matchFulfilled,
      (state, action: PayloadAction<IUser>) => {
        state.user = action.payload;
      }
    );
    builder.addMatcher(
      api.endpoints.deleteAvailability.matchFulfilled,
      (state, action: PayloadAction<IUser>) => {
        state.user = action.payload;
      }
    );
  }
});

export const { setUser, clearUser, _setFcmToken, _setProfilePicture } =
  userSlice.actions;
export default userSlice.reducer;
