import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  styled,
  CircularProgress,
  Grid,
  Rating,
  DialogActions,
  Avatar,
  Collapse
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import moment from 'moment';
import {
  useLazyGetShiftPatternsQuery,
  useLazyGetTimesheetsQuery,
  useApproveTimesheetMutation
} from 'src/services/api';
import { Receipt } from 'lucide-react';
import InvoiceDialog from 'src/components/core/dialogs/shifts/invoice-dialog';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import LoadingButton from '@mui/lab/LoadingButton';
import type { ITimesheetResponse } from 'src/interfaces/timesheet';
import { is } from 'date-fns/locale';
import {
  ExpandMore,
  ExpandLess,
  AccessTime,
  AttachMoney,
  Star,
  Comment
} from '@mui/icons-material';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    borderRadius: '0px !important',
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[3]
  }
}));

const StyledChip = styled(Chip)(({ theme, color }) => ({
  fontWeight: 'bold',
  color: theme.palette.getContrastText(theme.palette[color].main)
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const CarerTimesheets = () => {
  const [originalTimesheets, setOriginalTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const today = moment().format('YYYY-MM-DD');

  const [getShiftType] = useLazyGetShiftPatternsQuery(undefined);
  const currentMonth = moment();
  const startOfMonth = currentMonth
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD');
  const endOfMonth = currentMonth.clone().endOf('month').format('YYYY-MM-DD');

  const INITIAL_FILTER_OPTIONS = {
    homeName: '',
    startDate: startOfMonth,
    endDate: endOfMonth,
    shiftType: '',
    status: 'all'
  };

  const [filterOptions, setFilterOptions] = useState(INITIAL_FILTER_OPTIONS);

  const [getTimeSheets] = useLazyGetTimesheetsQuery();
  const [approveTimesheet] = useApproveTimesheetMutation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    timesheets: [],
    totalAmount: 0,
    home: {
      homeId: '',
      homeName: '',
      homeAddress: '',
      homeEmail: '',
      homePhone: ''
    }
  });

  const [rateAndReviewDialogOpen, setRateAndReviewDialogOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [currentTimesheet, setCurrentTimesheet] = useState(null);

  const [expanded, setExpanded] = useState([]);

  const { userState } = useAppSelector((state) => state);
  const { currentOrganization } = userState;

  const dispatch = useDispatch();

  const handleCreateInvoice = async () => {
    const approvedTimesheets = filteredTimesheets.filter(
      (timesheet) => timesheet?.status === 'approved'
    );
    let totalAmount = 0;
    const invoiceTimesheets = [];

    for (const timesheet of approvedTimesheets) {
      try {
        // Find the correct rate based on the care home
        const homeRate = timesheet?.shift_?.shiftPattern?.rates.find(
          (rate) => rate.careHomeId === timesheet?.shift_.homeId
        );

        // Determine if it's a weekend
        const isWeekend = moment(timesheet?.shift_?.date).isoWeekday() > 5;

        // Calculate hourly rate
        const hourlyPay = isWeekend
          ? homeRate?.weekendRate
          : homeRate?.weekdayRate;

        // Find the correct timing based on the care home
        const timing = timesheet?.shift_?.shiftPattern?.timings.find(
          (timing) => timing.careHomeId === timesheet?.shift_.homeId
        );

        // Calculate shift duration using actual start and end times
        const startTime = moment(timing.startTime, 'HH:mm');
        const endTime = moment(timing.endTime, 'HH:mm');

        // Handle cases where the shift goes past midnight
        let shiftDuration;
        if (endTime.isBefore(startTime)) {
          shiftDuration = moment.duration(
            endTime.add(1, 'day').diff(startTime)
          );
        } else {
          shiftDuration = moment.duration(endTime.diff(startTime));
        }

        const hours = shiftDuration.asHours();
        const amount = hourlyPay * hours;
        totalAmount += amount;

        invoiceTimesheets.push({
          ...timesheet,
          hourlyRate: hourlyPay,
          hours: hours,
          amount: amount,
          shiftDate: timesheet?.shift_?.date,
          shiftType: timesheet?.shift_?.shiftPattern?.name,
          carerName: `${timesheet?.carer?.firstName} ${timesheet?.carer?.lastName}`,
          homeName: timesheet?.home?.name
        });
      } catch (error) {
        console.error('Error processing timesheet for invoice:', error);
      }
    }

    setInvoiceData({
      timesheets: invoiceTimesheets,
      totalAmount,
      home: {
        homeName: invoiceTimesheets[0]?.home?.name || '',
        homeId: invoiceTimesheets[0]?.home?._id || '',
        homeAddress: invoiceTimesheets[0]?.home?.address || '',
        homeEmail: invoiceTimesheets[0]?.home?.email || '',
        homePhone: invoiceTimesheets[0]?.home?.phone || ''
      }
    });
    setInvoiceDialogOpen(true);
  };

  async function fetchTimesheets() {
    setIsLoading(true);
    try {
      const response = await getTimeSheets(undefined).unwrap();
      setOriginalTimesheets(response);
      applyFilters(response, INITIAL_FILTER_OPTIONS);
    } catch (error) {
      console.error('Error fetching timesheets:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const isCreateInvoiceDisabled = () => {
    const hasFilters =
      JSON.stringify(filterOptions) !== JSON.stringify(INITIAL_FILTER_OPTIONS);
    const hasApprovedTimesheets = filteredTimesheets.some(
      (timesheet) => timesheet?.status === 'approved'
    );
    return !hasFilters || !hasApprovedTimesheets;
  };

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleFilterChange = (field, value) => {
    setFilterOptions((prev) => ({ ...prev, [field]: value }));
  };

  const applyFilters = (timesheets, options) => {
    const filtered = timesheets.filter((timesheet) => {
      const shiftDate = moment(timesheet?.shift_?.date);
      const filterStartDate = moment(options.startDate);
      const filterEndDate = moment(options.endDate);

      return (
        (options.homeName === '' ||
          timesheet?.shift.home.company?.name
            .toLowerCase()
            .includes(options.homeName.toLowerCase())) &&
        shiftDate.isBetween(filterStartDate, filterEndDate, null, '[]') &&
        (options.shiftType === '' ||
          timesheet?.shift.shiftPattern?.name
            .toLowerCase()
            .includes(options.shiftType.toLowerCase())) &&
        (options.status === 'all' || timesheet?.status === options.status)
      );
    });
    setFilteredTimesheets(filtered);
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = () => {
    applyFilters(originalTimesheets, filterOptions);
  };

  const clearAllFilters = () => {
    setFilterOptions(INITIAL_FILTER_OPTIONS);
    applyFilters(originalTimesheets, INITIAL_FILTER_OPTIONS);
    setFilterDialogOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      default:
        return 'default';
    }
  };

  const handleApproveTimesheet = (timesheet) => {
    setCurrentTimesheet(timesheet);
    setRateAndReviewDialogOpen(true);
  };

  const handleRateAndReview = async () => {
    try {
      const response = await approveTimesheet({
        timesheetId: currentTimesheet?._id,
        rating: ratingValue,
        review: reviewText
      }).unwrap();
      setFilteredTimesheets((prevTimesheets) =>
        prevTimesheets.map((t) =>
          t._id === currentTimesheet?._id ? { ...t, status: 'approved' } : t
        )
      );
      setRateAndReviewDialogOpen(false);
      setRatingValue(null);
      setReviewText('');
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({ message: 'Error approving timesheet', color: 'error' })
      );
    }
  };

  const handleApproveWithoutReview = async (timesheetId) => {
    try {
      const response = await approveTimesheet({
        timesheetId: timesheetId,
        rating: null,
        review: null
      }).unwrap();
      setFilteredTimesheets((prevTimesheets) =>
        prevTimesheets.map((t) =>
          t._id === timesheetId ? { ...t, status: 'approved' } : t
        )
      );
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({ message: 'Error approving timesheet', color: 'error' })
      );
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height})`,
          [theme.breakpoints.up('sm')]: {
            height: `calc(100vh - ${theme.header.height} - 30px)`
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            animation: `fadeIn 1s ease-in`,
            animationFillMode: 'forwards',
            opacity: 0
          }}
        >
          Fetching timesheets...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2
        }}
      >
        <IconButton
          onClick={() => setFilterDialogOpen(true)}
          color={
            JSON.stringify(filterOptions) !==
            JSON.stringify(INITIAL_FILTER_OPTIONS)
              ? 'primary'
              : 'default'
          }
        >
          <FilterList />
        </IconButton>
        {userState?.currentOrganization.type === 'agency' &&
          userState.user.role === 'admin' && (
            <Button
              startIcon={<Receipt />}
              variant="contained"
              color="primary"
              onClick={handleCreateInvoice}
              sx={{ ml: 2 }}
            >
              Create Invoice
            </Button>
          )}
      </Box>

      <List sx={{ padding: 0 }}>
        <Grid container spacing={2}>
          {filteredTimesheets.map((timesheet: ITimesheetResponse) => {
            let primaryText = '';
            let startTime = '';
            let endTime = '';
            let carerPay = 0;
            let homerate = 0;

            const isWeekend = moment(timesheet?.shift_?.date).isoWeekday() > 5;
            if (
              userState.user?.role === 'admin' &&
              currentOrganization?.type === 'home'
            ) {
              primaryText =
                timesheet?.carer?.firstName + ' ' + timesheet?.carer?.lastName;

              const timing = timesheet?.shift_?.shiftPattern?.timings?.find(
                (timing) => timing.careHomeId === userState.user?._id
              );
              startTime = timing?.startTime;
              endTime = timing?.endTime;
            }
            if (
              userState.user?.role === 'admin' &&
              currentOrganization?.type === 'agency'
            ) {
              const userrate =
                timesheet?.shift_?.shiftPattern?.userTypeRates?.find(
                  (rate) =>
                    rate.userType?.toLowerCase() === timesheet?.carer?.role
                );

              const homeRate = timesheet?.shift_?.shiftPattern?.rates?.find(
                (rate) => rate.careHomeId === timesheet?.shift_?.homeId
              );
              homerate = isWeekend
                ? homeRate?.weekendRate
                : homeRate?.weekdayRate;
              carerPay = isWeekend
                ? userrate?.weekendRate
                : userrate?.weekdayRate;

              primaryText =
                timesheet?.carer?.firstName +
                '  ' +
                timesheet?.carer?.lastName +
                ' - ' +
                timesheet?.home?.name;
            }

            const timing = timesheet?.shift_?.shiftPattern?.timings?.find(
              (timing) => timing.careHomeId === timesheet?.shift_.homeId
            );
            startTime = timing?.startTime;
            endTime = timing?.endTime;
            return (
              <Grid item xs={12} sm={6} md={6} key={timesheet?._id}>
                <StyledListItem disablePadding>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (expanded.includes(timesheet?._id)) {
                        setExpanded((prev) =>
                          prev.filter((id) => id !== timesheet?._id)
                        );
                      } else {
                        setExpanded((prev) => [...prev, timesheet?._id]);
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                      <Avatar
                        alt={timesheet?.carer.firstName}
                        src={timesheet?.carer.avatar?.url}
                        sx={{ mr: 2, width: 40, height: 40 }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1" component="div">
                          {`${timesheet?.carer.firstName} ${timesheet?.carer.lastName}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {moment(timesheet?.shift_?.date).format('DD/MM/YYYY')}{' '}
                          - {timesheet?.shift_?.shiftPattern?.name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledChip
                          label={timesheet?.status}
                          color={getStatusColor(timesheet?.status)}
                          size="small"
                          sx={{ mr: 1 }}
                        />
                        {expanded.includes(timesheet?._id) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </Box>
                    </Box>

                    <Collapse in={expanded.includes(timesheet?._id)}>
                      <Divider />
                      <Box
                        sx={{
                          p: 2,
                          bgcolor: theme.colors.alpha.trueWhite[100]
                        }}
                      >
                        <DetailItem>
                          <AccessTime />
                          <Typography variant="body2">
                            Time: {startTime} - {endTime}
                          </Typography>
                        </DetailItem>

                        {timesheet?.rating && (
                          <DetailItem>
                            <Star />
                            <Typography variant="body2">
                              Rating:{' '}
                              <Rating
                                value={timesheet?.rating}
                                readOnly
                                size="small"
                              />
                            </Typography>
                          </DetailItem>
                        )}

                        {timesheet?.review && (
                          <DetailItem>
                            <Comment />
                            <Typography variant="body2">
                              Review: {timesheet?.review}
                            </Typography>
                          </DetailItem>
                        )}

                        {userState.user?.role === 'admin' && (
                          <Box sx={{ mt: 2 }}>
                            <DetailItem>
                              <AttachMoney />
                              <Typography variant="body2">
                                Carer Pay: £{carerPay} | Home Rate: £{homerate}
                              </Typography>
                            </DetailItem>
                          </Box>
                        )}

                        {userState.user?.role === 'admin' &&
                          currentOrganization?.type === 'home' &&
                          timesheet?.status === 'pending' && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  handleApproveTimesheet(timesheet)
                                }
                                sx={{ mr: 1 }}
                              >
                                Review
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  handleApproveWithoutReview(timesheet?._id)
                                }
                              >
                                Approve
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Collapse>
                  </Box>
                </StyledListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>

      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Filter Timesheets</DialogTitle>
        <DialogContent>
          <TextField
            label="Home Name"
            value={filterOptions.homeName}
            onChange={(e) => handleFilterChange('homeName', e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: 0
              }
            }}
          >
            <TextField
              label="Start Date"
              type="date"
              value={filterOptions.startDate}
              onChange={(e) => handleFilterChange('startDate', e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={filterOptions.endDate}
              onChange={(e) => handleFilterChange('endDate', e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Select
            value={filterOptions.shiftType}
            onChange={(e) => handleFilterChange('shiftType', e.target.value)}
            fullWidth
            displayEmpty
            margin="dense"
          >
            <MenuItem value="">All Shift Types</MenuItem>
            <MenuItem value="Longday">Longday</MenuItem>
            <MenuItem value="Night">Night</MenuItem>
            <MenuItem value="Early">Early</MenuItem>
            <MenuItem value="Late">Late</MenuItem>
          </Select>
          <Select
            value={filterOptions.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            fullWidth
            displayEmpty
            margin="dense"
            sx={{ mt: 1 }}
          >
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
          </Select>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={clearAllFilters}
              variant="outlined"
              color="secondary"
            >
              Clear All Filters
            </Button>
            <Button
              onClick={handleApplyFilters}
              variant="contained"
              color="primary"
            >
              Apply Filters
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <InvoiceDialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        timesheets={invoiceData.timesheets}
        totalAmount={invoiceData.totalAmount}
        home={invoiceData.home}
        selectedStartDate={filterOptions.startDate}
        selectedEndDate={filterOptions.endDate}
      />

      <Dialog
        open={rateAndReviewDialogOpen}
        onClose={() => setRateAndReviewDialogOpen(false)}
      >
        <DialogTitle>Rate and Review Carer's Shift</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <Typography variant="body1" gutterBottom>
              Carer: {currentTimesheet?.carer.firstName}{' '}
              {currentTimesheet?.carer.lastName}
            </Typography>
            <Rating
              name="shift-rating"
              value={ratingValue}
              onChange={(_, newValue) => setRatingValue(newValue)}
              precision={1}
              size="large"
            />
            <TextField
              label="Review (optional)"
              multiline
              rows={3}
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRateAndReviewDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleRateAndReview}
          >
            Approve with Review
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CarerTimesheets;
