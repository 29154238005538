import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import './scrollbar.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51Pid1H2M2oCccQjmdDbnK7EEm1aSwZ6Zy76aCgaDKvwUoLw5fjnd20Cq7PUqSEwbOmtoKGFB2ZxZHBaQgIlT0aDd00J4qRyAmm'
);

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </Elements>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
