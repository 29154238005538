import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  alpha,
  Tooltip
} from '@mui/material';
import { Icon } from '@iconify/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSidebarMenu } from './context';
import { MenuItem } from './items';

const SidebarMenuItem: React.FC<{ item: MenuItem; depth?: number }> =
  React.memo(({ item, depth = 0 }) => {
    const { isMinimized, selectedPath, handleItemClick } = useSidebarMenu();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      if (item.submenu) {
        setOpen((prevOpen) => !prevOpen);
      } else {
        handleItemClick(item);
      }
    };

    const isSelected = selectedPath === item.link;

    const menuItemStyle = React.useMemo(
      () => ({
        py: 1,
        minHeight: 40,
        maxHeight: 50,
        marginBlock: '5px !important',
        color: isSelected
          ? theme.colors.alpha.trueWhite[100]
          : theme.colors.alpha.trueWhite[70],
        backgroundColor: isSelected
          ? alpha(theme.colors.alpha.trueWhite[100], 0.06)
          : 'transparent',
        '&:hover': {
          backgroundColor: alpha(theme.colors.alpha.trueWhite[100], 0.06),
          color: theme.colors.alpha.trueWhite[100]
        },
        justifyContent: isMinimized ? 'center' : 'flex-start',
        paddingRight: isMinimized ? theme.spacing(2) : theme.spacing(3)
      }),
      [isSelected, isMinimized, theme, depth]
    );

    const menuItem = (
      <ListItem button onClick={handleClick} sx={menuItemStyle}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: isMinimized ? 'auto' : 40,
            marginRight: isMinimized ? 0 : 2
          }}
        >
          <Icon icon={item.icon} width={24} height={24} />
        </ListItemIcon>
        {!isMinimized && (
          <>
            <ListItemText
              primary={
                <Typography variant="body2" noWrap>
                  {item.label}
                </Typography>
              }
            />
            {item.submenu && (open ? <ExpandLess /> : <ExpandMore />)}
          </>
        )}
        {isMinimized && item.submenu && (
          <ChevronRightIcon
            sx={{
              position: 'absolute',
              right: 4,
              fontSize: 16,
              opacity: 0.7
            }}
          />
        )}
      </ListItem>
    );

    return (
      <>
        {isMinimized && item.submenu ? (
          <Tooltip title={item.label} placement="right">
            {menuItem}
          </Tooltip>
        ) : (
          menuItem
        )}
        {item.submenu && !isMinimized && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              sx={{
                width: '95%',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 'auto'
              }}
              component="div"
              disablePadding
            >
              {item.submenu.map((subItem, index) => (
                <SidebarMenuItem key={index} item={subItem} depth={depth + 1} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  });

const SidebarMenu: React.FC = () => {
  const { menuItems } = useSidebarMenu();

  return (
    <List>
      {menuItems.map((item, index) => (
        <SidebarMenuItem key={index} item={item} />
      ))}
    </List>
  );
};

export default React.memo(SidebarMenu);
