import React from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import {
  useGetAdminStaffsQuery,
  useGetCareStaffsQuery,
  useGetLinkedOrganizationAdminForCarerQuery,
  useGetLinkedOrganizationsQuery,
  useGetLinkedOrgranizationAdminsQuery
} from 'src/services/api';
import type { IUser } from 'src/interfaces/heirarchy';

interface SidebarProps {
  onSelectUser: (user: any, type: string) => void;
  selectedUser: any;
}

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  backgroundColor: 'inherit'
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: '4px 8px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(2)
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 2, 1),
  fontWeight: 'bold',
  color: theme.palette.text.secondary
}));

const Sidebar: React.FC<SidebarProps> = ({ onSelectUser, selectedUser }) => {
  const userState = useAppSelector((state) => state.userState);

  const { data: careStaffs } = useGetCareStaffsQuery(undefined);
  const { data: adminStaffs } = useGetAdminStaffsQuery(undefined);
  const { data: linkedOrganizations } = useGetLinkedOrgranizationAdminsQuery(
    undefined,
    {
      skip: ['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)
    }
  );
  const { data: linkedOrganizationsForCarer } =
    useGetLinkedOrganizationAdminForCarerQuery(undefined, {
      skip: !['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)
    });

  const renderUserList = (users: any, type: string) => (
    <>
      <SectionHeader variant="subtitle2">{type}</SectionHeader>
      <List disablePadding>
        {users?.map((user) => (
          <StyledListItemButton
            key={user._id}
            selected={selectedUser?._id === user.user?._id}
            onClick={() => onSelectUser(user?.user, type)}
          >
            <ListItemAvatar>
              <StyledAvatar
                src={''}
                alt={`${user.firstName} ${user.lastName}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${user.user.firstName} ${user.user.lastName}`}
              secondary={type}
              primaryTypographyProps={{
                variant: 'body1',
                fontWeight: selectedUser?._id === user._id ? 'bold' : 'normal'
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textSecondary'
              }}
            />
          </StyledListItemButton>
        ))}
      </List>
      <Divider />
    </>
  );

  return (
    <StyledBox>
      {renderUserList(
        ['carer', 'nurse', 'senior_carer'].includes(userState?.user?.role)
          ? linkedOrganizationsForCarer
          : linkedOrganizations || [],
        'Organizations'
      )}
      {renderUserList(careStaffs || [], 'Care Staffs')}
      {renderUserList(adminStaffs || [], 'Admin Staffs')}
    </StyledBox>
  );
};

export default Sidebar;
