import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IShiftType, IUserShiftTypeModel } from 'src/interfaces/shift-type.interface';
import { api } from 'src/services/api';

interface ShiftTypesState {
  shiftTypes: IShiftType[];
  _id: string;
}

const initialState: ShiftTypesState = {
  shiftTypes: [],
  _id: null
};

const shiftTypesSlice = createSlice({
  name: 'shiftTypes',
  initialState,
  reducers: {
    _setShiftTypes: (state, action: PayloadAction<IShiftType[]>) => {
      state.shiftTypes = action.payload;
    }

    // Add more reducers as needed
  },
  extraReducers: (builder) => {
    // Add extra reducers as needed
    builder.addMatcher(api.endpoints.setShiftPatternInit.matchFulfilled, (state, { payload }) => {
      state.shiftTypes = payload.data.shifttypes;
      state._id = payload.data._id;
    });
    builder.addMatcher(api.endpoints.getShiftPatterns.matchFulfilled, (state, { payload }) => {
      state.shiftTypes = payload.data.shifttypes;
      state._id = payload.data._id;
    });
    builder.addMatcher(api.endpoints.editShiftPattern.matchFulfilled, (state, { payload }) => {
      state.shiftTypes = payload.data.shifttypes;
      state._id = payload.data._id;
    });
    builder.addMatcher(api.endpoints.deleteShiftPattern.matchFulfilled, (state, { payload }) => {
      state.shiftTypes = payload.data.shifttypes;
      state._id = payload.data._id;
    });
  }
});

export const { _setShiftTypes } = shiftTypesSlice.actions;

export default shiftTypesSlice.reducer;
