import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Grid,
  FormControl,
  Typography,
  FormHelperText,
  TextField,
  Box,
  styled,
  useTheme,
  Paper
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { useAppSelector } from 'src/redux/hook';
import { Eye, File, Upload, Trash } from 'lucide-react';
import { onSubmit } from 'src/components/core/dialogs/shifts/util';

const SectionPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2
}));

const DocumentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2)
}));

const DBSInformation = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  const [dbsFileName, setDbsFileName] = useState('');

  useEffect(() => {
    if (application?.backgroundCheck) {
      setValue('dbsNumber', application.backgroundCheck.dbsNumber || '');
      setDbsFileName(application.backgroundCheck.fileName || '');
    }
  }, [application, setValue]);

  const uploadFile = async (
    file: File,
    retryCount: number = 0
  ): Promise<any> => {
    const maxRetries = 3;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('section', 'backgroundCheck');
    formData.append('documentType', 'basic');
    try {
      const response = await axios.post(
        `${apiHostname}/api/v1/application/upload-document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response;
    } catch (error: any) {
      if (
        retryCount < maxRetries &&
        error.message.includes('metadata for object')
      ) {
        console.log(
          `Retrying upload. Attempt ${retryCount + 1} of ${maxRetries}`
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return uploadFile(file, retryCount + 1);
      }
      throw error;
    }
  };

  const submitDbsSection = async (data: any) => {
    setIsLoading(true);
    try {
      if (data.dbsCertificate && data.dbsCertificate.length > 0) {
        await uploadFile(data.dbsCertificate[0]);
      }

      onSubmit({
        backgroundCheck: { dbsNumber: data.dbsNumber }
      });

      dispatch(
        showSnack({
          message: 'DBS information saved successfully',
          color: 'success'
        })
      );

      // You might want to update your global state here if necessary
    } catch (error: any) {
      dispatch(
        showSnack({
          message:
            error.response?.data?.message ||
            'An error occurred while saving DBS info. Please try again.',
          color: 'error'
        })
      );
      console.error('Error saving DBS data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${apiHostname}/api/v1/application/delete-document/backgroundCheck`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      dispatch(
        showSnack({
          message: 'DBS Certificate deleted successfully',
          color: 'success'
        })
      );

      setDbsFileName('');
      setValue('dbsCertificate', null);
      setValue('dbsNumber', '');

      // You might want to update your global state here if necessary
    } catch (error: any) {
      dispatch(
        showSnack({
          message:
            error.response?.data?.message ||
            'An error occurred while deleting DBS Certificate',
          color: 'error'
        })
      );
      console.error('Error deleting document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = () => {
    const url = application?.backgroundCheck?.documentUrl;
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const renderUploadedDocument = () => {
    if (!dbsFileName) return null;

    return (
      <DocumentPaper elevation={2}>
        <Box display="flex" alignItems="center">
          <File
            size={24}
            color={theme.palette.primary.main}
            style={{ marginRight: theme.spacing(2) }}
          />
          <Typography variant="body1">{dbsFileName}</Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            startIcon={<Eye />}
            onClick={handleView}
            sx={{ mr: 1 }}
          >
            View
          </Button>
          <Button
            variant="outlined"
            startIcon={<Trash />}
            onClick={handleDelete}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </DocumentPaper>
    );
  };

  return (
    <SectionPaper elevation={3}>
      <Typography variant="h6" gutterBottom>
        DBS Information
      </Typography>
      <form onSubmit={handleSubmit(submitDbsSection)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.dbsNumber}>
              <Controller
                name="dbsNumber"
                control={control}
                defaultValue=""
                rules={{ required: 'DBS Number is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="DBS Number"
                    error={!!errors.dbsNumber}
                    helperText={errors.dbsNumber?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="dbsCertificate"
              control={control}
              defaultValue={null}
              rules={{
                required: !dbsFileName && 'DBS Certificate upload is required'
              }}
              render={({ field: { onChange, onBlur, value, ...field } }) => (
                <Box>
                  <input
                    {...field}
                    type="file"
                    id="dbs-certificate-upload"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files && files.length > 0) {
                        onChange(files);
                        setDbsFileName(files[0].name);
                      }
                    }}
                    onBlur={onBlur}
                    accept=".pdf,.png,.jpg,.jpeg"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                  />
                  {renderUploadedDocument()}
                  <Button
                    variant="contained"
                    startIcon={<Upload />}
                    color="primary"
                    onClick={handleUploadClick}
                    sx={{
                      borderRadius: theme.shape.borderRadius * 3,
                      padding: theme.spacing(1.5, 3),
                      textTransform: 'none',
                      fontWeight: 600,
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none'
                      }
                    }}
                  >
                    {dbsFileName
                      ? 'Replace DBS Certificate'
                      : 'Upload DBS Certificate'}
                  </Button>
                </Box>
              )}
            />
            {errors.dbsCertificate && (
              <FormHelperText error>
                {errors.dbsCertificate.message}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                mt: 2,
                py: 1.5,
                px: 4,
                borderRadius: theme.shape.borderRadius * 2,
                textTransform: 'none',
                fontWeight: 600
              }}
            >
              {isLoading ? 'Saving...' : 'Save DBS Information'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SectionPaper>
  );
};

export default DBSInformation;
