import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import { pageTransition, pageVariants } from '../animation';
import {
  useGetUserQuery,
  useLazyGetUserQuery,
  useRegisterMutation
} from 'src/services/api';

const roleMapping = {
  Owner: 'owner',
  Admin: 'admin',
  Manager: 'manager',
  Carer: 'carer',
  'Senior Carer': 'senior_carer',
  Nurse: 'nurse',
  'HR Manager': 'hr_manager',
  'Accounting Manager': 'accounting_manager',
  Staff: 'staff'
};

// Create a reverse mapping for easy lookup
const reversedRoleMapping = Object.fromEntries(
  Object.entries(roleMapping).map(([key, value]) => [value, key])
);

const SignupForm = ({ onSubmit }) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [getUser] = useLazyGetUserQuery();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      phone: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      }
    }
  });

  const [register] = useRegisterMutation();

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const onSubmitForm = async (data) => {
    setLoading(true);
    try {
      const response = await register(data).unwrap();
      localStorage.setItem('token', data.token);
      localStorage.setItem('userData', JSON.stringify(data.user));
      onSubmit(response);
    } catch (error) {
      console.error('Registration failed:', error);
      // Handle error (show message to user)
    } finally {
      setLoading(false);
    }
  };

  const password = watch('password');

  const steps = [
    // Step 1: Name
    <motion.div
      key="name"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Typography variant="h4" gutterBottom>
        What's your name?
      </Typography>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: 'First name is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="First Name"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{ required: 'Last name is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            margin="normal"
          />
        )}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Box>
    </motion.div>,

    // Step 2: Email and Password
    <motion.div
      key="email"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Typography variant="h4" gutterBottom>
        Enter your email and password
      </Typography>
      <Controller
        name="email"
        control={control}
        rules={{
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="email"
            label="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: 'Password is required',
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters'
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="password"
            label="Password"
            error={!!errors.password}
            helperText={errors.password?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          required: 'Please confirm your password',
          validate: (value) =>
            value === password || 'The passwords do not match'
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="password"
            label="Confirm Password"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            margin="normal"
          />
        )}
      />
      <Box mt={2}>
        <Button
          variant="outlined"
          onClick={prevStep}
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Box>
    </motion.div>,

    // Step 3: Role and Phone
    <motion.div
      key="role"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Typography variant="h4" gutterBottom>
        What's your role?
      </Typography>
      <Controller
        name="role"
        control={control}
        rules={{ required: 'Role is required' }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal" error={!!errors.role}>
            <InputLabel>Role</InputLabel>
            <Select
              {...field}
              onChange={(e) => {
                // Map the selected value to the backend role before updating the form
                const backendRole =
                  roleMapping[e.target.value as keyof typeof roleMapping];
                field.onChange(backendRole);
              }}
              value={
                field.value
                  ? reversedRoleMapping[
                      field.value as keyof typeof reversedRoleMapping
                    ]
                  : ''
              }
            >
              {Object.entries(roleMapping).map(([displayName, value]) => (
                <MenuItem key={value} value={displayName}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.role?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name="phone"
        control={control}
        rules={{ required: 'Phone number is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Phone Number"
            error={!!errors.phone}
            helperText={errors.phone?.message}
            margin="normal"
          />
        )}
      />
      <Box mt={2}>
        <Button
          variant="outlined"
          onClick={prevStep}
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Box>
    </motion.div>,

    // Step 4: Address
    <motion.div
      key="address"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Typography variant="h4" gutterBottom>
        What's your address?
      </Typography>
      <Controller
        name="address.street"
        control={control}
        rules={{ required: 'Street is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Street"
            error={!!errors.address?.street}
            helperText={errors.address?.street?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="address.city"
        control={control}
        rules={{ required: 'City is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="City"
            error={!!errors.address?.city}
            helperText={errors.address?.city?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="address.state"
        control={control}
        rules={{ required: 'State is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="State"
            error={!!errors.address?.state}
            helperText={errors.address?.state?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="address.zipCode"
        control={control}
        rules={{ required: 'Zip code is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Zip Code"
            error={!!errors.address?.zipCode}
            helperText={errors.address?.zipCode?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="address.country"
        control={control}
        rules={{ required: 'Country is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Country"
            error={!!errors.address?.country}
            helperText={errors.address?.country?.message}
            margin="normal"
          />
        )}
      />
      <Box mt={2}>
        <Button
          variant="outlined"
          onClick={prevStep}
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmitForm)}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>
    </motion.div>
  ];

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        borderRadius: '8px',
        padding: '24px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '24px',
          borderRadius: '8px'
        }}
      >
        <AnimatePresence mode="wait">{steps[step]}</AnimatePresence>
      </Box>
    </Container>
  );
};

export default SignupForm;
