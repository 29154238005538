// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence
} from 'firebase/auth';
import { firebaseConfig } from './firebaseConfig';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Capacitor } from '@capacitor/core';
import { getDatabase } from 'firebase/database';
import { getMessaging, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const dbRt = getDatabase();
export let messaging;
try {
  messaging = getMessaging(firebaseApp);
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
  });
} catch (e) {
  console.log('Messaging not supported');
}
