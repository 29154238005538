import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router';
import GetStarted from './views/get-started';
import SignupForm from './views/signup-form';
import OrganizationForm from './views/org-form';
import AgencySelection from './views/agency-selection';
import { pageTransition, pageVariants } from './animation';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import {
  _setCurrentOrganization,
  _setPendingJoinRequest,
  _setUser
} from 'src/redux/reducers/user-slice';

const SignupProcess = () => {
  const [step, setStep] = useState('getStarted');
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (userState.user?._id) {
      if (
        !userState?.currentOrganization &&
        (userState?.user?.role === 'carer' || userState?.user?.role === 'nurse')
      ) {
        setStep('agencySelection');
      } else {
        setStep('organizationSignup');
      }
    }
  }, [navigate, userState]);

  const dispatch = useDispatch();

  const handleStart = () => setStep('userSignup');

  const handleUserSignup = (data) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('userData', JSON.stringify(data.user));
    setUserData(data);
    dispatch(_setUser(data.user));
    dispatch(_setCurrentOrganization(data.organization));
    dispatch(_setPendingJoinRequest(data.pendingJoinRequest));

    if (data.user.role === 'admin') {
      setStep('organizationSignup');
    } else if (data.user.role === 'carer' || data.user.role === 'nurse') {
      setStep('agencySelection');
    } else {
      // For other roles, navigate to home page
      navigate('/');
    }
  };

  const handleOrganizationSignup = (data) => {
    // Handle organization data if needed
    setStep('complete');
  };

  const handleAgencySelection = () => {
    setStep('complete');
  };

  return (
    <Container>
      <AnimatePresence mode="wait">
        {step === 'getStarted' && (
          <GetStarted onStart={handleStart} key="getStarted" />
        )}
        {step === 'userSignup' && (
          <SignupForm onSubmit={handleUserSignup} key="userSignup" />
        )}
        {step === 'organizationSignup' && (
          <OrganizationForm
            onSubmit={handleOrganizationSignup}
            userId={userData?.user.id}
            userAddress={userData?.user.address}
            key="orgSignup"
          />
        )}
        {step === 'agencySelection' && (
          <AgencySelection
            onSubmit={handleAgencySelection}
            user={userData?.user}
            key="agencySelection"
          />
        )}
        {step === 'complete' && (
          <motion.div
            key="complete"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh'
              }}
            >
              <Typography variant="h4">Registration Complete!</Typography>
              <Typography>Thank you for signing up.</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
                sx={{ marginTop: 2 }}
              >
                Go to Home
              </Button>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default SignupProcess;
