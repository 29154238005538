import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Chip,
  InputAdornment,
  useTheme
} from '@mui/material';
import { Link, Close, Clear } from '@mui/icons-material';
import {
  useSearchOrganizationsQuery,
  useSendLinkOrganizationMutation
} from 'src/services/api';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

interface SearchOrganization {
  _id: string;
  name: string;
  type: 'agency' | 'home';
}

interface SearchOrganizationsModalProps {
  open: boolean;
  onClose: () => void;
}

const SearchOrganizationsModal: React.FC<SearchOrganizationsModalProps> = ({
  open,
  onClose
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchOrganization[]>([]);
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );
  const handleSearch = () => {
    // This function is no longer needed as the search is triggered by the query hook
  };

  const dispatch = useDispatch();

  const handleLinkOrganization = async (orgId: string) => {
    // Implement the actual linking logic here
    try {
      const response = await sendInvitation({
        fromOrganizationId: currentOrganization._id,
        toOrganizationId: orgId
      }).unwrap();
      console.log('Invitation sent:', response);
      dispatch(showSnack({ message: 'Invitation sent', color: 'success' }));
      onClose();
    } catch (error) {
      dispatch(showSnack({ message: error.message, color: 'error' }));
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === '') {
      setSearchResults([]);
    }
  };

  const {
    data: searchResultsData,
    isLoading,
    error
  } = useSearchOrganizationsQuery(
    {
      term: searchTerm,
      exludedType: user.role
    },
    {
      skip: searchTerm.length < 3
    }
  );

  const [sendInvitation, { isLoading: isSending }] =
    useSendLinkOrganizationMutation();

  useEffect(() => {
    if (searchResultsData?.organizations) {
      setSearchResults(searchResultsData.organizations);
    } else {
      setSearchResults([]);
    }
  }, [searchResultsData]);

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Search Organizations
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search organizations..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={handleClearSearch} edge="end">
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
        </Box>
        {isLoading && <Typography>Loading...</Typography>}
        {error && (
          <Typography color="error">Error: {error.toString()}</Typography>
        )}
        <List>
          {searchResults.map((org) => (
            <ListItem
              key={org._id}
              divider
              sx={{
                display: 'flex'
              }}
            >
              <ListItemText
                primary={org.name}
                secondary={
                  <Chip
                    label={org.type}
                    size="small"
                    sx={{
                      backgroundColor: theme.colors.success.main
                    }}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="link"
                  onClick={() => handleLinkOrganization(org._id)}
                >
                  <Link />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {!isLoading && searchResults.length === 0 && searchTerm.length >= 3 && (
          <Typography variant="body2" color="text.secondary" align="center">
            No results found
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchOrganizationsModal;
