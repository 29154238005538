import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import { Shift } from './types';
import { useGetCareStaffsQuery } from 'src/services/api';
import type { IStaff } from 'src/interfaces/staffs';

interface AssignStaffDialogProps {
  open: boolean;
  onClose: () => void;
  shift: Shift;
  onAssign: (staffIds: string[]) => void;
}

const AssignStaffDialog: React.FC<AssignStaffDialogProps> = ({
  open,
  onClose,
  shift,
  onAssign
}) => {
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const {
    data: careStaffs,
    isLoading,
    isError
  } = useGetCareStaffsQuery(undefined);

  const handleStaffToggle = (staffId: string) => {
    setSelectedStaff((prev) => {
      if (prev.includes(staffId)) {
        return prev.filter((id) => id !== staffId);
      } else if (prev.length < shift.count) {
        return [...prev, staffId];
      }
      return prev;
    });
  };

  const handleAssign = () => {
    onAssign(selectedStaff);
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography color="error">Error loading staff data</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Assign Staff to {shift.shiftPattern.name}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Select {shift.count} staff members for this shift
        </Typography>
        <Grid container spacing={2}>
          {careStaffs?.map((staff: IStaff) => (
            <Grid item xs={12} sm={6} md={4} key={staff._id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStaff.includes(staff.user?._id)}
                    onChange={() => handleStaffToggle(staff?.user?._id)}
                    disabled={
                      selectedStaff.length >= shift.count &&
                      !selectedStaff.includes(staff?.user?._id)
                    }
                  />
                }
                label={`${staff.user?.firstName} ${staff.user?.lastName}`}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAssign}
          disabled={selectedStaff.length !== shift.count}
          variant="contained"
          color="primary"
        >
          Assign Staff
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignStaffDialog;
