import { SnackbarOrigin } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SnackState {
    open: boolean;
    message: string;
    color?: any;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
    action?: 'bug-report' | 'none';
    title?: string;
}

const initialState: SnackState = {
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: '',
    color: 'info',
    title: ''
};

const snackSlice = createSlice({
    name: 'snack',
    initialState,
    reducers: {
        showSnack: (state, action: PayloadAction<Omit<SnackState, 'open'>>) => {
            return {
                ...state,
                ...action.payload,
                open: true
            };
        },
        hideSnack: (state) => {
            return {
                ...initialState
            };
        }
    }
});

export const { showSnack, hideSnack } = snackSlice.actions;

export default snackSlice.reducer;