import React, { useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';

interface BankDetailsFormData {
  accountHolderName: string;
  bankName: string;
  accountNumber: string;
  sortCode: string;
  iban?: string;
  bic?: string;
  concentToDirectDeposit?: boolean;
}

interface BankDetailsProps {
  onSubmit: SubmitHandler<{ bankDetails: BankDetailsFormData }>;
}

const BankDetails: React.FC<BankDetailsProps> = ({ onSubmit }) => {
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  console.log(application);

  const { accountHolderName, bankName, accountNumber, sortCode, iban } =
    application?.bankDetails;
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<BankDetailsFormData>({
    defaultValues: {
      accountHolderName: accountHolderName || '',
      bankName: bankName || '',
      accountNumber: accountNumber || '',
      sortCode: sortCode || '',
      iban: iban || '',
      bic: '',
      concentToDirectDeposit: false
    }
  });

  useEffect(() => {
    setValue('accountHolderName', application?.bankDetails?.accountHolderName);
    setValue('bankName', application?.bankDetails?.bankName);
    setValue('accountNumber', application?.bankDetails?.accountNumber);
    setValue('sortCode', application?.bankDetails?.sortCode);
    setValue('iban', application?.bankDetails?.iban);
    setValue(
      'concentToDirectDeposit',
      application?.bankDetails?.concentToDirectDeposit
    );
  }, [application]);

  const dispatch = useDispatch();

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ bankDetails: data }))}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Bank Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="accountHolderName"
            control={control}
            defaultValue={application?.bankDetails?.accountHolderName}
            rules={{ required: 'Account holder name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Account Holder Name"
                fullWidth
                defaultValue={application?.bankDetails?.accountHolderName || ''}
                error={!!errors?.accountHolderName}
                helperText={errors?.accountHolderName?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="bankName"
            control={control}
            defaultValue={application?.bankDetails?.bankName || ''}
            rules={{ required: 'Bank name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Bank Name"
                fullWidth
                error={!!errors?.bankName}
                helperText={errors?.bankName?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="accountNumber"
            defaultValue={application?.bankDetails?.accountNumber || ''}
            control={control}
            rules={{
              required: 'Account number is required',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Account number must be 8 digits'
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Account Number"
                fullWidth
                error={!!errors?.accountNumber}
                helperText={errors?.accountNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="sortCode"
            control={control}
            defaultValue={application?.bankDetails?.sortCode || ''}
            rules={{
              required: 'Sort code is required',
              pattern: {
                value: /^[0-9]{6}$/,
                message: 'Sort code must be 6 digits'
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Sort Code"
                fullWidth
                error={!!errors?.sortCode}
                helperText={errors?.sortCode?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="iban"
            control={control}
            defaultValue={application?.bankDetails?.iban || ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="IBAN (Optional)"
                fullWidth
                error={!!errors?.iban}
                helperText={errors?.iban?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                name="concentToDirectDeposit"
                control={control}
                rules={{ required: 'You must consent to direct deposit' }}
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} />
                )}
              />
            }
            label="I consent to receive payment via direct deposit to the account details provided above"
          />
          {errors?.concentToDirectDeposit && (
            <FormHelperText error>
              {errors?.concentToDirectDeposit.message}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save Bank Details
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BankDetails;
