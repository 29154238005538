import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import moment from 'moment';
import {
  useGetAssignmentsForUserQuery,
  useCreateTimesheetRequestMutation
} from 'src/services/api';
import {
  CalendarContainer,
  DayCell,
  ShiftAvatar,
  ShiftIndicator
} from './calendar-views/styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

interface ShiftAssignment {
  _id: string;
  status: string;
  shift: {
    _id: string;
    date: string;
    shiftPattern: any;
    homeId: any;
  };
  timesheet?: {
    status: string;
  };
}

const CareStaffShiftCalendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [assignments, setAssignments] = useState<
    Record<string, ShiftAssignment[]>
  >({});
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [isViewShiftOpen, setIsViewShiftOpen] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState<ShiftAssignment[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const {
    data: userAssignments,
    isLoading,
    isError
  } = useGetAssignmentsForUserQuery(undefined);

  const [requestTimeSheet, requestTimeSheetState] =
    useCreateTimesheetRequestMutation();

  useEffect(() => {
    if (userAssignments) {
      const groupedAssignments = groupAssignmentsByDate(userAssignments);
      setAssignments(groupedAssignments);
    }
  }, [userAssignments]);

  const groupAssignmentsByDate = (
    assignmentsData: ShiftAssignment[]
  ): Record<string, ShiftAssignment[]> => {
    return assignmentsData?.reduce((acc, assignment) => {
      const dateKey = moment(assignment.shift?.date).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(assignment);
      return acc;
    }, {} as Record<string, ShiftAssignment[]>);
  };

  const handlePrevMonth = (): void => {
    setCurrentDate(moment(currentDate).subtract(1, 'month'));
  };

  const handleNextMonth = (): void => {
    setCurrentDate(moment(currentDate).add(1, 'month'));
  };

  const handleDayClick = (day: moment.Moment) => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    setSelectedDate(day);
    setSelectedShifts(dayAssignments);
    setIsViewShiftOpen(true);
  };

  const handleRequestTimesheet = async (shift: ShiftAssignment) => {
    try {
      const response = await requestTimeSheet({
        shiftId: shift.shift?._id,
        shiftPatternId: shift.shift?.shiftPattern._id,
        homeId: shift.shift?.homeId._id
      }).unwrap();
      dispatch(showSnack({ message: response.message, color: 'success' }));
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error?.message || 'Error requesting timesheet. Try again later',
          color: 'danger'
        })
      );
      console.log(error);
    }
  };

  const getShiftColor = (shiftName: string): string => {
    let hash = 0;
    for (let i = 0; i < shiftName?.length; i++) {
      hash = shiftName?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 100%, 70%)`;
  };

  const renderCalendarDay = (
    day: moment.Moment,
    isCurrentMonth: boolean
  ): JSX.Element => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    const isToday = day.isSame(moment(), 'day');

    const renderShiftAvatars = () => {
      return dayAssignments.slice(0, 2).map((assignment, index) => (
        <ShiftAvatar
          key={assignment._id}
          style={{
            backgroundColor: getShiftColor(assignment.shift?.shiftPattern.name),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            zIndex: index,
            fontSize: '0.65rem'
          }}
        >
          {assignment.shift?.shiftPattern?.name.substring(0, 2).toUpperCase()}
        </ShiftAvatar>
      ));
    };

    if (isMobile) {
      return (
        <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
          <Badge
            badgeContent={dayAssignments.length}
            color="primary"
            invisible={dayAssignments.length === 0}
            sx={{
              '& .MuiBadge-badge': {
                right: 5,
                top: 5,
                padding: '0 4px',
                color: 'white',
                boxShadow: '0.5px 0px 0px 0.1px rgba(0,0,0,0.2)'
              }
            }}
          >
            <Paper
              elevation={1}
              sx={{
                height: '2.5rem',
                width: '2.5rem',
                margin: 'auto',
                borderRadius: '30%',
                border: isToday
                  ? `1px solid ${theme.palette.error.main}`
                  : 'none',
                backgroundColor: isCurrentMonth
                  ? 'white'
                  : theme.palette.action.hover,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => handleDayClick(day)}
            >
              <Typography variant="caption">{day?.date()}</Typography>
            </Paper>
          </Badge>
        </Grid>
      );
    }

    return (
      <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
        <DayCell
          isCurrentMonth={isCurrentMonth}
          isToday={isToday}
          onClick={() => handleDayClick(day)}
        >
          <Typography variant="subtitle2">{day.date()}</Typography>
          {dayAssignments.length > 0 && <ShiftIndicator />}
          <Box display="flex" mt={1}>
            {renderShiftAvatars()}
          </Box>
          {dayAssignments.length > 0 && (
            <Typography variant="caption">
              {dayAssignments.length}{' '}
              {dayAssignments.length === 1 ? 'shift' : 'shifts'}
            </Typography>
          )}
        </DayCell>
      </Grid>
    );
  };

  const renderCalendarDays = (): JSX.Element[] => {
    const days: JSX.Element[] = [];
    const startDate = moment(currentDate).startOf('month').startOf('week');
    const endDate = moment(currentDate).endOf('month').endOf('week');

    let day = startDate.clone();
    while (day.isSameOrBefore(endDate)) {
      const isCurrentMonth = day.month() === currentDate.month();
      days.push(renderCalendarDay(day.clone(), isCurrentMonth));
      day.add(1, 'day');
    }
    return days;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error loading shifts. Please try again later.
      </Typography>
    );
  }

  return (
    <CalendarContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">{currentDate.format('MMMM YYYY')}</Typography>
        <Box>
          <IconButton onClick={handlePrevMonth}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <Grid item xs={12 / 7} key={day}>
            <Typography variant="subtitle1" align="center">
              {day}
            </Typography>
          </Grid>
        ))}
        {isMobile && !showFullCalendar
          ? renderCalendarDays().slice(0, 7)
          : renderCalendarDays()}
      </Grid>
      {isMobile && (
        <Box
          onClick={() => setShowFullCalendar(!showFullCalendar)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            cursor: 'pointer'
          }}
        >
          <Typography variant="button" sx={{ mr: 1 }}>
            {showFullCalendar ? 'Show Current Week' : 'View Full Month'}
          </Typography>
          {showFullCalendar ? <ExpandLess /> : <ExpandMore />}
        </Box>
      )}

      <Dialog
        open={isViewShiftOpen}
        onClose={() => setIsViewShiftOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Shifts for {selectedDate?.format('MMMM D, YYYY')}
        </DialogTitle>
        <DialogContent>
          {selectedShifts.map((assignment) => (
            <Box
              key={assignment._id}
              mb={2}
              p={2}
              border={1}
              borderColor="grey.300"
              borderRadius={2}
            >
              <Typography variant="h6">
                {assignment.shift?.shiftPattern.name}
              </Typography>
              <Typography>Home: {assignment.shift?.homeId.name}</Typography>
              {/* <Typography>
                Address: {JSON.stringify(assignment.shift?.homeId)}
              </Typography> */}
              <Typography>
                Time:{' '}
                {
                  assignment.shift?.shiftPattern?.timings?.find(
                    (timing) =>
                      timing.careHomeId === assignment.shift?.homeId?._id
                  )?.startTime
                }{' '}
                -{' '}
                {
                  assignment.shift?.shiftPattern?.timings?.find(
                    (timing) =>
                      timing.careHomeId === assignment.shift?.homeId?._id
                  )?.endTime
                }
              </Typography>
              <Typography>Status: {assignment.status}</Typography>
              <Box mt={2}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={
                    assignment.timesheet?.status === 'pending' ||
                    assignment.timesheet?.status === 'approved'
                  }
                  onClick={() => handleRequestTimesheet(assignment)}
                  loading={requestTimeSheetState.isLoading}
                >
                  {assignment.timesheet?.status === 'pending'
                    ? 'Timesheet Pending'
                    : assignment.timesheet?.status === 'approved'
                    ? 'Timesheet Approved'
                    : 'Request Timesheet'}
                </LoadingButton>
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewShiftOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </CalendarContainer>
  );
};

export default CareStaffShiftCalendar;
