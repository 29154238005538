import { useRoutes, useSearchParams } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { CustomSnackBar } from './components/core/snack/snack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './redux/hook';
import type { RootState } from './redux/store';
import { apiHostname } from './api/api';
import { Capacitor } from '@capacitor/core';
import { showSnack } from './redux/reducers/snack/snack-slice';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './config/firebase';
import { PushNotifications } from '@capacitor/push-notifications';
import {
  useLazyGetApplicationQuery,
  useUpdateFcmTokenMutation
} from './services/api';
import { _setFcmToken } from './redux/reducers/users';
import SubscriptionDialog from './components/core/dialogs/payment-reminder';
import { closePaymentDialog } from './redux/reducers/dialogs/payment';
import { saveApplication } from './redux/reducers/carer-application';

import router from './newrouter';

function App() {
  const content = useRoutes(router);

  const [loading, setLoading] = useState(true);
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  const dispatch = useDispatch();

  // redux
  const userState = useAppSelector((state: RootState) => state.user.user);
  const user = useAppSelector((state: RootState) => state.userState.user);

  const application = useAppSelector(
    (state: RootState) => state.carerApplication.application
  );

  // rtk

  const [uploadFCMToken] = useUpdateFcmTokenMutation();
  const [getApplication, getApplicationState] = useLazyGetApplicationQuery();

  const [searchParams] = useSearchParams();

  if (searchParams.has('token')) {
    localStorage.setItem('invToken', searchParams.get('token') || '');
    localStorage.setItem('invCompany', searchParams.get('company') || '');
  }

  async function fetchCarerProfile() {
    try {
      const response = await getApplication(undefined).unwrap();
      dispatch(
        saveApplication({
          ...application,
          ...response.data
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (userState._id) {
      initializeNotifications();
      if (userState.accountType === 'carer') {
        fetchCarerProfile();
      }
    }
  }, [userState]);

  const initializeNotifications = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await initializeNativeNotifications();
      } else {
        await initializeWebNotifications();
      }
    } catch (error) {
      console.error('Error initializing notifications:', error);
    }
  };

  const initializeWebNotifications = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log(
        'Notification permission granted',
        process.env.REACT_APP_FB_VAPID_KEY
      );
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FB_VAPID_KEY
      });
      setFcmToken(token);
      await handleUploadFCMToken(token);
      onMessage(messaging, handleIncomingMessage);
    } else {
      throw new Error('Notification permission not granted');
    }
  };

  const initializeNativeNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('Push notification permissions not granted');
    }
    await PushNotifications.register();
    await addNativeNotificationListeners();
  };

  const addNativeNotificationListeners = async () => {
    await PushNotifications.addListener('registration', ({ value }) => {
      console.log('Push registration success, token: ', value);
      setFcmToken(value);
      handleUploadFCMToken(value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      handleIncomingMessage
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
      }
    );
  };

  const handleIncomingMessage = (payload) => {
    console.log('Incoming message:', payload);
    // const notiObj = JSON.parse(payload);
    // console.log('Notification object:', notiObj);

    dispatch(
      showSnack({
        message: payload.notification.body,
        color: 'info'
      })
    );
  };

  const handleUploadFCMToken = async (token) => {
    try {
      console.log('Uploading FCM Token:', token);
      const response = await uploadFCMToken(token).unwrap();
      dispatch(_setFcmToken(token));
    } catch (error) {
      console.error('Error uploading FCM token:', error);
      // dispatch(_clearFcmToken());
      dispatch(
        showSnack({
          message:
            'Failed to register for notifications. Please try again later.',
          color: 'warning'
        })
      );
    }
  };

  const [openSubscriptionReminder, setopenSubscriptionReminder] =
    useState(false);

  const paymentDialogState = useAppSelector(
    (state: RootState) => state.paymentDialog
  );

  // const [getSubStatus, getSubState] = useLazyGetSubscriptionStatusQuery();

  const bottomBoxes: {
    label: string;
    icon: string;
    link: string;
  }[] = [];

  const handleOpenDialog = () => {
    setopenSubscriptionReminder(true);
  };

  const handleCloseDialog = () => {
    dispatch(closePaymentDialog());
    setopenSubscriptionReminder(false);
  };

  const checkForPaymentStatus = async () => {
    try {
      // const response = await getSubStatus(undefined).unwrap();
      // if (response.status === 'inactive') {
      //   handleOpenDialog();
      // } else {
      //   handleCloseDialog();
      // }
    } catch (error) {
      handleCloseDialog();
      console.log(error, 'poor');
    }
  };

  useEffect(() => {
    if (
      userState.isEmailVerified &&
      ['home', 'agency'].includes(userState.accountType)
    ) {
      checkForPaymentStatus();
    }
  }, [userState.isEmailVerified]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
      <CustomSnackBar />

      <SubscriptionDialog
        open={openSubscriptionReminder || paymentDialogState.isOpen}
        onClose={handleCloseDialog}
        message={paymentDialogState.message}
      />
    </ThemeProvider>
  );
}
export default App;
