import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Shift } from './types';
import { useAppSelector } from 'src/redux/hook';
import AssignStaffDialog from './assign-staffs';
import ViewAssignedStaffDialog from './assigned-staffs';
import {
  useAssignUsersToShiftMutation,
  useGetAssignedStaffsQuery,
  useAcceptShiftByAgencyMutation
} from 'src/services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import ShiftAccordion from './view-shift-accordion';

interface ViewShiftDialogProps {
  open: boolean;
  onClose: () => void;
  selectedDate: moment.Moment | null;
  shifts: Shift[];
  onShiftsUpdate: (updatedShifts: Shift[]) => void;
  onEditClick: (shift: Shift) => void;
  onDeleteClick: (shift: Shift) => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}));

const ViewShiftDialog: React.FC<ViewShiftDialogProps> = ({
  open,
  onClose,
  selectedDate,
  shifts,
  onShiftsUpdate,
  onEditClick,
  onDeleteClick
}) => {
  const dispatch = useDispatch();
  const [assignStaffDialogOpen, setAssignStaffDialogOpen] = useState(false);
  const [viewAssignedStaffDialogOpen, setViewAssignedStaffDialogOpen] =
    useState(false);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [assignUsersToShift] = useAssignUsersToShiftMutation();
  const [acceptShiftByAgency] = useAcceptShiftByAgencyMutation();

  const [assignedStaffs, setAssignedStaffs] = useState<any[] | null>(null);

  const userState = useAppSelector((state) => state.userState);

  const handleOpenAssignStaff = (shift: Shift) => {
    setSelectedShift(shift);
    setAssignStaffDialogOpen(true);
  };

  const handleCloseAssignStaff = () => {
    setAssignStaffDialogOpen(false);
    setSelectedShift(null);
  };

  const handleOpenViewAssignedStaff = (shift: Shift) => {
    setSelectedShift(shift);
    setViewAssignedStaffDialogOpen(true);
  };

  const handleCloseViewAssignedStaff = () => {
    setViewAssignedStaffDialogOpen(false);
    setSelectedShift(null);
  };

  const handleAssignStaff = async (staffIds: string[]) => {
    if (selectedShift) {
      try {
        await assignUsersToShift({
          shiftId: selectedShift._id,
          userIds: staffIds
        }).unwrap();
        setSelectedShift(null);
        onClose();
      } catch (error) {
        console.error('Failed to assign staff:', error);
        dispatch(
          showSnack({
            message: 'Failed to assign staff. Please try again.',
            color: 'danger'
          })
        );
      }
    }
  };

  const handleAcceptShift = async (shift: Shift) => {
    try {
      const updatedShift = await acceptShiftByAgency(shift._id).unwrap();
      onShiftsUpdate(
        shifts.map((s) => (s._id === updatedShift._id ? updatedShift : s))
      );
      dispatch(
        showSnack({ message: 'Shift accepted successfully', color: 'success' })
      );
    } catch (error) {
      console.error('Failed to accept shift:', error);
      dispatch(
        showSnack({
          message: error?.message || 'Error accepting shift. Try again later',
          color: 'danger'
        })
      );
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Shifts for {selectedDate?.format('MMMM D, YYYY')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Total Shifts: {shifts.length}
        </Typography>
        <Grid container spacing={2}>
          {shifts.map((shift) => (
            <Grid item xs={12} sm={6} md={4} key={shift._id}>
              <ShiftAccordion
                shift={shift}
                userState={userState}
                onAssignStaff={handleOpenAssignStaff}
                onViewAssignedStaff={handleOpenViewAssignedStaff}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                setAssignedStaffs={setAssignedStaffs}
                onShiftUpdate={(updatedShift) =>
                  onShiftsUpdate(
                    shifts.map((s) =>
                      s._id === updatedShift._id ? updatedShift : s
                    )
                  )
                }
                onAccept={handleAcceptShift}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      {selectedShift && (
        <>
          <AssignStaffDialog
            open={assignStaffDialogOpen}
            onClose={handleCloseAssignStaff}
            shift={selectedShift}
            onAssign={handleAssignStaff}
          />
        </>
      )}
      {selectedShift && assignedStaffs?.length > 0 && (
        <ViewAssignedStaffDialog
          open={viewAssignedStaffDialogOpen}
          onClose={handleCloseViewAssignedStaff}
          assignedStaffs={assignedStaffs || []}
          shift={selectedShift}
        />
      )}
    </StyledDialog>
  );
};

export default ViewShiftDialog;
