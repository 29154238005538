import React, { useState } from 'react';
import {
  Typography,
  Paper,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { styled } from '@mui/system';
import {
  CreditCard,
  History,
  CheckCircle,
  AttachMoney
} from '@mui/icons-material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  useUpdatePaymentMethodMutation,
  useGetPaymentHistoryQuery,
  useCancelSubscriptionMutation
} from 'src/services/api';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6]
  }
}));

const StyledCardElement = styled(CardElement)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    borderColor: theme.palette.primary.main
  }
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 'medium',
  padding: theme.spacing(0.5, 1)
}));

const SubscriptionDetails = ({ plan, subscriptionDetails, organizationId }) => {
  const [isUpdateCardDialogOpen, setIsUpdateCardDialogOpen] = useState(false);
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();
  const { data: paymentHistory, isLoading: isPaymentHistoryLoading } =
    useGetPaymentHistoryQuery(organizationId);
  const [cancelSubscription, { isLoading: isCancelingSubscription }] =
    useCancelSubscriptionMutation();

  const dispatch = useDispatch();

  const handleUpdateCard = () => {
    setIsUpdateCardDialogOpen(true);
  };

  async function handleCancelSubscription() {
    try {
      const response = await cancelSubscription(organizationId);

      dispatch(
        showSnack({
          message: 'Subscription canceled',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to cancel subscription',
          color: 'error'
        })
      );
    }
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Box display="flex" alignItems="center" mb={2}>
              <AttachMoney color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">Plan Details</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              <strong>Plan:</strong> {plan.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Price:</strong> ${plan.price} / {plan.interval}
            </Typography>
            <Box mt={2}>
              <StatusChip
                label={subscriptionDetails.status.toUpperCase()}
                color="success"
                icon={<CheckCircle />}
              />
            </Box>
            <Typography variant="body1" mt={2}>
              <strong>Next billing date:</strong>{' '}
              {new Date(
                subscriptionDetails.currentPeriodEnd
              ).toLocaleDateString()}
            </Typography>
            <LoadingButton
              variant="contained"
              color="error"
              fullWidth
              loading={false}
              loadingPosition="start"
              startIcon={<CreditCard />}
              onClick={() => {
                handleCancelSubscription();
              }}
            >
              Cancel Subscription
            </LoadingButton>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Box display="flex" alignItems="center" mb={2}>
              <CreditCard color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">Payment Method</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              <strong>Card ending in:</strong>{' '}
              {subscriptionDetails.defaultPaymentMethod?.card?.last4}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Expires:</strong>{' '}
              {subscriptionDetails.defaultPaymentMethod?.card?.exp_month}/
              {subscriptionDetails.defaultPaymentMethod?.card?.exp_year}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={handleUpdateCard}
              sx={{ mt: 2 }}
            >
              Update Payment Method
            </Button>
          </StyledPaper>
        </Grid>
      </Grid>

      <StyledPaper elevation={3}>
        <Box display="flex" alignItems="center" mb={2}>
          <History color="primary" sx={{ mr: 1 }} />
          <Typography variant="h6">Payment History</Typography>
        </Box>
        {isPaymentHistoryLoading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Date</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Amount</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentHistory?.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>
                      {new Date(payment.created * 1000).toLocaleDateString()}
                    </TableCell>
                    <TableCell>${(payment.amount / 100).toFixed(2)}</TableCell>
                    <TableCell>
                      <Chip
                        label={payment.status.toUpperCase()}
                        color={
                          payment.status === 'succeeded' ? 'success' : 'error'
                        }
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledPaper>

      <UpdateCardDialog
        open={isUpdateCardDialogOpen}
        onClose={() => setIsUpdateCardDialogOpen(false)}
        organizationId={organizationId}
        updatePaymentMethod={updatePaymentMethod}
      />
    </Box>
  );
};

const UpdateCardDialog = ({
  open,
  onClose,
  organizationId,
  updatePaymentMethod
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if (error) {
        setError(error.message);
        setIsProcessing(false);
        return;
      }

      const response = await updatePaymentMethod({
        organizationId,
        paymentMethodId: paymentMethod.id
      });

      if ('error' in response) {
        setError('Failed to update payment method');
      } else {
        onClose();
        // You might want to show a success message or update the UI here
      }
    } catch (err) {
      setError('An unexpected error occurred');
    }

    setIsProcessing(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <CreditCard color="primary" sx={{ mr: 1 }} />
          Update Payment Method
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <StyledCardElement />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isProcessing}
        >
          {isProcessing ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionDetails;
