import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  IconButton,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { RootState } from 'src/redux/store';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useLazyGetResidentsQuery,
  useLazyGetResidentTaskHistoryQuery,
  useUpdateResidentMutation
} from 'src/services/api';
import SubscriptionDialog from 'src/components/core/dialogs/payment-reminder';
import { apiHostname, wsHostname } from 'src/api/api';
import axios from 'axios';
import dayjs from 'dayjs';
import { ResidentTaskHistoryResponse } from 'src/interfaces/resident';
import ResidentTaskHistoryDialog from 'src/components/core/dialogs/resident/task-history';
import { HistoryIcon } from 'lucide-react';
import { useAppSelector } from 'src/redux/hook';

interface IResident {
  _id?: string;
  homeId: string;
  firstName: string;
  lastName: string;
  roomNumber: string;
  profilePictureUrl: string;
  type: 'Permanent' | 'Temporary' | 'Respite';
  medications: IMedication[];
  personalCare: IPersonalCare;
  groupId?: any;
  currentStatus: number;
  dueTasks?: IDueTask[];
}

interface IMedication {
  type: string;
  frequency: {
    times: number;
    per: 'day' | 'week';
  };
  label: string;
  medicineName: string;
  timings: string[];
  status: {
    isDue: boolean;
    lastDueTime?: Date;
    lastResolvedTime?: Date;
    lastResolvedDescription?: string;
  };
}

interface IPersonalCare {
  [key: string]: IPersonalCareItem | IMealCareItem;
}

interface IPersonalCareItem {
  frequency: {
    times: number;
    per: 'day' | 'week';
  };
  timings?: string[];
  statuses: {
    isDue: boolean;
    lastDueTime?: Date;
    lastResolvedTime?: Date;
    lastResolvedDescription?: string;
    additionalData?: any;
  }[];
}

interface IMealCareItem extends IPersonalCareItem {
  defaultTime: string;
}

interface IDueTask {
  type: 'personalCare' | 'medication';
  key: string;
  minutesPastDue: number;
  taskTime?: string;
}

const removeIdFields = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeIdFields);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (key !== '_id' && key !== 'id') {
        acc[key] = removeIdFields(value);
      }
      return acc;
    }, {} as any);
  }
  return obj;
};

const HomeResidentPage: React.FC = () => {
  const [residents, setResidents] = useState<IResident[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );
  const { currentOrganization } = useAppSelector((state) => state.userState);
  const homeId = currentOrganization?._id;
  const dispatch = useDispatch();

  const [openSubscriptionReminder, setOpenSubscriptionReminder] =
    useState(false);

  const [getResidents, getResidentsState] = useLazyGetResidentsQuery();
  const [updateResident] = useUpdateResidentMutation();

  const [uploadingResidentId, setUploadingResidentId] = useState<string | null>(
    null
  );
  const userState = useSelector((state: RootState) => state.user.user);
  const [isUploading, setIsUploading] = useState(false);

  const [taskHistoryDialogOpen, setTaskHistoryDialogOpen] = useState(false);
  const [selectedResidentForHistory, setSelectedResidentForHistory] =
    useState<IResident | null>(null);

  const [getResidentTaskHistory] = useLazyGetResidentTaskHistoryQuery();

  const handleOpenTaskHistoryDialog = (resident: IResident) => {
    setSelectedResidentForHistory(resident);
    setTaskHistoryDialogOpen(true);
  };

  const handleCloseTaskHistoryDialog = () => {
    setTaskHistoryDialogOpen(false);
    setSelectedResidentForHistory(null);
  };

  const fetchTaskHistory = async (
    residentId: string,
    startDate: Date,
    endDate: Date
  ) => {
    try {
      const response = await getResidentTaskHistory({
        residentId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }).unwrap();
      return response.data;
    } catch (error) {
      console.error('Error fetching task history:', error);
      // Handle error (e.g., show a notification to the user)
      return { resident: null, taskHistory: [] };
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const socket = new WebSocket(`${wsHostname}?homeId=${userState._id}`);
    socket.onopen = () => {
      console.log('Socket connected');
    };
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Message:', message);
      if (message.event === `task-resolved-${userState._id}`) {
        const resident = message.data;
        const cleaned = removeIdFields(resident);
        const { currentStatus, dueTasks, taskTime } =
          calculateCurrentStatus(cleaned);
        const final = {
          ...cleaned,
          currentStatus: currentStatus,
          dueTasks: dueTasks,
          loading: false
        };

        setResidents(
          residents.map((r) =>
            r._id === resident._id ? { _id: resident._id, ...final } : r
          )
        );

        // setResidents(
        //   residents.map((r) =>
        //     r._id === resident._id ? { ...r, ...resident } : r
        //   )
        // );
      }
    };

    return () => {
      socket.close();
    };
  }, [residents]);

  useEffect(() => {
    fetchResidents();
  }, []);

  const fetchResidents = async () => {
    try {
      const response = await getResidents(homeId).unwrap();
      const updatedResidents = response.data.map((resident: IResident) => ({
        ...resident,
        ...calculateCurrentStatus(resident)
      }));
      setResidents(updatedResidents);
    } catch (error) {
      console.error('Error fetching residents:', error);
      if (error.status === 402) {
        setOpenSubscriptionReminder(true);
      }
    }
  };

  const calculateCurrentStatus = (
    resident: IResident
  ): { currentStatus: number; dueTasks: IDueTask[]; taskTime?: string } => {
    const now = new Date();
    const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
    const currentTime = now.toTimeString().slice(0, 5); // HH:MM format

    let dueTasksCount = 0;
    let totalTasksCount = 0;
    let dueTasks: IDueTask[] = [];

    // Check personal care tasks
    if (resident.personalCare) {
      Object.entries(resident.personalCare).forEach(([key, task]) => {
        totalTasksCount++;
        const taskStatus = isTaskDue(task, currentDay, currentTime);
        if (taskStatus.isDue) {
          dueTasksCount++;
          dueTasks.push({
            type: 'personalCare',
            key: key,
            minutesPastDue: taskStatus.minutesPastDue,
            taskTime: taskStatus.taskTime
          });
        }
      });
    }

    // Check medications
    if (Array.isArray(resident.medications)) {
      resident.medications.forEach((med) => {
        totalTasksCount++;
        const taskStatus = isTaskDue(med, currentDay, currentTime);
        if (taskStatus.isDue) {
          dueTasksCount++;
          dueTasks.push({
            type: 'medication',
            key: med.medicineName,
            minutesPastDue: taskStatus.minutesPastDue,
            taskTime: taskStatus.taskTime
          });
        }
      });
    }

    const status =
      totalTasksCount > 0 ? (dueTasksCount / totalTasksCount) * 100 : 0;

    return { currentStatus: status, dueTasks };
  };

  const isTaskDue = (
    task: any,
    currentDay: string,
    currentTime: string
  ): { isDue: boolean; minutesPastDue: number; taskTime: string } => {
    if (!task.frequency) {
      return { isDue: false, minutesPastDue: 0, taskTime: '' };
    }

    const now = dayjs();
    const currentDateTime = now
      .hour(parseInt(currentTime.split(':')[0]))
      .minute(parseInt(currentTime.split(':')[1]));

    const checkTiming = (
      timing: string,
      status: any
    ): { isDue: boolean; minutesPastDue: number; taskTime: string } => {
      if (!timing || timing.trim() === '') {
        return { isDue: false, minutesPastDue: 0, taskTime: '' };
      }

      const [taskHour, taskMinute] = timing.split(':').map(Number);
      const taskDateTime = now.hour(taskHour).minute(taskMinute);
      const tenMinutesBefore = taskDateTime.subtract(10, 'minute');

      if (currentDateTime.isBefore(tenMinutesBefore)) {
        return { isDue: false, minutesPastDue: 0, taskTime: timing };
      }

      if (
        !status ||
        !status.lastResolvedTime ||
        status.lastResolvedTime === ''
      ) {
        const minutesPastDue = Math.max(
          0,
          currentDateTime.diff(tenMinutesBefore, 'minute')
        );
        return { isDue: true, minutesPastDue, taskTime: timing };
      }

      const lastResolvedDateTime = dayjs(status.lastResolvedTime);
      if (lastResolvedDateTime.isBefore(tenMinutesBefore)) {
        const minutesPastDue = Math.max(
          0,
          currentDateTime.diff(tenMinutesBefore, 'minute')
        );
        return { isDue: true, minutesPastDue, taskTime: timing };
      }

      return { isDue: false, minutesPastDue: 0, taskTime: timing };
    };

    if (task.frequency.per === 'day') {
      if (Array.isArray(task.timings) && Array.isArray(task.statuses)) {
        for (let i = 0; i < task.timings.length; i++) {
          const result = checkTiming(task.timings[i], task.statuses[i]);
          if (result.isDue) {
            return result;
          }
        }
        return checkTiming(
          task.timings[task.timings.length - 1],
          task.statuses[task.statuses.length - 1]
        );
      } else if (task.defaultTime) {
        return checkTiming(task.defaultTime, task.status);
      }
    } else if (task.frequency.per === 'week') {
      if (Array.isArray(task.timings) && Array.isArray(task.statuses)) {
        const todayTiming = task.timings.find((t: any) => t.day === currentDay);
        const todayIndex = task.timings.findIndex(
          (t: any) => t.day === currentDay
        );
        if (todayTiming && todayIndex !== -1) {
          return checkTiming(todayTiming.time, task.statuses[todayIndex]);
        }
      }
    }

    return { isDue: false, minutesPastDue: 0, taskTime: '' };
  };

  const handleAddResident = (resident: IResident) => {
    setResidents([...residents, resident]);
  };

  const handleOpenDialog = (resident?: IResident) => {
    if (resident) {
      setSelectedResident(
        { ...resident, groupId: resident.groupId?._id } || null
      );
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedResident(null);
  };

  const handleUpdateResident = async (updatedResident: IResident) => {
    try {
      const response = await updateResident({
        id: updatedResident._id,
        data: updatedResident
      }).unwrap();
      setResidents(
        residents.map((r) =>
          r._id === updatedResident._id ? response.data : r
        )
      );
      dispatch(
        showSnack({
          message: 'Resident updated successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Error updating resident:', error);
      dispatch(
        showSnack({
          message: 'An error occurred while updating the resident',
          color: 'danger'
        })
      );
    }
  };

  const handleProfilePictureClick = (residentId: string) => {
    setUploadingResidentId(residentId);
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsUploading(true);
    const file = event.target.files?.[0];
    if (file && uploadingResidentId) {
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `resident_profile_${uploadingResidentId}_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, {
        type: file.type
      });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/resident/${uploadingResidentId}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userState.token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          const updatedResident = residents.find(
            (r) => r._id === uploadingResidentId
          );
          if (updatedResident) {
            await handleUpdateResident({
              ...updatedResident,
              profilePictureUrl: response.data.data.avatarUrl
            });
          }
          dispatch(
            showSnack({ message: 'Profile picture updated', color: 'success' })
          );
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        setIsUploading(false);
        console.error('Error uploading profile picture:', error);
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
        setUploadingResidentId(null);
      }
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {residents.map((resident) => (
          <Grid item key={resident._id} xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                borderRadius: 2,
                p: 2,
                backgroundColor: 'white',
                position: 'relative'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ position: 'relative', mr: 2 }}>
                  <Avatar
                    alt={`${resident.firstName} ${resident.lastName}`}
                    src={`${resident.profilePictureUrl}`}
                    sx={{ width: 56, height: 56 }}
                  />
                  {isUploading && uploadingResidentId === resident._id ? (
                    <CircularProgress
                      size={56}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                      }}
                    />
                  ) : (
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: -8,
                        right: -8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' }
                      }}
                      onClick={() => handleProfilePictureClick(resident._id!)}
                    >
                      <CameraAltIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <Typography variant="h6" component="div">
                    {resident.firstName} {resident.lastName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Type: {resident.type}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Room: {resident.roomNumber}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Group: {resident.groupId?.name || 'Not assigned'}
                  </Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                  <IconButton
                    onClick={() => {
                      handleOpenTaskHistoryDialog(resident);
                    }}
                    size="small"
                  >
                    <HistoryIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDialog(resident)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ width: '100%', mt: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={resident.currentStatus}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5,
                      backgroundColor:
                        resident.currentStatus > 66
                          ? 'error.main'
                          : resident.currentStatus > 33
                          ? 'warning.main'
                          : 'success.main'
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => handleOpenDialog()}
      >
        <AddIcon />
      </Fab>
      <AddResidentDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        homeId={homeId}
        existingResident={selectedResident || undefined}
      />
      <SubscriptionDialog
        open={openSubscriptionReminder}
        onClose={() => setOpenSubscriptionReminder(false)}
        message="Please subscribe to add more residents"
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />

      {selectedResidentForHistory && (
        <ResidentTaskHistoryDialog
          open={taskHistoryDialogOpen}
          onClose={handleCloseTaskHistoryDialog}
          fetchTaskHistory={fetchTaskHistory}
          resident={selectedResidentForHistory}
        />
      )}
    </Container>
  );
};

export default HomeResidentPage;
