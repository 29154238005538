import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/hook';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import Snackbar from '@mui/joy/Snackbar';
import Button from '@mui/joy/Button';
import { hideSnack } from 'src/redux/reducers/snack/snack-slice';
import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { keyframes } from '@emotion/react';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const theme = extendTheme({
  components: {
    JoySnackbar: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          borderRadius: '12px',
          animation: `${slideIn} 0.3s ease-out`
        }
      }
    }
  }
});

export function CustomSnackBar() {
  const snackState = useAppSelector((state) => state.snack);
  const dispatch = useDispatch();

  const getIcon = () => {
    switch (snackState.color) {
      case 'success':
        return <CheckCircle />;
      case 'error':
        return <Error />;
      case 'warning':
        return <Warning />;
      default:
        return <Info />;
    }
  };

  return (
    <CssVarsProvider theme={theme}>
      <div
        style={{
          position: 'fixed',
          top: 'calc(env(safe-area-inset-top) + 20px)',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1400,
          width: '90%',
          maxWidth: '400px'
        }}
      >
        <Snackbar
          variant="soft"
          color={snackState.color}
          open={snackState.open}
          onClose={() => dispatch(hideSnack())}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          startDecorator={getIcon()}
          endDecorator={
            <Button
              onClick={() => dispatch(hideSnack())}
              size="sm"
              variant="soft"
              color={snackState.color}
              sx={{
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'action.hover'
                }
              }}
            >
              Dismiss
            </Button>
          }
          sx={{
            alignItems: 'center',
            gap: 1,
            fontWeight: 'medium',
            fontSize: 'sm',
            '--Snackbar-padding': '0.75rem',
            '--Snackbar-radius': '12px',
            boxShadow: 'md'
          }}
        >
          {snackState.message}
        </Snackbar>
      </div>
    </CssVarsProvider>
  );
}
