import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { closeInvDialog } from 'src/redux/reducers/invitations';
import { useLinkUserMutation } from 'src/services/api';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle
} from '../ui/dialog-styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogContent: {
    padding: theme.spacing(3)
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end'
  },
  acceptButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const InvitationDialog = ({ open, onClose }) => {
  const dialogState = useAppSelector((state) => state.invDialog);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [company, setCompany] = useState('');
  const [token, setToken] = useState('');

  const [linkUser, _] = useLinkUserMutation();

  const handleAccept = async () => {
    try {
      const response = await linkUser({
        userId: dialogState.userId,
        userType: dialogState.userType
      }).unwrap();
      dispatch(
        showSnack({
          message:
            'Invitation has been accepted. Go to agencies tab to see you agencies',
          color: 'success'
        })
      );
      dispatch(closeInvDialog());
      onClose();
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const handleReject = () => {
    dispatch(closeInvDialog());
    onClose();
  };

  return (
    <StyledDialog
      open={dialogState.open}
      onClose={handleReject}
      maxWidth="sm"
      fullWidth
    >
      <StyledDialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">Invitation</Typography>
        <Button onClick={handleReject}>Close</Button>
      </StyledDialogTitle>
      <StyledDialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          You have been invited by {dialogState.company} to join their
          organization.
        </Typography>
      </StyledDialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          className={classes.rejectButton}
          onClick={handleReject}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          className={classes.acceptButton}
          onClick={handleAccept}
          sx={{
            color: 'white'
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default InvitationDialog;
