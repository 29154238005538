import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  useTheme,
  Grid,
  Typography,
  useMediaQuery,
  Slide,
  Button,
  Fab
} from '@mui/material';
import { styled } from '@mui/system';
import { useAppSelector } from 'src/redux/hook';
import { useNavigate } from 'react-router';
import {
  useGetOrganizationListingQuery,
  useGetQuickStatsQuery,
  useLazyGetInvitationQuery,
  useLazyGetOrganizationRoleQuery
} from 'src/services/api';
import { useDispatch } from 'react-redux';
import { closeInvDialog, openInvDialog } from 'src/redux/reducers/invitations';
import { apiHostname } from 'src/api/api';
import { QuickStatsGrid } from 'src/components/core/ui/widget';
import Calendar from 'src/components/core/ui/calendar';
import InvitationDialog from 'src/components/core/dialogs/invitations';
import ChatPopup from 'src/components/core/dialogs/chat-popup';
import { Permission, type IOrganizationRole } from 'src/interfaces/heirarchy';
import { Chat } from '@mui/icons-material';
import { AppCard } from 'src/components/core/ui/styled';
import ModernShiftCalendar from 'src/components/core/ui/shift-calendar';
import CareStaffShiftCalendar from 'src/components/core/ui/care-staff-calendar';

export default function Home() {
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );
  const shiftState = useAppSelector((state) => state.shifts);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentRole, setCurrentRole] = useState<IOrganizationRole | null>(
    null
  );
  const [chatOpen, setChatOpen] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const [openInvitation, setOpenInvitation] = useState(false);

  const { data: quickStatsData, isLoading: quickStatsLoading } =
    useGetQuickStatsQuery(undefined, { skip: !user?._id });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkInvitation] = useLazyGetInvitationQuery();
  const [loading, setLoading] = useState(true);
  const [getOrgRole] = useLazyGetOrganizationRoleQuery();
  const {
    data: organizations,
    isLoading,
    isError,
    error
  } = useGetOrganizationListingQuery(undefined);

  useEffect(() => {
    async function loadOrganizationRole() {
      console.log('initializing');
      if (user && currentOrganization) {
        try {
          const role = await getOrgRole(currentOrganization?._id).unwrap();
          console.log(role, 'andi');
          setCurrentRole(role);
        } catch (error) {
          console.error('Failed to fetch organization role:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    loadOrganizationRole();
  }, [user, currentOrganization, dispatch]);

  const hasPermission = (permission: Permission) => {
    return currentRole?.permissions.includes(permission);
  };

  let calendar = <ModernShiftCalendar />;
  let quickStats = [
    {
      label: 'Total Shifts',
      value: quickStatsData?.totalShifts || 0,
      iconColor: 'white',
      bgColor: '#1976d2'
    },
    {
      label: 'Completed Shifts',
      value: quickStatsData?.completedShifts || 0,
      iconColor: 'white',
      bgColor: '#4caf50'
    },
    {
      label: 'Pending Shifts',
      value: quickStatsData?.pendingShifts || 0,
      iconColor: 'white',
      bgColor: '#ff9800'
    },
    {
      label: 'Previous Month',
      value: quickStatsData?.previousMonthShifts || 0,
      iconColor: 'white',
      bgColor: '#9c27b0'
    }
  ];

  let bottomBoxes = [
    {
      label: 'Settings',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/settings`,
      permission: Permission.EDIT_ORGANIZATION
    },
    {
      label: 'Timesheets',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/timesheets`,
      permission: Permission.VIEW_TIMESHEETS
    },
    {
      label: 'Documents',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/documents`,
      permission: Permission.VIEW_DOCUMENTS
    }
  ];

  if (currentOrganization?.type === 'agency') {
    if (user.role === 'carer') {
      calendar = <CareStaffShiftCalendar />;
    }
    bottomBoxes.push({
      label: 'Homes',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/home-users`,
      permission: Permission.VIEW_HOMES
    });
  } else if (currentOrganization?.type === 'home') {
    if (user.role === 'carer') {
      calendar = <CareStaffShiftCalendar />;
    }
    bottomBoxes.push(
      {
        label: 'Residents',
        icon: 'iconamoon:profile-duotone',
        link: `/app/dashboards/residents`,
        permission: Permission.VIEW_RESIDENTS
      },
      {
        label: 'Agencies',
        icon: 'iconamoon:profile-duotone',
        link: `/app/dashboards/agency-users`,
        permission: Permission.VIEW_AGENCIES
      }
    );
  }

  useEffect(() => {
    setSlideIn(true);
    if (user._id) {
      const token = localStorage.getItem('invToken');
      checkForInvitations(token || 'null');
    }
  }, [user._id]);

  async function checkForInvitations(token) {
    try {
      if (token) {
        const response = await fetch(
          `${apiHostname}/api/v1/invitations/token/${token}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        if (response.ok) {
          const jsondata = await response.json();
          dispatch(
            openInvDialog({
              company: jsondata.data.company?.name || 'Unknown Company',
              token: token,
              userId: jsondata.data.senderId,
              userType: jsondata.data.senderAccountType
            })
          );
        } else {
          localStorage.removeItem('invToken');
          dispatch(closeInvDialog());
        }
      }
    } catch (error) {
      console.log(error, 'error in checkForInvitations');
      localStorage.removeItem('invToken');
      dispatch(closeInvDialog());
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${theme.header.height} - 10px)`,
        overflow: 'auto',
        bgcolor: 'inherit',
        p: theme.spacing(2),
        pb: theme.spacing(3),
        flexGrow: 1
      }}
    >
      <Slide direction="down" in={slideIn} mountOnEnter unmountOnExit>
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
          Welcome, {user.firstName}!
        </Typography>
      </Slide>

      <Grid container>
        <QuickStatsGrid quickStats={quickStats} />
      </Grid>

      {isMobile ? (
        <Box sx={{ mt: 3 }}>{calendar}</Box>
      ) : (
        <Grid container sx={{ mt: 3 }} gap={10}>
          <Grid item xs={12} md={12}>
            {calendar}
          </Grid>
        </Grid>
      )}

      {isMobile && (
        <Fragment>
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              Recent Activity
            </Typography>
            <AppCard sx={{ p: 2 }}>
              <Typography>No recent activity</Typography>
            </AppCard>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              Upcoming Events
            </Typography>
            <AppCard sx={{ p: 2 }}>
              <Typography>No upcoming events</Typography>
            </AppCard>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              Quick Actions
            </Typography>
            <Grid container spacing={2}>
              {bottomBoxes.map(
                (box, index) =>
                  hasPermission(box.permission) && (
                    <Grid item key={index}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(box.link)}
                      >
                        {box.label}
                      </Button>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        </Fragment>
      )}

      <InvitationDialog
        open={openInvitation}
        onClose={() => {
          setOpenInvitation(false);
          localStorage.removeItem('invToken');
        }}
      />

      {hasPermission(Permission.USE_CHAT) && (
        <Fab
          color="primary"
          aria-label="chat"
          onClick={() => setChatOpen((prev) => !prev)}
        >
          <Chat />
        </Fab>
      )}

      <ChatPopup open={chatOpen} onClose={() => setChatOpen(false)} />
    </Box>
  );
}
