import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hook';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[100]} 100%)`
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '1rem',
  color: theme.palette.primary.main,
  fontWeight: 'bold'
}));

const FeatureList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const FeatureItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  margin: theme.spacing(2, 0)
}));

interface SubscriptionDialogProps {
  open: boolean;
  onClose: () => void;
  message?: string;
}

const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  open,
  onClose,
  message
}) => {
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user.user);

  const handleSubscribe = () => {
    navigate(`/app/dashboards/${userState.accountType}-settings`, {
      replace: true,
      state: {
        tab: 'payments'
      }
    }); // Navigate to subscription page
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>Unlock Premium Features</StyledDialogTitle>
      <DialogContent>
        <Typography variant="h4" align="center" gutterBottom>
          {message}
        </Typography>
        {/* <FeatureList>
          {[
            'Advanced Shift Management',
            'Comprehensive Reports',
            'Priority Support'
          ].map((feature, index) => (
            <FeatureItem key={index}>
              <ListItemIcon>
                <StarIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </FeatureItem>
          ))}
        </FeatureList> */}
        <Box textAlign="center">
          <SubscribeButton onClick={handleSubscribe} size="large">
            Subscribe Now
          </SubscribeButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Maybe Later
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SubscriptionDialog;
