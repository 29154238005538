import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import {
  useLazyGetCurrentCareStatusQuery,
  useResolveTaskMutation
} from 'src/services/api';
import PadCheckDialog from './padCheckDialog';
import { response } from 'express';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

interface ResidentCareStatusProps {
  resident: any;
  residentId?: string;
  onClose: () => void;
}

const ResidentCareStatus: React.FC<ResidentCareStatusProps> = ({
  resident,
  residentId,
  onClose
}) => {
  const [padCheckDialogOpen, setPadCheckDialogOpen] = useState(false);

  const [careStatus, setCareStatus] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<{
    type: string;
    key: string;
    item: any;
  } | null>(null);
  const [description, setDescription] = useState('');

  const [getCurrentCareStatus] = useLazyGetCurrentCareStatusQuery();
  const [resolveTask] = useResolveTaskMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCareStatus();
  }, [residentId]);

  const fetchCareStatus = async () => {
    try {
      setLoading(true);
      const response = await getCurrentCareStatus(residentId).unwrap();
      setCareStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch care status', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (taskType: string, taskKey: string, item: any) => {
    setSelectedTask({ type: taskType, key: taskKey, item });
    if (taskKey === 'padCheck') {
      setPadCheckDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  };
  const handleClosePadCheckDialog = () => {
    setPadCheckDialogOpen(false);
    setSelectedTask(null);
  };

  const handleResolvePadCheck = async (
    stoolType: string,
    urineType: string
  ) => {
    if (!selectedTask) return;

    try {
      console.log('Pad Check resolved', resident.personalCare['padCheck']);

      let padTime = resident.dueTasks.find(
        (task: any) => task.key === 'padCheck'
      ).taskTime;

      let index = resident.personalCare['padCheck'].timings.findIndex(
        (time: any) => time === padTime
      );
      await resolveTask({
        residentId: residentId,
        taskType: 'personalCare',
        taskKey: 'padCheck',
        taskIndex: index,
        description: `Stool Type: ${stoolType}, Urine Type: ${urineType}`,
        additionalData: { stoolType, urineType }
      });
      fetchCareStatus();
      handleClosePadCheckDialog();
    } catch (error) {
      console.error('Failed to resolve pad check', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedTask(null);
    setDescription('');
    onClose();
  };

  const handleResolveTask = async () => {
    if (!selectedTask) return;

    try {
      const response = await resolveTask({
        residentId: residentId,
        taskType:
          selectedTask.type === 'Personal Care'
            ? 'personalCare'
            : 'medications',
        taskKey: selectedTask.key,
        description,
        taskIndex: 0
      }).unwrap();
      if (!response.ok) {
        console.log('Failed to resolve task');
      }
      handleCloseDialog();
    } catch (error) {
      console.log(error, 'error');
      dispatch(showSnack({ message: error.data.error, color: 'danger' }));
    }
  };

  const renderCareItems = (items: any, title: string) => (
    <Box mt={2}>
      <Typography variant="h6">{title}</Typography>
      <Grid container spacing={2}>
        {Object.entries(items).map(([key, item]: [string, any]) => (
          <Grid item xs={4} sm={3} md={2} key={key}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: item.isDue ? 'error.light' : 'success.light',
                cursor: 'pointer'
              }}
              onClick={() => handleOpenDialog(title, item.key, item)}
            >
              <Typography variant="subtitle2">{item.key}</Typography>
              <Typography variant="caption">{item.taskTime}</Typography>
              {/* {item.isDue && (
                <Typography variant="caption" color="error">
                  Due
                </Typography>
              )} */}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  if (loading) return <CircularProgress />;

  if (!careStatus) return <Typography>No care status available</Typography>;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Care Status for {resident.firstName} {resident.lastName}
      </Typography>
      {renderCareItems(resident.dueTasks, 'Personal Care')}
      {renderCareItems(careStatus.medications, 'Medications')}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Resolve Task: {selectedTask?.key}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Resolution Description"
            type="text"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleResolveTask} color="primary">
            Resolve
          </Button>
        </DialogActions>
      </Dialog>

      <PadCheckDialog
        open={padCheckDialogOpen}
        onClose={handleClosePadCheckDialog}
        onResolve={handleResolvePadCheck}
      />
    </Box>
  );
};

export default ResidentCareStatus;
