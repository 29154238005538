import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ResidentCareStatus from './ResidentStatus';

interface ResidentManagementDialogProps {
  open: boolean;
  onClose: () => void;
  resident: any;
  onSave: (updatedResident: any) => void;
  homeId: string;
}

const ResidentManagementDialog: React.FC<ResidentManagementDialogProps> = ({
  open,
  onClose,
  resident,
  onSave,
  homeId
}) => {
  if (!resident) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        Manage Resident: {resident.firstName} {resident.lastName}
      </DialogTitle>
      <DialogContent>
        <ResidentCareStatus
          resident={resident}
          residentId={resident._id}
          onClose={onClose}
        />

        {/* {resident.dueTasks && resident.dueTasks.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Due Tasks:
            </Typography>
            <List>
              {resident.dueTasks.map((task, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${
                      task.type === 'personalCare'
                        ? 'Personal Care'
                        : 'Medication'
                    }: ${task.key}`}
                    secondary={`${task.minutesPastDue.toFixed(
                      0
                    )} minutes overdue`}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResidentManagementDialog;
