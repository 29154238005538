import * as React from 'react';
import AuthWrapper from './AuthWrapper';
import { Grid, Stack, Typography, Link } from '@mui/material';
import AuthLogin from './auth-forms/AuthLogin';
import { useNavigate } from 'react-router-dom';

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <AuthWrapper>
      <AuthLogin />
    </AuthWrapper>
  );
};

export default Login;
