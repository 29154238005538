import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Box,
  Avatar,
  useMediaQuery,
  useTheme,
  alpha,
  CircularProgress,
  Button,
  Paper
} from '@mui/material';
import { RootState } from 'src/redux/store';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useInitiateHandOverMutation,
  useLazyGetHandOverDataQuery,
  useLazyGetResidentsForCarerQuery,
  useLazyGetResidentsQuery,
  useUpdateResidentMutation
} from 'src/services/api';
import { apiHostname, wsHostname } from 'src/api/api';
import ResidentManagementDialog from 'src/components/core/dialogs/ResidentManagement';
import dayjs from 'dayjs';

import PullToRefresh from 'react-simple-pull-to-refresh';
import { useAppSelector } from 'src/redux/hook';
import { set } from 'date-fns';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LoadingButton } from '@mui/lab';

interface IResident {
  _id?: string;
  homeId: string;
  firstName: string;
  lastName: string;
  roomNumber: string;
  profilePictureUrl: string;
  type: 'Permanent' | 'Temporary' | 'Respite';
  medications: IMedication[];
  personalCare: IPersonalCare;
  currentStatus: number;
  groupId?: any;
  dueTasks?: IDueTask[];
  loading?: boolean;
}

interface IMedication {
  type: string;
  frequency: {
    times: number;
    per: 'day' | 'week';
  };
  label: string;
  medicineName: string;
  timings: string[];
}

interface IPersonalCare {
  [key: string]: IPersonalCareItem | IMealCareItem;
}

interface IPersonalCareItem {
  frequency: {
    times: number;
    per: 'day' | 'week';
  };
  timings?: string[];
  statuses: {
    isDue: boolean;
    lastDueTime?: Date;
    lastResolvedTime?: Date;
    lastResolvedDescription?: string;
    additionalData?: any;
  }[];
}

interface IMealCareItem extends IPersonalCareItem {
  defaultTime: string;
}

interface IDueTask {
  type: 'personalCare' | 'medication';
  key: string;
  minutesPastDue: number;
  taskTime?: string;
}

const removeIdFields = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeIdFields);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (key !== '_id' && key !== 'id') {
        acc[key] = removeIdFields(value);
      }
      return acc;
    }, {} as any);
  }
  return obj;
};

const CarerResidentPage: React.FC = () => {
  const [residents, setResidents] = useState<IResident[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFetchingResidents, setIsFetchingResidents] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );
  const dispatch = useDispatch();

  const [getResidents, getResidentsState] = useLazyGetResidentsQuery();
  const [updateResident] = useUpdateResidentMutation();
  const [getHandOverData] = useLazyGetHandOverDataQuery();
  const [initiateHandover, { isLoading: isInitiatingHandover }] =
    useInitiateHandOverMutation();

  const [groups, setGroups] = useState<{ _id: string; name: string }[]>([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [lastHandoverTime, setLastHandoverTime] = useState<string | null>(null);

  const userState = useAppSelector((state) => state.user.user);

  useEffect(() => {
    const socket = new WebSocket(`${wsHostname}?homeId=${userState.homeId}`);
    socket.onopen = () => {
      console.log('Socket connected');
    };
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.event === `task-resolved-${userState.homeId}`) {
        const resident = message.data;
        console.log(resident._id, 'pari');
        const cleaned = removeIdFields(resident);
        const { status, dueTasks, taskTime } = calculateCurrentStatus(cleaned);
        const final = {
          ...cleaned,
          currentStatus: status,
          dueTasks: dueTasks,
          loading: false
        };

        setResidents(
          residents.map((r) =>
            r._id === resident._id ? { _id: resident._id, ...final } : r
          )
        );

        // setResidents(
        //   residents.map((r) =>
        //     r._id === resident._id ? { ...r, ...resident } : r
        //   )
        // );
      }
    };

    return () => {
      socket.close();
    };
  }, [residents]);

  // filters

  const filteredAndSortedResidents = useCallback(() => {
    return residents
      .filter((resident) => {
        if (selectedGroup && resident.groupId?._id !== selectedGroup)
          return false;
        if (selectedType && resident.type !== selectedType) return false;
        return true;
      })
      .sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        if (sortOrder === 'asc') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
  }, [residents, selectedGroup, selectedType, sortOrder]);

  useEffect(() => {
    fetchResidents();
    fetchLastHandoverTime();
  }, []);

  //   useEffect(() => {
  //     const interval = setInterval(checkDueTasks, 5000); // Check every minute
  //     return () => clearInterval(interval);
  //   }, [residents]);

  const fetchResidents = async () => {
    try {
      setIsFetchingResidents(true);
      const response = await fetch(
        `${apiHostname}/api/v1/residents/all/filtered`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();

      if (!data.data || !Array.isArray(data.data)) {
        throw new Error('Invalid data received from API');
      }

      const residentsData = data.data.map((resident: any) => ({
        ...resident,
        medications: removeIdFields(resident.medications),
        personalCare: removeIdFields(resident.personalCare),
        loading: true
      }));

      const updatedResidents = residentsData.map((resident) => {
        const { status, dueTasks, taskTime } = calculateCurrentStatus(resident);
        return {
          ...resident,
          currentStatus: status,
          dueTasks: dueTasks,
          loading: false
        };
      });

      console.log('Residents:', updatedResidents);

      setResidents(updatedResidents);
      setIsFetchingResidents(false);
    } catch (error) {
      dispatch(
        showSnack({
          message: 'An error occurred while fetching residents',
          color: 'danger'
        })
      );
      setIsFetchingResidents(false);
    }
  };

  //   const checkDueTasks = useCallback(() => {
  //     if (!Array.isArray(residents) || residents.length === 0) {
  //       return;
  //     }
  //   }, [residents]);

  const calculateCurrentStatus = (
    resident: IResident
  ): { status: number; dueTasks: IDueTask[]; taskTime?: string } => {
    const now = new Date();
    const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
    const currentTime = now.toTimeString().slice(0, 5); // HH:MM format

    let dueTasksCount = 0;
    let totalTasksCount = 0;
    let dueTasks: IDueTask[] = [];

    // Check personal care tasks
    if (resident.personalCare) {
      Object.entries(resident.personalCare).forEach(([key, task]) => {
        totalTasksCount++;
        const taskStatus = isTaskDue(task, currentDay, currentTime);

        if (taskStatus.isDue) {
          dueTasksCount++;
          dueTasks.push({
            type: 'personalCare',
            key: key,
            minutesPastDue: taskStatus.minutesPastDue,
            taskTime: taskStatus?.taskTime
          });
        }
      });
    }

    // Check medications
    if (Array.isArray(resident.medications)) {
      resident.medications.forEach((med) => {
        totalTasksCount++;
        const taskStatus = isTaskDue(med, currentDay, currentTime);

        if (taskStatus.isDue) {
          dueTasksCount++;
          dueTasks.push({
            type: 'medication',
            key: med.medicineName,
            minutesPastDue: taskStatus.minutesPastDue,
            taskTime: taskStatus.taskTime
          });
        }
      });
    }

    const status =
      totalTasksCount > 0 ? (dueTasksCount / totalTasksCount) * 100 : 0;

    return { status, dueTasks };
  };

  const isTaskDue = (
    task: any,
    currentDay: string,
    currentTime: string
  ): { isDue: boolean; minutesPastDue: number; taskTime: string } => {
    if (!task.frequency) {
      return { isDue: false, minutesPastDue: 0, taskTime: '' };
    }

    const now = dayjs();
    const currentDateTime = now
      .hour(parseInt(currentTime.split(':')[0]))
      .minute(parseInt(currentTime.split(':')[1]));

    const checkTiming = (
      timing: string,
      status: any
    ): { isDue: boolean; minutesPastDue: number; taskTime: string } => {
      if (!timing || timing.trim() === '') {
        return { isDue: false, minutesPastDue: 0, taskTime: '' };
      }

      const [taskHour, taskMinute] = timing.split(':').map(Number);
      const taskDateTime = now.hour(taskHour).minute(taskMinute);
      const tenMinutesBefore = taskDateTime.subtract(10, 'minute');

      // Check if current time is within the 10-minute window before the task time

      console.log(
        currentDateTime.format('MM DD HH:mm'),
        taskDateTime.format('MM DD HH:mm'),
        'pari'
      );
      if (currentDateTime.isBefore(tenMinutesBefore)) {
        return { isDue: false, minutesPastDue: 0, taskTime: timing };
      }

      // Check if the status is unresolved
      if (
        !status ||
        !status.lastResolvedTime ||
        status.lastResolvedTime === ''
      ) {
        const minutesPastDue = Math.max(
          0,
          currentDateTime.diff(tenMinutesBefore, 'minute')
        );
        return { isDue: true, minutesPastDue, taskTime: timing };
      }

      // If resolved, check if it's resolved for today
      const lastResolvedDateTime = dayjs(status.lastResolvedTime);
      if (lastResolvedDateTime.isBefore(tenMinutesBefore)) {
        const minutesPastDue = Math.max(
          0,
          currentDateTime.diff(tenMinutesBefore, 'minute')
        );
        return { isDue: true, minutesPastDue, taskTime: timing };
      }

      return { isDue: false, minutesPastDue: 0, taskTime: timing };
    };

    if (task.frequency.per === 'day') {
      if (task.defaultTime) {
        console.log(task.statuses, 'andi');
        // return checkTiming(task.defaultTime, task.statuses[0]);
      }
      if (Array.isArray(task.timings) && Array.isArray(task.statuses)) {
        // Check all timings and return the first due task
        for (let i = 0; i < task.timings.length; i++) {
          const result = checkTiming(task.timings[i], task.statuses[i]);
          if (result.isDue) {
            return result;
          }
        }

        // If we've checked all timings and none are due, return the last timing
        return checkTiming(
          task.timings[task.timings.length - 1],
          task.statuses[task.statuses.length - 1]
        );
      } else if (task.defaultTime) {
        // Handle tasks with default time
        let status = task.statuses;
        if (status) {
          return checkTiming(task.defaultTime, task.statuses[0]);
        } else {
          return checkTiming(task.defaultTime, null);
        }
      }
    } else if (task.frequency.per === 'week') {
      if (Array.isArray(task.timings) && Array.isArray(task.statuses)) {
        // Find the timing for the current day
        const todayTiming = task.timings.find((t: any) => t.day === currentDay);
        const todayIndex = task.timings.findIndex(
          (t: any) => t.day === currentDay
        );
        if (todayTiming && todayIndex !== -1) {
          return checkTiming(todayTiming.time, task.statuses[todayIndex]);
        }
      }
    }

    return { isDue: false, minutesPastDue: 0, taskTime: '' };
  };

  const handleOpenDialog = (resident: IResident) => {
    setSelectedResident(resident);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedResident(null);
  };

  const handleUpdateResident = async (updatedResident: IResident) => {
    try {
      const response = await updateResident({
        id: updatedResident._id,
        data: updatedResident
      }).unwrap();

      if (!response.ok) {
        console.log(response.data, 'andi');
      }
      //   setResidents(
      //     residents.map((r) =>
      //       r._id === updatedResident._id ? response.data : r
      //     )
      //   );
      dispatch(
        showSnack({
          message: 'Resident updated successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.log(error, 'andi');
      dispatch(
        showSnack({
          message: error.message,
          color: 'danger'
        })
      );
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getWaterColor = (level: number) => {
    if (level <= 33) return alpha(theme.colors.success.dark, 0.7);
    if (level > 33 && level < 50) return alpha(theme.colors.success.light, 0.4);
    if (level >= 50 && level < 75) return alpha(theme.colors.warning.dark, 0.7);
    if (level >= 75 && level < 100)
      return alpha(theme.colors.warning.light, 0.4);
    if (level >= 100 && level < 133)
      return alpha(theme.colors.error.light, 0.7);
    if (level >= 133 && level < 150) return alpha(theme.colors.error.dark, 0.4);
    if (level >= 150) return alpha(theme.colors.error.dark, 0.7);
  };

  async function handleRefresh() {
    console.log('Refreshing...');
    await fetchResidents();
  }

  if (isFetchingResidents) {
    return (
      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height})`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const fetchLastHandoverTime = async () => {
    try {
      // const response = await getHandover().unwrap();
      // if (response.data && response.data.initiatedAt) {
      //   setLastHandoverTime(dayjs(response.data.initiatedAt).format('MMMM D, YYYY HH:mm'));
      // }
      const response = await getHandOverData(userState.homeId).unwrap();
      if (response.data && response.data.initiatedAt) {
        setLastHandoverTime(
          dayjs(response.data.initiatedAt).format('MMMM D, YYYY HH:mm')
        );
      }
    } catch (error) {
      console.error('Error fetching last handover time:', error);
    }
  };

  const handleInitiateHandover = async () => {
    try {
      // const response = await createOrUpdateHandover({ notes: 'Handover initiated' }).unwrap();
      // if (response.data && response.data.initiatedAt) {
      //   setLastHandoverTime(dayjs(response.data.initiatedAt).format('MMMM D, YYYY HH:mm'));
      //   dispatch(
      //     showSnack({
      //       message: 'Handover initiated successfully',
      //       color: 'success'
      //     })
      //   );
      // }
      const response = await initiateHandover(userState.homeId).unwrap();
      console.log(response, 'response');

      dispatch(
        showSnack({
          message: 'Handover initiated successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Error initiating handover:', error);
      dispatch(
        showSnack({
          message: 'Failed to initiate handover',
          color: 'danger'
        })
      );
    }
  };

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              padding: 2,
              marginBottom: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2
            }}
          >
            <LoadingButton
              loading={isInitiatingHandover}
              variant="contained"
              color="primary"
              startIcon={<SwapHorizIcon />}
              onClick={handleInitiateHandover}
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              Initiate Handover
            </LoadingButton>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTimeIcon color="action" />
              <Typography variant="body2" color="text.secondary">
                Last Handover: {lastHandoverTime || 'Not available'}
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              height: `calc(100vh - ${theme.header.height})`,
              width: '100%',
              position: 'relative',
              marginInline: 'auto',
              overflowY: 'auto',
              display: 'flex',
              flexWrap: 'wrap',
              padding: '0px !important', // Add some padding
              alignContent: 'flex-start' // This will ensure items start from the top
            }}
          >
            {residents.map((resident) => {
              const level = resident.currentStatus;
              return (
                <Grid
                  item
                  key={resident._id}
                  xs={3.8}
                  sm={3.8}
                  md={3.8}
                  lg={4} // Adjust these values for responsiveness
                >
                  <Box
                    onClick={() => handleOpenDialog(resident)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      boxShadow: '1px 1px 5px rgba(0,0,0,0.1)',
                      borderRadius: 2,
                      p: 2,
                      backgroundColor: theme.colors.alpha.trueWhite[100],
                      position: 'relative',
                      height: { xs: '120px', sm: 'auto' },
                      overflow: 'hidden',
                      [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        justifyContent: 'center'
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: `${level}%`,
                        opacity: 0.7,
                        background: `linear-gradient(to top, ${getWaterColor(
                          level
                        )}, ${getWaterColor(level)})`,
                        clipPath:
                          'polygon(0% 100%, 0% 45%, 15% 50%, 30% 45%, 45% 50%, 60% 45%, 75% 50%, 90% 45%, 100% 50%, 100% 100%)',
                        zIndex: 0
                      }
                    }}
                  >
                    {resident.loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            position: 'absolute',
                            top: 5,
                            left: 5,
                            backgroundColor: 'rgba(255,255,255,0.7)',
                            padding: '2px 5px',
                            borderRadius: '4px',
                            fontSize: '0.7rem',
                            display: { xs: 'block', sm: 'none' },
                            zIndex: 1
                          }}
                        >
                          {resident.roomNumber}
                        </Typography>

                        <Avatar
                          alt={`${resident.firstName} ${resident.lastName}`}
                          src={resident.profilePictureUrl}
                          sx={{
                            width: { xs: 48, sm: 56 },
                            height: { xs: 48, sm: 56 },
                            mr: isMobile ? 0 : 2,
                            mb: isMobile ? 1 : 0,
                            zIndex: 1
                          }}
                        />

                        <Box
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                            zIndex: 1
                          }}
                        >
                          <Typography variant="h6" component="div">
                            {resident.firstName} {resident.lastName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Type: {resident.type}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Room: {resident.roomNumber}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Group: {resident.groupId?.name || 'Not assigned'}
                          </Typography>
                        </Box>

                        {/* Content for mobile screens */}
                        <Box
                          sx={{
                            display: { xs: 'block', sm: 'none' },
                            textAlign: 'center',
                            width: '100%',
                            zIndex: 1
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '100%',
                              fontWeight: 'bold'
                            }}
                          >
                            {resident.firstName} {resident.lastName}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </PullToRefresh>
      <ResidentManagementDialog
        homeId=""
        open={dialogOpen}
        onClose={handleCloseDialog}
        resident={selectedResident}
        onSave={handleUpdateResident}
      />
    </>
  );
};

export default CarerResidentPage;
