import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ShiftTypeInit } from './interfaces/shift-type';
import { apiHostname } from 'src/api/api';
import type { IShiftPattern } from 'src/interfaces/shift-pattern';
import type { IInvoice } from 'src/interfaces/invoice';
import { response } from 'express';

interface IRate {
  careHomeId: string;
  weekdayRate: number;
  weekendRate: number;
}

interface IShiftType {
  _id?: string;
  name: string;
  startTime: string;
  endTime: string;
  rates: IRate[];
}

interface IShiftTypeResponse {
  userId: string;
  shiftTypes: IShiftType[];
}

// Define your API endpoints
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiHostname}/api/v1`,
    prepareHeaders: async (headers, { getState }) => {
      const token = await localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        console.log(token);
      }
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      // headers.set("X-API-Key", "secretKey");
      return headers;
    }
  }),

  endpoints: (builder) => ({
    // Query endpoint

    register: builder.mutation({
      query: (data) => ({
        url: 'auth/register',
        method: 'POST',
        body: data
      })
    }),

    sendJoinRequest: builder.mutation({
      query: (data) => ({
        url: 'join-requests',
        method: 'POST',
        body: data
      })
    }),
    getJoinRequests: builder.query({
      query: () => `join-requests`
    }),

    getShiftPatterns: builder.query({
      query: ({ userId }: { userId: string }) => `shifttype/${userId}`
    }),
    getShifts: builder.query({
      query: () => 'shift'
    }),

    setShiftPatternInit: builder.mutation({
      query: (data: { shifts: ShiftTypeInit[] }) => ({
        url: 'shifttype/init',
        method: 'POST',
        body: data
      })
    }),
    editShiftPattern: builder.mutation({
      query: ({ data, id }: { data: IShiftType; id: string }) => ({
        url: `shifttype/${id}`,
        method: 'PUT',
        body: {
          data: data
        }
      })
    }),
    deleteShiftPattern: builder.mutation({
      query: (id: string) => ({
        url: `shifttype/${id}/one`,
        method: 'DELETE'
      })
    }),
    publishShiftMultiple: builder.mutation({
      query: (
        data: {
          shiftPattern: IShiftType;
          count: number;
          agentId: string;
          date: string;
          shiftTypeRef: string;
          shiftTypeModel: string;
        }[]
      ) => ({
        url: 'shift/multiple',
        method: 'POST',
        body: {
          shiftsData: data
        }
      })
    }),
    publishShiftMultipleFree: builder.mutation({
      query: (
        data: {
          shiftPattern: IShiftType;
          count: number;
          agentId: string;
          date: string;
          shiftTypeRef: string;
          shiftTypeModel: string;
        }[]
      ) => ({
        url: 'shift/multiple/free',
        method: 'POST',
        body: {
          shiftsData: data
        }
      })
    }),

    deleteShift: builder.mutation({
      query: (id: string) => ({
        url: `shift/${id}`,
        method: 'DELETE'
      })
    }),
    getShiftWithAssignments: builder.query({
      query: (shiftId: string) => `shift/${shiftId}`
    }),
    assignUsersToShift: builder.mutation({
      query: ({ shiftId, userIds }) => ({
        url: `shift/${shiftId}/assign`,
        method: 'POST',
        body: { userIds }
      })
    }),
    unassignUserFromShift: builder.mutation({
      query: ({ shiftId, userId }) => ({
        url: `shift/${shiftId}/unassign/${userId}`,
        method: 'DELETE'
      })
    }),
    updateAssignmentStatus: builder.mutation({
      query: ({ assignmentId, status }) => ({
        url: `shift/assignments/${assignmentId}`,
        method: 'PUT',
        body: { status }
      })
    }),
    getAssignmentsForUser: builder.query({
      query: () => 'shift/assignments/user'
    }),

    replaceCarer: builder.mutation({
      query: ({
        oldCarerId,
        newCarerId,
        shiftId
      }: {
        oldCarerId: string;
        newCarerId: string;
        shiftId: string;
      }) => ({
        url: `shift/${shiftId}/replace-carer`,
        method: 'PUT',
        body: {
          oldCarerId,
          newCarerId
        }
      })
    }),

    getOrganizationRole: builder.query({
      query: (orgId: string) => `organizations/role/${orgId}`
    }),

    getOrganizationListing: builder.query({
      query: () => 'organizations/listing'
    }),

    addUserToOrganization: builder.mutation({
      query: (data: {
        userId: string;
        organizationId: string;
        role: string;
      }) => ({
        url: 'organizations/addUser',
        method: 'POST',
        body: data
      })
    }),

    sendLinkOrganization: builder.mutation({
      query: (data: {
        fromOrganizationId: string;
        toOrganizationId: string;
        message?: string;
      }) => ({
        url: 'organizations/linkInvitation',
        method: 'POST',
        body: data
      })
    }),

    getMyOrgInvitations: builder.query({
      query: () => `organizations/linkInvitations`
    }),

    responseToOrgInvitation: builder.mutation({
      query: (data: { invitationId: string; accept: boolean }) => ({
        url: 'organizations/respondToLinkInvitation',
        method: 'POST',
        body: data
      })
    }),

    getLinkedOrganizations: builder.query({
      query: (type: string | undefined) => `organizations/linked?type=${type}`
    }),

    getLinkedOrgranizationAdmins: builder.query({
      query: (type?: string) => `organizations/linked/admins`
    }),

    getLinkedOrganizationAdminForCarer: builder.query({
      query: () => `organizations/linked/admins/carer`
    }),

    searchOrganizations: builder.query({
      query: ({ term, exludedType }: { term: string; exludedType: string }) =>
        `organizations/search?searchTerm=${term}&excludeType=${exludedType}`
    }),
    // users
    getUser: builder.query({
      query: (userId: string) => `auth/user/profile`
    }),

    getLinkedUsers: builder.query({
      query: (type: string) => `auth/linked-users/${type}`
    }),
    getStaffsForAssign: builder.query({
      query: ({ date }: { date: string }) => `auth/staffs?date=${date}`
    }),
    linkUser: builder.mutation({
      query: ({ userId, userType }: { userId: string; userType: string }) => ({
        url: `auth/link`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType
        }
      })
    }),
    unLinkUser: builder.mutation({
      query: ({
        userId,
        userType,
        rating,
        review
      }: {
        userId: string;
        userType: string;
        rating?: number;
        review?: string;
      }) => ({
        url: `auth/unlink`,
        method: 'PATCH',
        body: {
          linkedUserId: userId,
          linkedUserType: userType,
          rating,
          review
        }
      })
    }),

    getUsers: builder.query({
      query: (accountType: string) => 'auth/users/' + accountType
    }),

    getCarerResume: builder.query({
      query: (carerId: string) => `auth/${carerId}/carer-resume`
    }),

    searchUsers: builder.query({
      query: ({ query, accountType }: { query: string; accountType: string }) =>
        `auth/search-users/${accountType}?companyName=${query}`
    }),

    updateAvailability: builder.mutation({
      query: (dates: string[]) => ({
        url: 'auth/update-availability',
        method: 'PUT',
        body: {
          dates: dates
        }
      })
    }),
    deleteAvailability: builder.mutation({
      query: (date: string) => ({
        url: `auth/availabilities/${date}`,
        method: 'DELETE'
      })
    }),

    getUnacceptedShiftsForAgency: builder.query({
      query: () => 'shift/agency/unaccepted'
    }),

    acceptShiftByAgency: builder.mutation({
      query: (shiftId: string) => ({
        url: `shift/agency/accept/${shiftId}`,
        method: 'POST'
      })
    }),

    assignCarers: builder.mutation({
      query: ({
        shiftId,
        carerIds
      }: {
        shiftId: string;
        carerIds: string[];
      }) => ({
        url: 'shift/assign-carers',
        method: 'POST',
        body: {
          shiftId,
          carerIds
        }
      })
    }),

    getAssignedStaffs: builder.query({
      query: (shiftId: string) => `shift/${shiftId}/assigned`
    }),

    unassignCarer: builder.mutation({
      query: ({ shiftId, carerId }: { shiftId: string; carerId: string }) => ({
        url: `shift/${shiftId}/unassign-carer`,
        method: 'POST',
        body: {
          carerId
        }
      })
    }),

    generateQR: builder.mutation({
      query: (shiftId: string) => ({
        url: `shift/${shiftId}/generateQR`,
        method: 'POST'
      })
    }),

    // documents

    uploadFiles: builder.mutation({
      query: ({
        userId,
        formData
      }: {
        userId: string;
        formData: FormData;
      }) => ({
        url: `carer-documents/${userId}/upload`,
        method: 'POST',
        body: formData
      })
    }),

    getCarerDocuments: builder.query({
      query: (userId: string) => `carer-documents/${userId}/documents`
    }),
    deleteCarerDocument: builder.mutation({
      query: ({
        carerId,
        documentId,
        fileName
      }: {
        carerId: string;
        documentId: string;
        fileName: string;
      }) => ({
        url: `carer-documents/${carerId}/document/${documentId}/file/${fileName}`,
        method: 'DELETE'
      })
    }),
    deleteCarerCertificate: builder.mutation({
      query: ({
        carerId,
        certificateId,
        fileName
      }: {
        carerId: string;
        certificateId: string;
        fileName: string;
      }) => ({
        url: `carer-documents/${carerId}/certificate/${certificateId}/file/${fileName}`,
        method: 'DELETE'
      })
    }),
    getCarerCertificates: builder.query({
      query: (userId: string) => `carer-documents/${userId}/certificates`
    }),

    uploadShareCode: builder.mutation({
      query: ({
        userId,
        shareCode
      }: {
        userId: string;
        shareCode: string;
      }) => ({
        url: `carer-documents/${userId}/share-code`,
        method: 'PATCH',
        body: {
          shareCode
        }
      })
    }),

    updateNINumber: builder.mutation({
      query: ({ userId, niNumber }: { userId: string; niNumber: string }) => ({
        url: `carer-documents/${userId}/ni-number`,
        method: 'PATCH',
        body: {
          niNumber
        }
      })
    }),

    getAdditionalInfo: builder.query({
      query: (userId: string) => `carer-documents/${userId}/additional-info`
    }),

    // Timesheets
    getTimesheets: builder.query({
      query: (userId: string) => `timesheet`
    }),

    getQuickStats: builder.query({
      query: (userId: string) => `shift/utils/quickstats`
    }),

    createTimesheetRequest: builder.mutation({
      query: (data: {
        shiftId: string;
        shiftPatternId: string;
        homeId: string;
      }) => ({
        url: `timesheet`,
        method: 'POST',
        body: data
      })
    }),
    approveTimesheet: builder.mutation({
      query: ({
        timesheetId,
        rating,
        review
      }: {
        timesheetId: string;
        rating?: number;
        review?: string;
      }) => ({
        url: `timesheet/${timesheetId}/approve`,
        method: 'PATCH',
        body: {
          rating,
          review
        }
      })
    }),
    rejectTimesheet: builder.mutation({
      query: (timesheetId: string) => ({
        url: `timesheet/${timesheetId}/reject`,
        method: 'PATCH'
      })
    }),
    // timeline
    getCompany: builder.query({
      query: (userId: string) => `timeline/current-company`
    }),
    getPreviousCompany: builder.query({
      query: (userId: string) => `timeline/previous-companies`
    }),
    // Invitations

    getInvitations: builder.query({
      query: (userId: string) => ({
        url: `invitations/`,
        method: 'GET'
      })
    }),
    getInvitation: builder.query({
      query: (invToken: string) => ({
        url: `invitations/token/${invToken}`,
        method: 'GET'
      })
    }),
    acceptInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/accept/${invitationId}`,
        method: 'PUT'
      })
    }),

    sendInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      })
    }),
    sendHomeStaffInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      })
    }),

    sendHomeInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/home`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      })
    }),
    sendAgencyInvitation: builder.mutation({
      query: ({
        accountType,
        receiverEmail,
        companyName
      }: {
        accountType: string;
        receiverEmail: string;
        companyName: string;
      }) => ({
        url: `invitations/agency`,
        method: 'POST',
        body: {
          accountType: accountType,
          receiverEmail: receiverEmail,
          companyName: companyName
        }
      })
    }),

    getHomeStaffInvitationByToken: builder.query({
      query: (invToken: string) => ({
        url: `invitations/home-staff/token/${invToken}`,
        method: 'GET'
      })
    }),

    rejectInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/reject/${invitationId}`,
        method: 'PUT'
      })
    }),
    cancelInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `invitations/${invitationId}`,
        method: 'DELETE'
      })
    }),

    // Groups

    createGroup: builder.mutation({
      query: (data: any) => ({
        url: 'groups',
        method: 'POST',
        body: data
      })
    }),
    getGroups: builder.query({
      query: (homeId: string) => `groups/${homeId}/all`
    }),

    // Residents

    getResidents: builder.query({
      query: (homeId: string) => `residents/${homeId}/all`
    }),
    getResidentTaskHistory: builder.query({
      query: ({
        residentId,
        startDate,
        endDate
      }: {
        residentId: string;
        startDate: string;
        endDate: string;
      }) =>
        `residents/${residentId}/task-history?startDate=${startDate}&endDate=${endDate}`
    }),

    initiateHandOver: builder.mutation({
      query: (homeId: string) => ({
        url: `residents/${homeId}/handover`,
        method: 'POST'
      })
    }),

    getHandOverData: builder.query({
      query: (homeId: string) => `residents/${homeId}/handover`
    }),

    getResidentsForCarer: builder.query({
      query: (carerId: string) => `residents/carer`
    }),

    createResident: builder.mutation({
      query: (data: any) => ({
        url: `residents`,
        method: 'POST',
        body: data
      })
    }),
    updateResident: builder.mutation({
      query: ({ id, data }: { id: string; data: any }) => ({
        url: `residents`,
        method: 'PUT',
        body: data
      })
    }),

    getCurrentCareStatus: builder.query({
      query: (residentId: string) => `residents/${residentId}/care-status`
    }),

    markTaskAsDue: builder.mutation({
      query: ({
        residentId,
        taskType,
        taskKey
      }: {
        residentId: string;
        taskType: string;
        taskKey: string;
      }) => ({
        url: `/residents/${residentId}/mark-task-due/${taskType}/${taskKey}`,
        method: 'POST'
      })
    }),

    resolveTask: builder.mutation({
      query: ({
        residentId,
        taskType,
        taskKey,
        description,
        additionalData,
        taskIndex
      }: {
        residentId: string;
        taskType: string;
        taskKey: string;
        description: string;
        additionalData?: any;
        taskIndex?: number;
      }) => ({
        url: `/residents/${residentId}/resolve-task/${taskIndex}/${taskType}/${taskKey}`,
        method: 'POST',
        body: {
          description,
          additionalData
        }
      })
    }),

    getTaskHistory: builder.query({
      query: ({
        residentId,
        startDate,
        endDate
      }: {
        residentId: string;
        startDate: string;
        endDate: string;
      }) =>
        `residents/${residentId}/task-history?startDate=${startDate}&endDate=${endDate}`
    }),

    updateFcmToken: builder.mutation({
      query: (fcmToken: string) => ({
        url: `auth/update-fcm-token`,
        method: 'PUT',
        body: {
          fcmToken
        }
      })
    }),

    sendChat: builder.mutation({
      query: (data: {
        senderId: string;
        recipientId: string;
        content: string;
        messageType: string;
      }) => ({
        url: `chat/`,
        method: 'POST',
        body: data
      })
    }),

    sendGlobalChat: builder.mutation({
      query: (data: {
        senderId: string;
        content: string;
        messageType: string;
        recipient: string;
      }) => ({
        url: `chat/global`,
        method: 'POST',
        body: data
      })
    }),

    markAsRead: builder.mutation({
      query: (messageId: string) => ({
        url: `chat/read/${messageId}`,
        method: 'PUT'
      })
    }),
    getUnreadCount: builder.query({
      query: () => `chat/unread/count`
    }),

    // // payments
    // createSubscription: builder.mutation<
    //   any,
    //   { planId: string; paymentMethodId: string }
    // >({
    //   query: (body) => ({
    //     url: 'auth/create-subscription',
    //     method: 'POST',
    //     body
    //   })
    // }),
    // cancelSubscription: builder.mutation<any, void>({
    //   query: () => ({
    //     url: 'auth/cancel-subscription',
    //     method: 'POST'
    //   })
    // }),
    // updatePaymentMethod: builder.mutation<any, { paymentMethodId: string }>({
    //   query: (body) => ({
    //     url: 'auth/update-payment-method',
    //     method: 'POST',
    //     body
    //   })
    // }),
    // getPaymentHistory: builder.query<any, void>({
    //   query: () => 'auth/payment-history'
    // }),
    // getSubscriptionStatus: builder.query<any, void>({
    //   query: () => 'auth/subscription-status'
    // }),

    initializeCustomer: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/initialize-customer',
        method: 'POST'
      })
    }),

    // Upload profile picture

    uploadProfilePicture: builder.mutation({
      query: ({ userId, file }) => ({
        url: `/picture/${userId}/upload`,
        method: 'POST',
        body: file,
        formData: true
      })
    }),

    // third party

    getThirdPartyUsers: builder.query({
      query: () => `auth/third-party`
    }),

    // Password reset

    requestPasswordReset: builder.mutation({
      query: (email: string) => ({
        url: `auth/request-reset`,
        method: 'POST',
        body: {
          email
        }
      })
    }),
    resetPassword: builder.mutation({
      query: (data: { password: string; token: string }) => ({
        url: `auth/reset-password`,
        method: 'POST',
        body: {
          token: data.token,
          newPassword: data.password
        }
      })
    }),

    // leave
    getAnnualLeaveConfig: builder.query({
      query: () => `leave/annual-leave/config`
    }),

    // Configure annual leave
    configureAnnualLeave: builder.mutation({
      query: (config: { staffType: string; daysPerYear: number }) => ({
        url: `leave/annual-leave/configure`,
        method: 'POST',
        body: config
      })
    }),

    // Request annual leave
    requestAnnualLeave: builder.mutation({
      query: (leaveRequest: { startDate: string; endDate: string }) => ({
        url: `leave/annual-leave/request`,
        method: 'POST',
        body: leaveRequest
      })
    }),

    // Get annual leave requests
    getAnnualLeaveRequests: builder.query({
      query: ({
        status,
        staffId
      }: {
        status?: 'pending' | 'approved' | 'rejected';
        staffId?: string;
      }) =>
        `leave/annual-leave/requests/${staffId}?${
          status ? `?status=${status}` : ''
        }`
    }),

    // Approve annual leave
    approveAnnualLeave: builder.mutation({
      query: (leaveId: string) => ({
        url: `leave/annual-leave/approve/${leaveId}`,
        method: 'POST'
      })
    }),

    // Get staff availability
    getStaffAvailability: builder.query({
      query: (date: string) => `leave/annual-leave/availability?date=${date}`
    }),

    // Get remaining leave days for current user
    getRemainingLeaveDays: builder.query({
      query: () => `leave/annual-leave/remaining-days`
    }),

    // Cancel annual leave request
    cancelAnnualLeave: builder.mutation({
      query: (leaveId: string) => ({
        url: `leave/annual-leave/cancel/${leaveId}`,
        method: 'POST'
      })
    }),

    // Get leave history for current user
    getLeaveHistory: builder.query({
      query: (year?: number) =>
        `leave/annual-leave/history${year ? `?year=${year}` : ''}`
    }),
    submitApplicationSection: builder.mutation({
      query: ({ data }: { data: any }) => ({
        url: 'application',
        method: 'POST',
        body: data
      })
    }),
    getApplication: builder.query({
      query: (id: string) => 'application/?carerId=' + id
    }),
    getHomeShiftTypes: builder.query<IShiftTypeResponse, string>({
      query: (userId) => `/shift-pattern/home/${userId}`
    }),
    getAgencyShiftTypes: builder.query<IShiftTypeResponse, string>({
      query: (userId) => `/shift-pattern/agency/${userId}`
    }),
    addHomeShiftType: builder.mutation<
      IShiftTypeResponse,
      { userId: string; shiftType: IShiftType }
    >({
      query: ({ userId, shiftType }) => ({
        url: '/shift-pattern',
        method: 'POST',
        body: { userId, shiftType }
      })
    }),

    addAgencyShiftType: builder.mutation<
      IShiftTypeResponse,
      { userId: string; shiftType: IShiftType }
    >({
      query: ({ userId, shiftType }) => ({
        url: '/shift-pattern',
        method: 'POST',
        body: { userId, shiftType }
      })
    }),
    updateHomeShiftType: builder.mutation<
      IShiftTypeResponse,
      { userId: string; shiftTypeId: string; shiftType: IShiftType }
    >({
      query: ({ userId, shiftTypeId, shiftType }) => ({
        url: `/shift-pattern/home/${userId}/${shiftTypeId}`,
        method: 'PUT',
        body: shiftType
      })
    }),
    updateAgencyShiftType: builder.mutation<
      IShiftTypeResponse,
      { userId: string; shiftTypeId: string; shiftType: IShiftType }
    >({
      query: ({ userId, shiftTypeId, shiftType }) => ({
        url: `/shift-pattern/agency/${userId}/${shiftTypeId}`,
        method: 'PUT',
        body: shiftType
      })
    }),
    deleteHomeShiftType: builder.mutation<
      void,
      { userId: string; shiftTypeId: string }
    >({
      query: ({ userId, shiftTypeId }) => ({
        url: `/shift-pattern/home/${userId}/${shiftTypeId}`,
        method: 'DELETE'
      })
    }),
    deleteAgencyShiftType: builder.mutation<
      void,
      { userId: string; shiftTypeId: string }
    >({
      query: ({ userId, shiftTypeId }) => ({
        url: `/shift-pattern/agency/${userId}/${shiftTypeId}`,
        method: 'DELETE'
      })
    }),

    getPublishedShifts: builder.query({
      query: (userId: string) => `/shift/published`
    }),

    getAgencyShifts: builder.query({
      query: (userId: string) => `/shift/published/agency`
    }),

    updateShift: builder.mutation({
      query: (data) => ({
        url: `/shift/${data?._id}`,
        method: 'PUT',
        body: data
      })
    }),
    getYourShiftPatterns: builder.query({
      query: (userId: string) => `/shift-pattern`
    }),
    getOtherShiftPatterns: builder.query({
      query: (userId: string) => `/shift-pattern/other/${userId}`
    }),
    createYourShiftType: builder.mutation({
      query: (data: IShiftPattern) => ({
        url: '/shift-pattern',
        method: 'POST',
        body: data
      })
    }),
    deleteYourShiftType: builder.mutation({
      query: (id: string) => ({
        url: `/shift-pattern/${id}`,
        method: 'DELETE'
      })
    }),
    updateYourShiftType: builder.mutation({
      query: (data: IShiftPattern) => ({
        url: `/shift-pattern/${data._id}`,
        method: 'PUT',
        body: data
      })
    }),

    // Invoice
    createInvoice: builder.mutation({
      query: (data) => ({
        url: '/invoices',
        method: 'POST',
        body: data
      })
    }),

    getInvoices: builder.query({
      query: () => '/invoices'
    }),

    getInvoiceById: builder.query({
      query: (id) => `/invoices/${id}`
    }),

    updateInvoiceStatus: builder.mutation({
      query: ({ invoiceId, status }) => ({
        url: `/invoices/${invoiceId}/status`,
        method: 'PATCH',
        body: { status }
      })
    }),

    // staffs

    getCareStaffs: builder.query({
      query: () => '/staffs/care'
    }),

    getAdminStaffs: builder.query({
      query: () => '/staffs/admin'
    }),

    createSubscription: builder.mutation({
      query: (data) => ({
        url: '/stripe/create-subscription',
        method: 'POST',
        body: data
      })
    }),
    createSetupIntent: builder.mutation({
      query: (data) => ({
        url: '/stripe/create-setup-intent',
        method: 'POST',
        body: data
      })
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: '/stripe/cancel-subscription',
        method: 'POST'
      })
    }),
    updatePaymentMethod: builder.mutation({
      query: (data) => ({
        url: '/stripe/update-payment-method',
        method: 'POST',
        body: data
      })
    }),
    getPlan: builder.query({
      query: (organizationType) => `/stripe/plans/${organizationType}`
    }),
    getPaymentHistory: builder.query({
      query: () => '/stripe/payment-history'
    }),
    getSubscriptionDetails: builder.query({
      query: (organizationId) =>
        `/stripe/subscription-details/${organizationId}`
    }),
    createCheckoutSession: builder.mutation<
      { id: string },
      { organizationId: string; priceId: string }
    >({
      query: (data) => ({
        url: '/stripe/create-checkout-session',
        method: 'POST',
        body: data
      })
    }),

    verifyCheckoutSession: builder.query<
      { success: boolean; message: string },
      string
    >({
      query: (sessionId) => `/stripe/verify-checkout-session/${sessionId}`
    }),
    getChats: builder.query({
      query: ({ otherUserId, page = 1, limit = 20 }) => ({
        url: `chat/${otherUserId}`,
        params: { page, limit }
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          return newItems;
        }
        return {
          ...newItems,
          data: {
            ...newItems.data,
            messages: [
              ...newItems.data.messages,
              ...(currentCache?.data?.messages || [])
            ]
          }
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: any) => {
        return {
          data: {
            messages: response.data.messages,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages,
            totalCount: response.data.totalCount
          }
        };
      }
    })
  })

  // stripe
});

// Export hooks for accessing the API endpoints
export const {
  // Staffs
  useGetCareStaffsQuery,
  useGetAdminStaffsQuery,
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceStatusMutation,

  useGetChatsQuery,

  useRegisterMutation,
  // Org
  useLazyGetOrganizationRoleQuery,
  useGetOrganizationListingQuery,
  useAddUserToOrganizationMutation,
  useSearchOrganizationsQuery,
  useSendLinkOrganizationMutation,
  useGetMyOrgInvitationsQuery,
  useResponseToOrgInvitationMutation,
  useGetLinkedOrganizationsQuery,
  useGetLinkedOrgranizationAdminsQuery,
  useGetLinkedOrganizationAdminForCarerQuery,

  // Join requests
  useGetJoinRequestsQuery,
  useSendJoinRequestMutation,

  // shifts
  useGetPublishedShiftsQuery,
  useGetAgencyShiftsQuery,
  useUpdateShiftMutation,
  useGetShiftWithAssignmentsQuery,
  useAssignUsersToShiftMutation,
  useUnassignUserFromShiftMutation,
  useUpdateAssignmentStatusMutation,
  useGetAssignmentsForUserQuery,
  useGetAssignedStaffsQuery,
  useGetQuickStatsQuery,
  useGetUnacceptedShiftsForAgencyQuery,
  useAcceptShiftByAgencyMutation,

  useLazyGetYourShiftPatternsQuery,
  useGetYourShiftPatternsQuery,

  useGetOtherShiftPatternsQuery,
  useLazyGetOtherShiftPatternsQuery,
  useCreateYourShiftTypeMutation,
  useDeleteYourShiftTypeMutation,
  useUpdateYourShiftTypeMutation,
  useGetShiftPatternsQuery,
  useGetShiftsQuery,
  useUpdateAvailabilityMutation,
  useDeleteAvailabilityMutation,
  useLazyGetShiftsQuery,
  useLazyGetShiftPatternsQuery,
  useSetShiftPatternInitMutation,
  useEditShiftPatternMutation,
  useDeleteShiftPatternMutation,
  useDeleteShiftMutation,
  useGenerateQRMutation,
  useGetLinkedUsersQuery,
  useLazyGetLinkedUsersQuery,
  usePublishShiftMultipleMutation,
  usePublishShiftMultipleFreeMutation,
  useAssignCarersMutation,
  useUnassignCarerMutation,
  useLazySearchUsersQuery,
  useLinkUserMutation,
  useUnLinkUserMutation,
  useAcceptInvitationMutation,
  useRejectInvitationMutation,
  useSendInvitationMutation,
  useGetInvitationsQuery,
  useLazyGetInvitationsQuery,
  useCancelInvitationMutation,
  useLazyGetUserQuery,
  useGetUserQuery,
  useLazyGetInvitationQuery,
  useLazyGetUsersQuery,
  useLazyGetCompanyQuery,
  useGetPreviousCompanyQuery,
  useLazyGetPreviousCompanyQuery,
  useLazyGetStaffsForAssignQuery,

  useReplaceCarerMutation,

  useSendHomeStaffInvitationMutation,
  useLazyGetHomeStaffInvitationByTokenQuery,
  useSendAgencyInvitationMutation,
  useSendHomeInvitationMutation,
  // Timesheets

  useLazyGetTimesheetsQuery,
  useCreateTimesheetRequestMutation,
  useApproveTimesheetMutation,
  useRejectTimesheetMutation,

  // Upload documents
  useUploadFilesMutation,
  useUploadShareCodeMutation,
  useLazyGetCarerCertificatesQuery,
  useLazyGetCarerDocumentsQuery,

  useDeleteCarerDocumentMutation,
  useDeleteCarerCertificateMutation,
  useUpdateNINumberMutation,

  useGetAdditionalInfoQuery,
  useLazyGetAdditionalInfoQuery,

  // Groups

  useLazyGetGroupsQuery,
  useCreateGroupMutation,

  // Resiodents
  useLazyGetResidentTaskHistoryQuery,
  useLazyGetResidentsQuery,
  useCreateResidentMutation,
  useUpdateResidentMutation,
  useLazyGetResidentsForCarerQuery,

  useGetCurrentCareStatusQuery,
  useLazyGetCurrentCareStatusQuery,
  useLazyGetTaskHistoryQuery,
  useMarkTaskAsDueMutation,
  useResolveTaskMutation,
  useGetTaskHistoryQuery,

  useUpdateFcmTokenMutation,

  // chat

  useSendChatMutation,
  useLazyGetChatsQuery,
  useSendGlobalChatMutation,

  // payments
  // useUpdatePaymentMethodMutation,
  // useGetPaymentHistoryQuery,
  // useGetSubscriptionStatusQuery,
  // useInitializeCustomerMutation,
  // useLazyGetSubscriptionStatusQuery,
  // useLazyGetPaymentHistoryQuery,

  useCreateSubscriptionMutation,
  useCreateSetupIntentMutation,
  useCancelSubscriptionMutation,

  useGetPlanQuery,
  useGetSubscriptionDetailsQuery,
  useUpdatePaymentMethodMutation,
  useGetPaymentHistoryQuery,

  useCreateCheckoutSessionMutation,
  useVerifyCheckoutSessionQuery,

  useLazyGetCarerResumeQuery,

  useUploadProfilePictureMutation,

  useLazyGetThirdPartyUsersQuery,

  useLazyGetHandOverDataQuery,
  useInitiateHandOverMutation,

  useRequestPasswordResetMutation,
  useResetPasswordMutation,

  // leave
  useGetAnnualLeaveConfigQuery,
  useConfigureAnnualLeaveMutation,
  useRequestAnnualLeaveMutation,
  useGetAnnualLeaveRequestsQuery,
  useApproveAnnualLeaveMutation,
  useGetStaffAvailabilityQuery,
  useGetRemainingLeaveDaysQuery,
  useCancelAnnualLeaveMutation,
  useLazyGetAnnualLeaveRequestsQuery,

  // carer application
  useSubmitApplicationSectionMutation,
  useGetApplicationQuery,
  useLazyGetApplicationQuery,

  useGetHomeShiftTypesQuery,
  useGetAgencyShiftTypesQuery,
  useLazyGetAgencyShiftTypesQuery,
  useLazyGetHomeShiftTypesQuery,
  useAddHomeShiftTypeMutation,
  useAddAgencyShiftTypeMutation,
  useUpdateHomeShiftTypeMutation,
  useUpdateAgencyShiftTypeMutation,
  useDeleteHomeShiftTypeMutation,
  useDeleteAgencyShiftTypeMutation
} = api;
