import React, { useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Box,
  styled
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2
}));

const DayCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 28
  }
}));

const DayTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1)
}));

// Types
type DayAvailability = {
  available: boolean;
  day: boolean;
  night: boolean;
};

interface AvailabilityScheduleFormData {
  monday: DayAvailability;
  tuesday: DayAvailability;
  wednesday: DayAvailability;
  thursday: DayAvailability;
  friday: DayAvailability;
  saturday: DayAvailability;
  sunday: DayAvailability;
  preferredShiftLength: string;
  maxHoursPerWeek: number;
  additionalNotes: string;
}

interface AvailabilityScheduleProps {
  onSubmit: SubmitHandler<{ availability: AvailabilityScheduleFormData }>;
}

const AvailabilitySchedule: React.FC<AvailabilityScheduleProps> = ({
  onSubmit
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm<AvailabilityScheduleFormData>({
    defaultValues: {
      monday: { available: false, day: false, night: false },
      tuesday: { available: false, day: false, night: false },
      wednesday: { available: false, day: false, night: false },
      thursday: { available: false, day: false, night: false },
      friday: { available: false, day: false, night: false },
      saturday: { available: false, day: false, night: false },
      sunday: { available: false, day: false, night: false },
      preferredShiftLength: '',
      maxHoursPerWeek: 0,
      additionalNotes: ''
    }
  });

  const userState = useAppSelector((state) => state.user.user);
  const application = useAppSelector(
    (state) => state.carerApplication.application
  );

  useEffect(() => {
    if (application.availability) {
      Object.entries(application.availability).forEach(([key, value]) => {
        if (key in application.availability) {
          setValue(key as keyof AvailabilityScheduleFormData, value as any);
        }
      });
    }
  }, [application, setValue]);

  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ] as const;

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ availability: data }))}>
      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Availability Schedule
        </Typography>
        <Grid container spacing={3}>
          {days.map((day) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={day}>
              <DayCard elevation={2}>
                <DayTypography variant="subtitle1">
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </DayTypography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller
                        name={`${day}.available`}
                        control={control}
                        render={({ field }) => (
                          <StyledCheckbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              if (!e.target.checked) {
                                setValue(`${day}.day`, false);
                                setValue(`${day}.night`, false);
                              }
                            }}
                          />
                        )}
                      />
                    }
                    label="Available"
                  />
                  {watch(`${day}.available`) && (
                    <Box>
                      <FormControlLabel
                        control={
                          <Controller
                            name={`${day}.day`}
                            control={control}
                            rules={{
                              validate: (value) =>
                                value ||
                                watch(`${day}.night`) ||
                                'Select at least one'
                            }}
                            render={({ field }) => (
                              <StyledCheckbox
                                {...field}
                                checked={field.value}
                              />
                            )}
                          />
                        }
                        label="Day"
                      />
                      <FormControlLabel
                        control={
                          <Controller
                            name={`${day}.night`}
                            control={control}
                            rules={{
                              validate: (value) =>
                                value ||
                                watch(`${day}.day`) ||
                                'Select at least one'
                            }}
                            render={({ field }) => (
                              <StyledCheckbox
                                {...field}
                                checked={field.value}
                              />
                            )}
                          />
                        }
                        label="Night"
                      />
                    </Box>
                  )}
                </FormGroup>
                {errors[day]?.day && (
                  <FormHelperText error>
                    {errors[day]?.day?.message}
                  </FormHelperText>
                )}
              </DayCard>
            </Grid>
          ))}

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.preferredShiftLength}>
              <InputLabel id="preferred-shift-length-label">
                Preferred Shift Length
              </InputLabel>
              <Controller
                name="preferredShiftLength"
                control={control}
                rules={{ required: 'Preferred shift length is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="preferred-shift-length-label"
                    label="Preferred Shift Length"
                  >
                    <MenuItem value="4">4 hours</MenuItem>
                    <MenuItem value="6">6 hours</MenuItem>
                    <MenuItem value="8">8 hours</MenuItem>
                    <MenuItem value="12">12 hours</MenuItem>
                  </Select>
                )}
              />
              {errors.preferredShiftLength && (
                <FormHelperText>
                  {errors.preferredShiftLength.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="maxHoursPerWeek"
              control={control}
              rules={{
                required: 'Maximum hours per week is required',
                min: { value: 1, message: 'Must be at least 1 hour' },
                max: { value: 168, message: 'Cannot exceed 168 hours (7 days)' }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Maximum Hours per Week"
                  type="number"
                  fullWidth
                  error={!!errors.maxHoursPerWeek}
                  helperText={errors.maxHoursPerWeek?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="additionalNotes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Additional Notes on Availability"
                  fullWidth
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mt: 2,
                borderRadius: (theme) => theme.shape.borderRadius * 2,
                boxShadow: (theme) => theme.shadows[3]
              }}
            >
              Save & Continue
            </Button>
          </Grid>
        </Grid>
      </StyledPaper>
    </form>
  );
};

export default AvailabilitySchedule;
