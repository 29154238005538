import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Avatar,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { ITaskHistory } from 'src/interfaces/resident';
import { PersonStandingIcon } from 'lucide-react';

interface IResident {
    _id?: string;
    homeId: string;
    firstName: string;
    lastName: string;
    roomNumber: string;
    profilePictureUrl: string;
    type: 'Permanent' | 'Temporary' | 'Respite';
    medications: IMedication[];
    personalCare: IPersonalCare;
    groupId?: any;
    currentStatus: number;
    dueTasks?: IDueTask[];
    homeName?: string;
}

interface IMedication {
    type: string;
    frequency: {
        times: number;
        per: 'day' | 'week';
    };
    label: string;
    medicineName: string;
    timings: string[];
    status: {
        isDue: boolean;
        lastDueTime?: Date;
        lastResolvedTime?: Date;
        lastResolvedDescription?: string;
    };
}

interface IPersonalCare {
    [key: string]: IPersonalCareItem | IMealCareItem;
}

interface IPersonalCareItem {
    frequency: {
        times: number;
        per: 'day' | 'week';
    };
    timings?: string[];
    statuses: {
        isDue: boolean;
        lastDueTime?: Date;
        lastResolvedTime?: Date;
        lastResolvedDescription?: string;
        additionalData?: any;
    }[];
}

interface IMealCareItem extends IPersonalCareItem {
    defaultTime: string;
}

interface IDueTask {
    type: 'personalCare' | 'medication';
    key: string;
    minutesPastDue: number;
    taskTime?: string;
}


interface ResidentTaskHistoryResponse {
    resident: IResident;
    taskHistory: ITaskHistory[];
}

interface ResidentTaskHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    resident: IResident;
    fetchTaskHistory: (residentId: string, startDate: Date, endDate: Date) => Promise<ResidentTaskHistoryResponse>;
}

const ResidentTaskHistoryDialog: React.FC<ResidentTaskHistoryDialogProps> = ({
    open,
    onClose,
    resident,
    fetchTaskHistory
}) => {
    const [viewType, setViewType] = useState<'month' | 'year'>('month');
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [taskHistory, setTaskHistory] = useState<ITaskHistory[]>([]);
    const [resident_, setResident_] = useState<IResident>(resident);

    useEffect(() => {
        if (open) {
            fetchHistoryData();
        }
    }, [open, viewType, selectedDate]);

    const fetchHistoryData = async () => {
        let startDate: Dayjs;
        let endDate: Dayjs;

        if (viewType === 'month') {
            startDate = selectedDate.startOf('month');
            endDate = selectedDate.endOf('month');
        } else {
            startDate = selectedDate.startOf('year');
            endDate = selectedDate.endOf('year');
        }

        console.log('Fetching task history for:', resident._id, startDate.toISOString(), endDate.toISOString());
        try {
            const response = await fetchTaskHistory(
                resident._id!,
                new Date(startDate.toISOString()),
                new Date(endDate.toISOString())
            );
            setTaskHistory(response.taskHistory);
            console.log('Task history:', response);
        } catch (error) {
            console.error('Error fetching task history:', error);
            // You might want to show an error message to the user here
        }
    };

    const handleDateChange = (newDate: Dayjs | null) => {
        if (newDate) {
            setSelectedDate(newDate);
        }
    };

    const handleViewTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setViewType(event.target.value as 'month' | 'year');
    };

    const groupTasksByDate = (tasks: ITaskHistory[]) => {
        const grouped = tasks.reduce((acc, task) => {
            const date = dayjs(task.resolvedTime).format('YYYY-MM-DD');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(task);
            return acc;
        }, {} as Record<string, ITaskHistory[]>);

        return Object.entries(grouped).sort(([dateA], [dateB]) => dayjs(dateB).diff(dayjs(dateA)));
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Task History</DialogTitle>
            <DialogContent
                sx={{
                    pt: 2
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center" mb={2}>
                            {resident_?.profilePictureUrl ? (
                                <Avatar
                                    src={resident_?.profilePictureUrl}
                                    alt={`${resident_.firstName} ${resident_.lastName}`}
                                    sx={{ width: 64, height: 64, mr: 2 }}
                                />
                            ) : (
                                <Avatar sx={{ width: 64, height: 64, mr: 2 }}>
                                    <PersonStandingIcon />
                                </Avatar>
                            )}
                            <Box>
                                <Typography variant="h6">{`${resident_.firstName} ${resident_.lastName}`}</Typography>
                                <Typography variant="body2">Room: {resident_.roomNumber}</Typography>
                                <Typography variant="body2">Group: {resident_.groupId.name}</Typography>
                                <Typography variant="body2">Home: {resident_.homeName}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel>View</InputLabel>
                                <Select
                                    value={viewType}
                                    onChange={(e) => setViewType(e.target.value as 'month' | 'year')}
                                    label="View"
                                >
                                    <MenuItem value="month">Month</MenuItem>
                                    <MenuItem value="year">Year</MenuItem>
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={viewType === 'month' ? ['year', 'month'] : ['year']}
                                    label={viewType === 'month' ? "Month and Year" : "Year"}
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue!)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Task</TableCell>
                                <TableCell>Scheduled Time</TableCell>
                                <TableCell>Resolved Time</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupTasksByDate(taskHistory).map(([date, tasks]) => (
                                <React.Fragment key={date}>
                                    {tasks.map((task) => (
                                        <TableRow key={task._id}>
                                            <TableCell>
                                                <Typography variant="subtitle1">{dayjs(date).format('MMMM D, YYYY')}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {task.status === 'missed' ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: 'error.light',
                                                            color: 'white',
                                                            borderRadius: 4,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            padding: '2px 2px'
                                                        }}
                                                    >
                                                        <Typography variant="body2" color="secondary">Missed</Typography>
                                                    </Box>
                                                ) : (
                                                    <Typography variant="body2" color="error">Resolved</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>{`${task.taskType} - ${task.taskKey}`}</TableCell>
                                            <TableCell>{dayjs(task.scheduledTime).format('HH:mm')}</TableCell>
                                            <TableCell>{dayjs(task.resolvedTime).format('HH:mm')}</TableCell>
                                            <TableCell>{task.description || 'N/A'}</TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResidentTaskHistoryDialog;