import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  Typography,
  IconButton,
  Box,
  CircularProgress
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiHostname } from 'src/api/api';
import {
  useCreateGroupMutation,
  useLazyGetGroupsQuery
} from 'src/services/api';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

const residentFormLabels = {
  homeId: 'Care Home',
  medications: 'Medications',
  groupId: 'Resident Group',
  personalCare: {
    shower: {
      label: 'Shower',
      frequency: 'Shower Frequency',
      timings: 'Shower Times'
    },
    bath: {
      label: 'Bath',
      frequency: 'Bath Frequency',
      timings: 'Bath Schedule'
    },
    breakfast: {
      label: 'Breakfast',
      frequency: 'Breakfast Frequency',
      timings: 'Breakfast Times'
    },
    lunch: {
      label: 'Lunch',
      frequency: 'Lunch Frequency',
      timings: 'Lunch Times'
    },
    dinner: {
      label: 'Dinner',
      frequency: 'Dinner Frequency',
      timings: 'Dinner Times'
    },
    snacks: {
      label: 'Snacks',
      frequency: 'Snack Frequency',
      timings: 'Snack Times'
    },
    padCheck: {
      label: 'Pad Check',
      frequency: 'Pad Check Frequency',
      timings: 'Pad Check Times'
    },
    fluidIntake: {
      label: 'Fluid Intake',
      frequency: 'Fluid Intake Frequency',
      timings: 'Fluid Intake Times'
    },
    sleep: {
      label: 'Sleep',
      frequency: 'Sleep Frequency',
      timings: 'Sleep Times'
    },
    dayCheck: {
      label: 'Day Check',
      frequency: 'Day Check Frequency',
      timings: 'Day Check Times',
      interval: 'Day Check Interval'
    },
    nightCheck: {
      label: 'Night Check',
      frequency: 'Night Check Frequency',
      timings: 'Night Check Times',
      interval: 'Night Check Interval'
    }
  }
};

interface AddResidentDialogProps {
  open: boolean;
  onClose: () => void;
  homeId: string;
  existingResident?: IResident; // New prop for existing resident
}
const mockGroups = [
  { _id: '1', name: 'Group A' },
  { _id: '2', name: 'Group B' },
  { _id: '3', name: 'Group C' }
];

interface IResident {
  _id?: string; // Added _id for existing residents
  homeId: string;
  firstName: string;
  lastName: string;
  roomNumber: string;
  profilePictureUrl: string;
  type: 'Permanent' | 'Temporary' | 'Respite';
  medications: IMedication[];
  personalCare: IPersonalCare;
  groupId?: any;
}

interface IMedication {
  type: string;
  frequency: {
    times: number;
    per: 'day' | 'week';
  };
  label: string;
  medicineName: string;
  timings: string[];
}

interface IPersonalCare {
  [key: string]: IPersonalCareItem | IMealCareItem | ICheckItem;
}

interface ICheckItem extends IPersonalCareItem {
  interval: number;
}
interface IPersonalCareItem {
  frequency: {
    times: number;
    per: 'day' | 'week' | 'night';
  };
  timings?: Array<string | { day: string; time: string }>;
}

interface IMealCareItem extends IPersonalCareItem {
  defaultTime: string;
}

// Helper function to remove _id fields recursively
const removeIdFields = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeIdFields);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (key !== '_id' && key !== 'id') {
        acc[key] = removeIdFields(value);
      }
      return acc;
    }, {} as any);
  }
  return obj;
};

const residentTypes = ['Permanent', 'Temporary', 'Respite'];
const frequencyPerOptions = ['day', 'week', 'night'] as const;
type FrequencyPer = typeof frequencyPerOptions[number];
const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const AddResidentDialog: React.FC<AddResidentDialogProps> = ({
  open,
  onClose,
  homeId,
  existingResident
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset
  } = useForm<IResident>({
    defaultValues: existingResident || {
      homeId,
      medications: [],
      groupId: '',
      personalCare: {
        shower: { frequency: { times: 1, per: 'day' }, timings: [''] },
        bath: {
          frequency: { times: 1, per: 'week' },
          timings: [{ day: 'Monday', time: '09:00' }]
        },
        breakfast: {
          frequency: { times: 1, per: 'day' },
          timings: ['08:00']
        },
        lunch: { frequency: { times: 1, per: 'day' }, timings: ['12:00'] },
        dinner: { frequency: { times: 1, per: 'day' }, timings: ['18:00'] },
        snacks: { frequency: { times: 2, per: 'day' }, timings: ['', ''] },
        padCheck: {
          frequency: { times: 4, per: 'day' },
          timings: ['', '', '', '']
        },
        fluidIntake: {
          frequency: { times: 6, per: 'day' },
          timings: ['', '', '', '', '', '']
        },
        sleep: { frequency: { times: 1, per: 'day' }, timings: [''] },
        dayCheck: {
          frequency: { times: 1, per: 'day' },
          timings: ['08:00'],
          interval: 1
        },
        nightCheck: {
          frequency: { times: 1, per: 'night' },
          timings: ['20:00'],
          interval: 1
        }
      }
    }
  });

  const {
    fields: medicationFields,
    append: appendMedication,
    remove: removeMedication
  } = useFieldArray({
    control,
    name: 'medications'
  });

  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');

  const [groups, setGroups] = React.useState<any[]>([]);

  const dispatch = useDispatch();

  //   rtk

  const [getGroups, getGroupsState] = useLazyGetGroupsQuery();
  const [createGroup, createGroupState] = useCreateGroupMutation();

  const fetchGroups = async () => {
    try {
      const response = await getGroups(homeId).unwrap();
      setGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);
  /**
   * Handles adding a new group
   */
  const handleAddGroup = async () => {
    // TODO: Implement actual API call to add a new group
    const newGroup: any = {
      id: Date.now().toString(), // Temporary ID
      name: newGroupName,
      description: newGroupDescription
    };
    try {
      const response = await createGroup({
        homeId,
        name: newGroupName,
        description: newGroupDescription
      }).unwrap();
      setGroups([...groups, response.data]);
    } catch (error) {
      console.error(error);
    }
    setIsDialogOpen(false);
  };

  React.useEffect(() => {
    if (existingResident) {
      reset(existingResident);
    }
  }, [existingResident, reset]);

  const onSubmit = async (data: IResident) => {
    try {
      let newData = data;
      newData = removeIdFields(data);
      console.log(newData, '-----');
      const url = existingResident
        ? `${apiHostname}/api/v1/residents/${existingResident._id}`
        : `${apiHostname}/api/v1/residents`;
      const method = existingResident ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(newData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const responseData = await response.json();
      dispatch(showSnack({ message: responseData.message, color: 'success' }));
      reset();
      onClose();
    } catch (error) {
      console.error(
        existingResident
          ? 'Error updating resident:'
          : 'Error adding resident:',
        error
      );
    }
  };

  const generateTimings = (
    startTime: string,
    frequency: number,
    key: string
  ) => {
    const timings = watch(`personalCare.${key}.timings`);
    const time = timings[timings.length - 1];

    const t = moment(`2000-01-01T${time}`);

    const times = watch(`personalCare.${key}.frequency.times`);

    let currentTime = new Date(`2000-01-01T${startTime}`);
    const interval = watch(`personalCare.${key}.interval`);
    const freq = watch(`personalCare.${key}.timings`);

    if (frequency < freq.length) {
      timings.pop();
      return timings;
    }

    const next_time = t.add('hours', interval * (frequency - freq.length));

    timings.push(next_time.format('HH:mm'));

    return timings;
  };

  const renderPersonalCareFields = () => {
    return Object.entries(control._defaultValues.personalCare).map(
      ([key, value]) => {
        const frequencyTimes = watch(`personalCare.${key}.frequency.times`);
        const frequencyPer = watch(
          `personalCare.${key}.frequency.per`
        ) as FrequencyPer;

        if (key === 'dayCheck' || key === 'nightCheck') {
          return (
            <Grid container spacing={1} key={key} marginTop={1}>
              <Grid item xs={12} sm={2}>
                <Typography>
                  {residentFormLabels.personalCare[key].label}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller
                  name={`personalCare.${key}.frequency.times` as const}
                  control={control}
                  rules={{
                    min: {
                      value: 1,
                      message: 'Frequency must be at least 1'
                    },
                    validate: (value) =>
                      value >= 1 || 'Frequency must be at least 1'
                  }}
                  defaultValue={(value as ICheckItem).frequency?.times}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Frequency"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1
                        }
                      }}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        const newFrequency = parseInt(e.target.value);
                        const startTime =
                          key === 'dayCheck' ? '08:00' : '20:00';
                        const newTimings = generateTimings(
                          startTime,
                          newFrequency,
                          key
                        );
                        setValue(`personalCare.${key}.timings`, newTimings);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller
                  name={`personalCare.${key}.frequency.per` as const}
                  control={control}
                  defaultValue={(value as ICheckItem).frequency?.per}
                  render={({ field }) => (
                    <TextField {...field} select label="Per" fullWidth>
                      {frequencyPerOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller
                  name={`personalCare.${key}.interval` as const}
                  control={control}
                  defaultValue={(value as ICheckItem).interval}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Interval (hours)"
                      type="number"
                      fullWidth
                      InputProps={{ readOnly: false }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>
                  {watch(`personalCare.${key}.timings`).map((time, index) => (
                    <Grid item xs={6} key={index}>
                      <Controller
                        name={`personalCare.${key}.timings.${index}` as const}
                        control={control}
                        defaultValue={time}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Time ${index + 1}`}
                            type="time"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          );
        }

        return (
          <Grid container spacing={1} key={key} marginTop={1}>
            <Grid item xs={12} sm={2}>
              <Typography>
                {residentFormLabels.personalCare[key]?.label}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                name={`personalCare.${key}.frequency.times` as const}
                control={control}
                defaultValue={(value as IPersonalCareItem).frequency?.times}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Frequency"
                    type="number"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                name={`personalCare.${key}.frequency.per` as const}
                control={control}
                defaultValue={(value as IPersonalCareItem).frequency?.per}
                render={({ field }) => (
                  <TextField {...field} select label="Per" fullWidth>
                    {frequencyPerOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* {['breakfast', 'lunch', 'dinner', 'snacks'].includes(key) ? (
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`personalCare.${key}.defaultTime` as const}
                  control={control}
                  defaultValue={(value as IMealCareItem).defaultTime}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Default Time"
                      type="time"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            ) : (
              
            )} */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                {Array.from({ length: frequencyTimes }, (_, index) => (
                  <Grid item xs={12} key={index}>
                    {frequencyPer === 'week' ? (
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Controller
                            name={
                              `personalCare.${key}.timings.${index}.day` as const
                            }
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                select
                                label={`Day ${index + 1}`}
                                fullWidth
                              >
                                {weekDays.map((day) => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={
                              `personalCare.${key}.timings.${index}.time` as const
                            }
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label={`Time ${index + 1}`}
                                type="time"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Controller
                        name={`personalCare.${key}.timings.${index}` as const}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Time ${index + 1}`}
                            type="time"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {existingResident ? 'Update Resident' : 'Add Resident'}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'First Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Last Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="roomNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Room Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Room Number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={!!errors.roomNumber}
                      helperText={errors.roomNumber?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: 'Resident Type is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Resident Type"
                      select
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={!!errors.type}
                      helperText={errors.type?.message}
                    >
                      {residentTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <Controller
                name="profilePictureUrl"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Profile Picture URL"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Grid> */}
              <Grid item xs={12}>
                {JSON.stringify(existingResident?.groupId?._id)}
                <Controller
                  name="groupId"
                  control={control}
                  defaultValue={existingResident?.groupId?._id || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Group"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      defaultValue={existingResident?.groupId?._id || ''}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {groups.map((group) => (
                        <MenuItem key={group._id} value={group._id}>
                          {group.name}
                        </MenuItem>
                      ))}
                      <Box
                        sx={{
                          width: '100%',
                          justifyContent: 'flex-end',
                          display: 'flex'
                        }}
                      >
                        <LoadingButton
                          loading={createGroupState.isLoading}
                          sx={{
                            marginLeft: 'auto'
                          }}
                          size="small"
                          variant="contained"
                          onClick={async () => {
                            setIsDialogOpen(true);
                          }}
                        >
                          Add New Group
                        </LoadingButton>
                      </Box>
                    </TextField>
                  )}
                />
              </Grid>

              {/* Medications Section */}
              <Grid item xs={12} spacing={1}>
                <Typography variant="h6">Medications</Typography>
                {medicationFields.map((field, index) => (
                  <Grid container spacing={2} key={field.id}>
                    <Grid item xs={12} sm={3}>
                      <Controller
                        name={`medications.${index}.medicineName`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Medicine Name"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controller
                        name={`medications.${index}.type`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField {...field} label="Type" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Controller
                        name={`medications.${index}.frequency.times`}
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Frequency"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Controller
                        name={`medications.${index}.frequency.per`}
                        control={control}
                        defaultValue="day"
                        render={({ field }) => (
                          <TextField {...field} select label="Per" fullWidth>
                            {frequencyPerOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton onClick={() => removeMedication(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={() =>
                    appendMedication({
                      medicineName: '',
                      type: '',
                      frequency: { times: 1, per: 'day' },
                      label: '',
                      timings: []
                    })
                  }
                >
                  Add Medication
                </Button>
              </Grid>

              {/* Personal Care Section */}
              <Grid item xs={12} gap={2} spacing={2}>
                <Typography variant="h6">Personal Care</Typography>
                {renderPersonalCareFields()}
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {existingResident ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add New Group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Group Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={newGroupDescription}
            onChange={(e) => setNewGroupDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddGroup} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default AddResidentDialog;
