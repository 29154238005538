import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Tooltip,
  IconButton,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AccessTime as AccessTimeIcon,
  Home as HomeIcon,
  Group as GroupIcon,
  PersonAdd as PersonAddIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import {
  useGetAssignedStaffsQuery,
  useCreateTimesheetRequestMutation
} from 'src/services/api';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  '&:before': {
    display: 'none'
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(1)} 0`
  }
}));

const ShiftInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}));

interface ShiftAccordionProps {
  shift: any;
  userState: any;
  onAssignStaff: (shift: any) => void;
  onViewAssignedStaff: (shift: any) => void;
  onEditClick: (shift: any) => void;
  onDeleteClick: (shift: any) => void;
  setAssignedStaffs: React.Dispatch<React.SetStateAction<any[] | null>>;
  onShiftUpdate: (updatedShift: any) => void;
  onAccept: (shift: any) => Promise<void>;
}

const ShiftAccordion: React.FC<ShiftAccordionProps> = ({
  shift,
  userState,
  onAssignStaff,
  onViewAssignedStaff,
  onEditClick,
  onDeleteClick,
  setAssignedStaffs,
  onShiftUpdate,
  onAccept
}) => {
  const dispatch = useDispatch();
  const [requestTimeSheet, requestTimeSheetState] =
    useCreateTimesheetRequestMutation();

  const {
    data: assignedStaffs,
    isLoading: isAssignedStaffsLoading,
    refetch
  } = useGetAssignedStaffsQuery(shift?._id, {
    skip: !shift?._id
  });

  const companyName =
    userState.currentOrganization?.type === 'agency'
      ? shift?.homeId?.name
      : shift?.agentId?.name;
  const timing = shift?.shiftPattern?.timings?.find(
    (timing) => timing.careHomeId === shift?.homeId?._id
  );
  const shiftStartTime = timing?.startTime || '';
  const shiftEndTime = timing?.endTime || '';

  const handleRequestTimesheet = async () => {
    try {
      const response = await requestTimeSheet({
        shiftId: shift._id,
        shiftPatternId: shift.shiftPattern?._id,
        homeId: shift.homeId?._id
      }).unwrap();
      dispatch(showSnack({ message: response.message, color: 'success' }));
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error?.message || 'Error requesting timesheet. Try again later',
          color: 'danger'
        })
      );
      console.log(error);
    }
  };

  const isCarer = ['carer', 'nurse'].includes(userState.user.accountType);
  const isAgency = userState.currentOrganization?.type === 'agency';
  const isShiftAccepted = shift.isAccepted;

  const handleAcceptShift = async () => {
    try {
      await onAccept(shift);
    } catch (error) {
      console.error('Error accepting shift:', error);
    }
  };

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${shift._id}-content`}
        id={`panel-${shift._id}-header`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '$ .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center'
          }
        }}
      >
        <Typography variant="subtitle1">{shift.shiftPattern.name}</Typography>
        <Box>
          {shift.isCompleted && (
            <Chip
              label="Completed"
              color="success"
              size="small"
              sx={{ ml: 1 }}
            />
          )}
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails>
        <ShiftInfoBox>
          <HomeIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2">{companyName}</Typography>
        </ShiftInfoBox>
        <ShiftInfoBox>
          <AccessTimeIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2">
            {shiftStartTime} - {shiftEndTime}
          </Typography>
        </ShiftInfoBox>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Chip
            label={shift.isAccepted ? 'Accepted' : 'Pending'}
            color={shift.isAccepted ? 'success' : 'warning'}
            size="small"
          />
          <Typography variant="body2">Count: {shift.count}</Typography>
        </Box>
        {isCarer && (
          <Box mt={2}>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={
                shift.timesheet?.status === 'pending' ||
                shift.timesheet?.status === 'approved'
              }
              onClick={handleRequestTimesheet}
              loading={requestTimeSheetState.isLoading}
              fullWidth
            >
              {shift.timesheet?.status === 'pending'
                ? 'Timesheet Pending'
                : shift.timesheet?.status === 'approved'
                ? 'Timesheet Approved'
                : 'Request Timesheet'}
            </LoadingButton>
          </Box>
        )}
        {!isCarer && (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                size="small"
                startIcon={<GroupIcon />}
                onClick={() => {
                  onViewAssignedStaff(shift);
                  setAssignedStaffs(assignedStaffs);
                }}
                disabled={isAgency && !isShiftAccepted}
              >
                Assigned Users ({assignedStaffs?.length || 0})
              </Button>
              {userState.currentOrganization?.type === 'home' && (
                <Box>
                  <Tooltip title="Edit Shift">
                    <IconButton
                      size="small"
                      onClick={() => onEditClick(shift)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Shift">
                    <IconButton
                      size="small"
                      onClick={() => onDeleteClick(shift)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              {isAgency && !isShiftAccepted ? (
                <LoadingButton
                  variant="contained"
                  size="small"
                  startIcon={<CheckIcon />}
                  onClick={handleAcceptShift}
                  loading={false}
                >
                  Accept Shift
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<PersonAddIcon />}
                  onClick={() => {
                    onAssignStaff(shift);
                  }}
                  disabled={isAgency && !isShiftAccepted}
                >
                  Assign Staff
                </Button>
              )}
            </Box>
          </>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ShiftAccordion;
