// menuItems.ts
export interface MenuItem {
  label: string;
  icon: string;
  link?: string;
  submenu?: MenuItem[];
}

export const calculateMenuItems = (
  user: any,
  currentOrganization: any,
  homeSettings: any
): MenuItem[] => {
  if (!user || !currentOrganization || !user.role) {
    return [];
  }

  const commonItems: MenuItem[] = [
    {
      label: 'Home',
      icon: 'material-symbols-light:home-outline',
      link: '/home'
    },
    {
      label: 'Timesheets',
      icon: 'hugeicons:google-sheet',
      link: '/timesheets'
    },
    {
      label: 'Chat',
      icon: 'fluent:chat-20-regular',
      link: '/chat'
    }
  ];

  const roleSpecificItems: Record<string, MenuItem[]> = {
    admin: [
      {
        label: 'Invitations',
        icon: 'fluent:mail-20-regular',
        link: '/invitations'
      },
      {
        label: 'Settings',
        icon: 'iconamoon:profile-duotone',
        submenu: [
          {
            label: 'General',
            icon: 'fluent:scan-person-20-regular',
            link:
              currentOrganization.type === 'agency'
                ? '/agency-settings'
                : '/home-settings'
          },
          {
            label: 'Payments',
            icon: 'solar:wallet-money-bold-duotone',
            link: '/payments'
          }
          //   {
          //     label: 'Payroll Enrollment',
          //     icon: 'fluent:scan-person-20-regular',
          //     link: '/agency-payroll-enrollment'
          //   }
        ]
      }
    ],
    carer: [
      {
        label: 'Profile',
        icon: 'fluent:scan-person-20-regular',
        link: '/carer-profile'
      }
      //   {
      //     label: 'Resume',
      //     icon: 'fluent:scan-person-20-regular',
      //     link: '/carer-resume'
      //   }
    ]

    // ... (include other role-specific items here)
  };

  if (currentOrganization.type === 'agency') {
    roleSpecificItems.admin.push(
      {
        label: 'Homes',
        icon: 'iconamoon:profile-duotone',
        link: '/home-users'
      },
      {
        label: 'Staffs',
        icon: 'fluent:scan-person-20-regular',
        link: '/staffs'
      }
    );
  } else if (currentOrganization.type === 'home') {
    roleSpecificItems.admin.push(
      {
        label: 'Agencies',
        icon: 'fluent:scan-person-20-regular',
        link: '/agency-users'
      },
      {
        label: 'Residents',
        icon: 'pepicons-print:people-circle',
        link: '/home-residents'
      }
    );

    roleSpecificItems.carer.push({
      label: 'Residents',
      icon: 'pepicons-print:people-circle',
      link: '/carer-residents'
    });
    if (homeSettings.allowResident3rdParty) {
      roleSpecificItems.admin.push({
        label: 'Third Parties',
        icon: 'fluent:scan-person-20-regular',
        link: '/home-thirdparties'
      });
    }
  }

  return [...commonItems, ...(roleSpecificItems[user.role] || [])];
};
